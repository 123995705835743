import * as React from 'react';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {DocumentElement, DocumentElementType} from 'ui-components';
import {
  AnalysisResult,
  AnalysisResultStatus,
} from '../../../../../objects/models/analysis-result.model';
import {findDeep, HttpClientContext} from 'front-core';
import {getAnalysisResultNetworkRequest} from '../../../../../http/analysis-results.network-requests';
import {getDocumentNetworkRequest} from '../../../../../http/documents.network-requests';
import classes from './with-partial-document.module.scss';
import {CircularProgress} from '@material-ui/core';
import {RESULT_RUNNING_STATUSES} from '../../../../analyses/pages/view-analysis/view-analysis.component';
import {useDispatch} from 'react-redux';
import {ModelKey} from '../../../../../constants/model-key';
import {
  registerActionListener,
  removeActionListener,
} from '../../../../../store/actions-listener/actions-listener.actions';
import {CoreActionsType} from '../../../../../store/core/core.actions';
import {getAnalysisStepProgress} from '../../../../../utils/analysis-result.utils';
import {keyBy} from 'lodash';

export type ElementData = {
  [key in DocumentElementType]?: DocumentElement;
};

export interface WithPartialDocumentInjectedProps {
  analysisResultId: number;
  elementData?: ElementData;
  analysisResult?: AnalysisResult;
  isLoading?: boolean;
}

export interface WithPartialDocumentConfig {
  elementType: Array<DocumentElementType>;
  required?: boolean;
}

export function withPartialDocument(config: WithPartialDocumentConfig) {
  return function withConnector<P>(
    WrappedComponent: React.ComponentType<P & WithPartialDocumentInjectedProps>
  ): React.ComponentType<P & WithPartialDocumentInjectedProps> {
    const WithPartialDocument: React.FC<P & WithPartialDocumentInjectedProps> = (
      props: P & WithPartialDocumentInjectedProps
    ) => {
      const {elementType, required} = config;
      const {analysisResultId} = props;
      const http = useContext(HttpClientContext);
      const dispatch = useDispatch();
      const [isLoadingResult, setIsLoadingResult] = useState<boolean>(false);
      const [isLoadingElement, setIsLoadingElement] = useState<boolean>(false);
      const [analysisResult, setAnalysisResult] = useState<AnalysisResult>(null);
      const [elementData, setElementData] = useState<ElementData>(null);

      const isInProgress = useMemo(
        () => analysisResult && RESULT_RUNNING_STATUSES.indexOf(analysisResult.status) > -1,
        [analysisResult]
      );
      const analysisStepProgress = useMemo(
        () => getAnalysisStepProgress(analysisResult),
        [analysisResult]
      );
      const hasMissingData = useMemo(() => {
        if (elementData === null) {
          return;
        }
        const missing = elementType.filter(et => !(et in elementData));
        if (missing.length === 0 || !required) {
          return;
        }
        return missing;
      }, [elementData, elementType, required]);
      const loadElement = useCallback(
        async (documentId: string) => {
          setIsLoadingElement(true);
          // get document
          const doc = (await http.exec(getDocumentNetworkRequest(documentId))) as any;
          // get document data from s3
          const res = await fetch(doc.getDocUrl);
          const data = await res.json();
          // find the requested element type
          const elem = findDeep(data, i => elementType.indexOf(i.type) > -1);
          setElementData(keyBy(elem, 'type'));
          setIsLoadingElement(false);
        },
        [http, elementType]
      );
      const load = useCallback(
        async (analysisResultId: number) => {
          setIsLoadingResult(true);
          // get analysis result
          const ar: AnalysisResult = (await http.exec(
            getAnalysisResultNetworkRequest(analysisResultId)
          )) as any;
          setAnalysisResult(ar);
          setIsLoadingResult(false);
          const isInProgress = RESULT_RUNNING_STATUSES.indexOf(ar.status) > -1;
          if (isInProgress) {
            return;
          }
          await loadElement(ar.rootDocumentId);
        },
        [http, setAnalysisResult, loadElement, setIsLoadingResult]
      );
      // initial load analysis result
      useEffect(() => {
        if (!analysisResultId) {
          return;
        }
        load(analysisResultId);
      }, [analysisResultId, load]);
      // listen to analysis result updates
      useEffect(() => {
        const listener = action => {
          if (
            action.payload.modelKey === ModelKey.ANALYSIS_RESULT &&
            analysisResultId &&
            Number(analysisResultId) === action.payload.data?.id
          ) {
            const updatedAnalysisResult: AnalysisResult = {
              ...analysisResult,
              ...action.payload.data,
            };
            setAnalysisResult(updatedAnalysisResult);
            if (
              updatedAnalysisResult.status === AnalysisResultStatus.COMPLETED &&
              elementData === null &&
              updatedAnalysisResult.rootDocumentId
            ) {
              loadElement(updatedAnalysisResult.rootDocumentId);
            }
          }
        };
        dispatch(registerActionListener(CoreActionsType.MODEL_UPDATED, listener));
        return () => {
          dispatch(removeActionListener(CoreActionsType.MODEL_UPDATED, listener));
        };
      }, [dispatch, analysisResultId, setAnalysisResult, analysisResult, elementData, loadElement]);

      if (isInProgress) {
        return (
          <div className={classes.LoadingState}>
            <CircularProgress
              variant={'indeterminate'}
              disableShrink
              size={18}
              thickness={3}
              color={'inherit'}
            />
            <span>
              Working on it...{' '}
              {analysisStepProgress > 0 ? `${Math.floor(analysisStepProgress * 100)}%` : ''}
            </span>
          </div>
        );
      }

      if (isLoadingResult || isLoadingElement || elementData === null) {
        return (
          <div className={classes.LoadingState}>
            <CircularProgress
              variant={'indeterminate'}
              disableShrink
              size={18}
              thickness={3}
              color={'inherit'}
            />
            <span>Loading...</span>
          </div>
        );
      }

      if (hasMissingData) {
        return (
          <div className={classes.LoadingState}>
            <span>Error: {hasMissingData.join(', ')} missing in document</span>
          </div>
        );
      }

      return (
        <WrappedComponent
          {...props}
          elementData={elementData}
          analysisResult={analysisResult}
          analysisResultId={analysisResultId}
        />
      );
    };

    const wrappedComponentName =
      WrappedComponent.displayName || (WrappedComponent as any).name || 'Component';
    (WithPartialDocument as any).displayName = `WithPartialDocument(${wrappedComponentName})`;

    return WithPartialDocument as any;
  };
}
