import {Epic} from 'redux-observable';
import {createRequestEpic} from 'front-core';
import {ActionKey} from '../../constants/action-key';
import HttpClient from '../../services/http-client.service';
import {ExperimentsActionType} from './experiments.actions';
import {
  createExperimentNetworkRequest,
  createExperimentSubscriptionNetworkRequest,
  deleteExperimentNetworkRequest,
  deleteExperimentSubscriptionNetworkRequest,
  updateExperimentNetworkRequest,
  editExperimentSubscriptionNetworkRequest,
  rerunExperimentLastCompletedAnalysisResultNetworkRequest,
  patchExperimentNetworkRequest,
} from '../../http/experiments.network-requests';
import {modelCreated, modelDeleted, modelUpdated, notifyEvent} from '../core/core.actions';
import {ModelKey} from '../../constants/model-key';
import {experimentToastCreator} from '../toasts.actions';
import {AmplitudeEvent} from '../../constants/amplitude-event';
import {Experiment} from '../../objects/models/experiment.model';
import {showToastMessage} from '../interface/interface.actions';
import i18n from '../../config/i18n.config';
import TransKeys from '../../constants/translation-keys';
import {ToastType} from '../../objects/system/toast-type.enum';
import {SOURCE_META_KEY} from '../../constants/app-sources';
import {AnyAction} from 'redux';
import {getter} from '../store.utils';
import {AUTH_STORE_KEY} from '../auth/auth.store';

function notifyExperimentSubscriptionsCreated(experiment, subscriberId): AnyAction {
  const user = getter(AUTH_STORE_KEY, 'user');
  return notifyEvent(AmplitudeEvent.EXPERIMENT_SUBSCRIPTION_CREATED, {
    id: experiment.id,
    schedule: experiment.cronExp,
    subscriberId: subscriberId,
    isSelfSubscription: subscriberId === user.id,
  });
}

export const createExperimentEpic: Epic = createRequestEpic(
  {
    types: [ExperimentsActionType.CREATE_EXPERIMENT],
    actionKey: ActionKey.CREATE_EXPERIMENT,
    request: createExperimentNetworkRequest,
    onSuccess: experiment => {
      let handlers = [
        notifyEvent(AmplitudeEvent.EXPERIMENT_CREATED, {id: experiment.id}),
        experimentToastCreator('CREATE_SUCCESS'),
        modelCreated(experiment, ModelKey.EXPERIMENT),
      ];
      if (experiment.subscribers.length) {
        handlers = [
          ...handlers,
          ...experiment.subscribers.map(subscriber =>
            notifyExperimentSubscriptionsCreated(experiment, subscriber.id)
          ),
        ];
      }
      return handlers;
    },
    onError: err => [experimentToastCreator('CREATE_ERROR')],
  },
  HttpClient
);

export const patchExperimentEpic: Epic = createRequestEpic(
  {
    types: [ExperimentsActionType.PATCH_EXPERIMENT],
    actionKey: ActionKey.PATCH_EXPERIMENT,
    request: patchExperimentNetworkRequest,
    onSuccess: experiment => [
      notifyEvent(AmplitudeEvent.EXPERIMENT_PATCHED, {id: experiment.id}),
      experimentToastCreator('UPDATE_SUCCESS'),
      modelUpdated(experiment, ModelKey.EXPERIMENT),
    ],
    onError: err => [experimentToastCreator('UPDATE_ERROR')],
  },
  HttpClient
);

export const updateExperimentEpic: Epic = createRequestEpic(
  {
    types: [ExperimentsActionType.UPDATE_EXPERIMENT],
    actionKey: ActionKey.UPDATE_EXPERIMENT,
    request: updateExperimentNetworkRequest,
    onSuccess: (experiment, payload, {metadata}) => [
      notifyEvent(AmplitudeEvent.EXPERIMENT_UPDATED, {
        id: experiment.id,
        source: metadata?.[SOURCE_META_KEY],
      }),
      experimentToastCreator('UPDATE_SUCCESS'),
      modelUpdated(experiment, ModelKey.EXPERIMENT),
    ],
    onError: err => [experimentToastCreator('UPDATE_ERROR')],
  },
  HttpClient
);

export const deleteExperimentEpic: Epic = createRequestEpic(
  {
    types: [ExperimentsActionType.DELETE_EXPERIMENT],
    actionKey: ActionKey.DELETE_EXPERIMENT,
    request: deleteExperimentNetworkRequest,
    onSuccess: (res, payload) => [
      notifyEvent(AmplitudeEvent.EXPERIMENT_DELETED, {id: payload}),
      experimentToastCreator('DELETE_SUCCESS'),
      modelDeleted(payload, ModelKey.EXPERIMENT),
    ],
    onError: err => [experimentToastCreator('DELETE_ERROR')],
  },
  HttpClient
);

export const createExperimentSubscriptionEpic: Epic = createRequestEpic(
  {
    types: [ExperimentsActionType.CREATE_EXPERIMENT_SUBSCRIPTION],
    actionKey: ActionKey.CREATE_EXPERIMENT_SUBSCRIPTION,
    request: createExperimentSubscriptionNetworkRequest,
    onSuccess: (experiment: Experiment, payload) => [
      notifyExperimentSubscriptionsCreated(experiment, payload.subscriberId),
      showToastMessage(
        i18n.t(TransKeys.TOASTS.CREATE_SUBSCRIPTION_SUCCESS, {
          model: ModelKey.EXPERIMENT.toLowerCase(),
        }),
        ToastType.SUCCESS
      ),
      modelUpdated(
        {
          id: payload.experimentId,
          cronExp: experiment.cronExp,
        },
        ModelKey.EXPERIMENT
      ),
    ],
    onError: (err, payload) => [
      showToastMessage(
        i18n.t(TransKeys.TOASTS.CREATE_SUBSCRIPTION_ERROR, {
          model: ModelKey.EXPERIMENT.toLowerCase(),
        }),
        ToastType.ERROR
      ),
    ],
  },
  HttpClient
);

export const editExperimentSubscriptionEpic: Epic = createRequestEpic(
  {
    types: [ExperimentsActionType.EDIT_EXPERIMENT_SUBSCRIPTION],
    actionKey: ActionKey.EDIT_EXPERIMENT_SUBSCRIPTION,
    request: editExperimentSubscriptionNetworkRequest,
    onSuccess: (experiment: Experiment, payload) => [
      notifyEvent(AmplitudeEvent.EXPERIMENT_SUBSCRIPTION_UPDATED, {
        id: experiment.id,
        schedule: experiment.cronExp,
        subscriberId: payload.subscriberId,
      }),
      showToastMessage(
        i18n.t(TransKeys.TOASTS.EDIT_SUBSCRIPTION_SUCCESS, {
          model: ModelKey.EXPERIMENT.toLowerCase(),
        }),
        ToastType.SUCCESS
      ),
      modelUpdated(
        {
          id: payload.experimentId,
          cronExp: experiment.cronExp,
        },
        ModelKey.EXPERIMENT
      ),
    ],
    onError: (err, payload) => [
      showToastMessage(
        i18n.t(TransKeys.TOASTS.EDIT_SUBSCRIPTION_ERROR, {
          model: ModelKey.EXPERIMENT.toLowerCase(),
        }),
        ToastType.ERROR
      ),
    ],
  },
  HttpClient
);

export const deleteExperimentSubscriptionEpic: Epic = createRequestEpic(
  {
    types: [ExperimentsActionType.DELETE_EXPERIMENT_SUBSCRIPTION],
    actionKey: ActionKey.DELETE_EXPERIMENT_SUBSCRIPTION,
    request: deleteExperimentSubscriptionNetworkRequest,
    onSuccess: (experiment: Experiment, payload) => [
      notifyEvent(AmplitudeEvent.EXPERIMENT_SUBSCRIPTION_DELETED, {
        id: experiment.id,
        schedule: experiment.cronExp,
        subscriberId: payload.subscriberId,
      }),
      showToastMessage(
        i18n.t(TransKeys.TOASTS.DELETE_SUBSCRIPTION_SUCCESS, {
          model: ModelKey.EXPERIMENT.toLowerCase(),
        }),
        ToastType.SUCCESS
      ),
      modelUpdated({id: payload.experimentId, cronExp: experiment.cronExp}, ModelKey.EXPERIMENT),
    ],
    onError: (err, payload) => [
      showToastMessage(
        i18n.t(TransKeys.TOASTS.DELETE_SUBSCRIPTION_ERROR, {
          model: ModelKey.EXPERIMENT.toLowerCase(),
        }),
        ToastType.ERROR
      ),
    ],
  },
  HttpClient
);

export const rerunExperimentLastCompletedAnalysisResultEpic: Epic = createRequestEpic(
  {
    types: [ExperimentsActionType.RERUN_EXPERIMENT_LAST_COMPLETED_ANALYSIS_RESULT],
    actionKey: ActionKey.RERUN_EXPERIMENT_LAST_COMPLETED_ANALYSIS_RESULT,
    request: rerunExperimentLastCompletedAnalysisResultNetworkRequest,
    onSuccess: (experiment: Experiment, payload, {metadata}) => [
      notifyEvent(AmplitudeEvent.EXPERIMENT_RERAN_LAST_COMPLETED_ANALYSIS_RESULT, {
        id: payload,
        source: metadata?.[SOURCE_META_KEY],
      }),
      experimentToastCreator('RERUN_SUCCESS'),
      modelUpdated(payload, ModelKey.EXPERIMENT),
    ],
    onError: err => [experimentToastCreator('RERUN_ERROR')],
  },
  HttpClient
);
