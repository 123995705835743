import * as React from 'react';
import classes from './rca-date-picker.module.scss';
import {DatePickerInput} from 'ui-components';
import {useCallback, useMemo} from 'react';
import {createStartDateComparingProps} from '../../../../../analyses/analysis-forms/components/ui-selectors/anomaly-period-selector/anomaly-period-selector.component';
import moment from 'moment/moment';
import {TIME_FORMATS} from '../../../../../../constants/time-formats';
import {exists} from 'front-core';

interface OwnProps {
  selected: string;
  maxDate: string;
  granularity: 'day' | 'week' | 'month';
  onChange: (date: string) => void;
  isLoading?: boolean;
}

type AllProps = OwnProps;

export const RCADatePicker: React.FC<AllProps> = (props: AllProps) => {
  const {selected, onChange, maxDate, granularity, isLoading} = props;

  const datePickerProps = useMemo(
    () => ({
      ...createStartDateComparingProps(granularity),
      maxDate: moment(maxDate).toDate(),
    }),
    [maxDate, granularity]
  );

  const renderValue = useCallback(
    (value: string) => {
      const valueMoment = moment.utc(value);
      if (!exists(value)) {
        return '';
      }
      if (granularity === 'day') {
        return valueMoment.format(TIME_FORMATS.READABLE_DATE_NO_YEAR);
      }
      if (granularity === 'week') {
        const end = moment(value).add(6, 'days');
        return `${valueMoment.format(TIME_FORMATS.READABLE_DATE_NO_YEAR)} - ${end.format(
          TIME_FORMATS.READABLE_DATE_NO_YEAR
        )}`;
      }
      if (granularity === 'month') {
        return valueMoment.format('MMM YY');
      }
    },
    [granularity]
  );

  return (
    <DatePickerInput
      className={classes.Picker}
      value={selected}
      onChange={onChange}
      disabled={isLoading}
      utc
      {...datePickerProps}
      renderValue={renderValue}
      dateFormat={TIME_FORMATS.READABLE_DATE}
    />
  );
};
