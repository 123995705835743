import {action, OnSuccessActionHook} from 'front-core';
import {LoopDTO} from '../../objects/dto/loop.dto';
import {showConfirmationMessage} from '../interface/interface.actions';
import i18n from '../../config/i18n.config';
import TransKeys from '../../constants/translation-keys';

export enum LoopsActionType {
  CREATE_LOOP = '@@LOOPS/CREATE_LOOP',
  UPDATE_LOOP = '@@LOOPS/UPDATE_LOOP',
  DELETE_LOOP = '@@LOOPS/DELETE_LOOP',
}

export const createLoop = (data: LoopDTO, onSuccess: OnSuccessActionHook) =>
  action(LoopsActionType.CREATE_LOOP, data, {onSuccess});
export const updateLoop = (data: LoopDTO, onSuccess: OnSuccessActionHook) =>
  action(LoopsActionType.UPDATE_LOOP, data, {onSuccess});

export const deleteLoop = (id: number) => action(LoopsActionType.DELETE_LOOP, id);

export const deleteLoopConfirmed = (id: number) =>
  showConfirmationMessage(
    {
      title: i18n.t(TransKeys.CONFIRMATIONS.DELETE_LOOP.TITLE),
      content: i18n.t(TransKeys.CONFIRMATIONS.DELETE_LOOP.CONTENT),
    },
    () => [deleteLoop(id)]
  );
