import {exists, number2k, safeDivision} from 'front-core';
import {HomepageMetric, HomepageSample} from '../../../../objects/models/homepage.model';
import {MetricType, MetricValueType} from '../../../../objects/models/metric.model';
import {
  BOUNDED_ACTION_ANALYSIS_PARAMETER_MAPPING,
  BOUNDED_ACTION_NUMERIC_PARAMETER_MAPPING,
  BOUNDED_ACTIONS_ANALYSIS_PARAMETER_MAPPING,
  HABIT_MOMENT_PARAMETER_MAPPING,
} from '../../../shared/core/query-builders/query-builders.config';
import {get, keyBy, last, range, values} from 'lodash';
import {CircleCheckSolidIcon, SqlElementType, TriangleExclamationSolidIcon} from 'ui-components';
import {METADATA_KEY, PARAMETERS_METADATA_KEY} from '../../../../constants/parameters-saved-keys';
import HttpClient from '../../../../services/http-client.service';
import {multiLoaderNetworkRequest} from '../../../../http/multi-loader.network-requests';
import moment from 'moment/moment';
import {TIME_FORMATS} from '../../../../constants/time-formats';
import {ExtendedHomepageMetric, TransformedSeriesMap} from './homepage-summary.component';
import {ModelSeriesGranularity} from '../../../../objects/models/model-sample-series.model';
import TransKeys from '../../../../constants/translation-keys';
import i18n from 'i18next';

export const getSampleWoWChange = (
  lastSample: HomepageSample,
  prevSample: HomepageSample,
  higherIsBetter: boolean = true
): {value: number; expected: number; isBetter?: boolean; isSignificant?: boolean} => {
  if (!lastSample) {
    return;
  }

  if (!exists(lastSample.value) || !exists(prevSample.value)) {
    return undefined;
  }

  const trendValue = safeDivision(lastSample.value - prevSample.value, prevSample.value) * 100;
  let isBetter = trendValue >= 0;
  if (higherIsBetter === false) {
    isBetter = !isBetter;
  }
  const isSignificant = lastSample.value > lastSample.upper || lastSample.value < lastSample.lower;

  return {value: trendValue, expected: lastSample.expectedValue, isBetter, isSignificant};
};

export const extractXLabelFromMetric = async (
  metric: ExtendedHomepageMetric,
  entityName: string,
  granularity: ModelSeriesGranularity
): Promise<string> => {
  let mapping = null;
  switch (metric.type) {
    case MetricType.RETENTION:
      mapping = BOUNDED_ACTIONS_ANALYSIS_PARAMETER_MAPPING;
      break;
    case MetricType.CONVERSION:
      mapping = BOUNDED_ACTION_ANALYSIS_PARAMETER_MAPPING;
      break;
    case MetricType.HABIT_MOMENT:
      mapping = HABIT_MOMENT_PARAMETER_MAPPING;
      break;
    case MetricType.BOUNDED_REVENUE:
      mapping = BOUNDED_ACTION_NUMERIC_PARAMETER_MAPPING;
      break;
    case MetricType.PAYMENT_RETENTION:
      return `${entityName} first payment`;
    case MetricType.DAU:
      return 'day';
    case MetricType.WAU:
    case MetricType.L7:
      return 'week';
    case MetricType.MAU:
    case MetricType.L28:
      return 'month';
    case MetricType.USAGE:
    case MetricType.REVENUE:
    case MetricType.BEHAVIORAL_CHURN:
    case MetricType.RATE:
    case MetricType.CUSTOM_SQL:
      return granularity;
  }

  if (mapping) {
    const refDateDefinition = get(metric.signalDefinition, mapping.ref_date);
    if (!exists(refDateDefinition)) {
      return granularity;
    }
    if (refDateDefinition.type === SqlElementType.TABLE_COLUMN) {
      return refDateDefinition.column;
    }
    if (refDateDefinition.type === SqlElementType.SIGNAL_COLUMN) {
      const res = await HttpClient.exec(
        multiLoaderNetworkRequest({
          signals: [refDateDefinition.signal_id],
        })
      );
      return get(res, `signals.${refDateDefinition.signal_id}.name`).toLowerCase();
    }
    if (
      get(
        refDateDefinition,
        `${PARAMETERS_METADATA_KEY}.${METADATA_KEY.BUILDER_COMPONENT_NAME_KEY}`
      ) === 'TemplateItemQueryBuilder'
    ) {
      return get(refDateDefinition, `${PARAMETERS_METADATA_KEY}.${METADATA_KEY.DISPLAY_NAME_KEY}`);
    }
  }

  return granularity;
};

export const extendHomepageMetric = (metric: HomepageMetric): ExtendedHomepageMetric => {
  const transformedSeries: TransformedSeriesMap = {};

  for (const series of metric.series) {
    const samples = series.samples || [];
    if (!samples || samples.length === 0) {
      continue;
    }
    const withoutPartialSamples = samples.filter(s => s.isPartial === false);
    const lastSample = last(withoutPartialSamples);
    let goalPerDay = undefined;

    if (metric.goal) {
      const metricTimeDelta = moment
        .utc(lastSample.sampleDatetime)
        .diff(moment.utc(lastSample.datetime), 'days');
      const sampleByDate = keyBy(withoutPartialSamples, 'datetime');
      const goalStart = moment.utc(metric.goal.startDate).subtract(metricTimeDelta, 'days');
      const goalEnd = moment.utc(metric.goal.endDate).subtract(metricTimeDelta, 'days');
      let startOfGoalSample = withoutPartialSamples.find(s =>
        moment.utc(s.datetime).isSameOrAfter(goalStart)
      );
      if (!exists(startOfGoalSample)) {
        startOfGoalSample = withoutPartialSamples[withoutPartialSamples.length - 1];
      }
      const goalValue = startOfGoalSample.expectedValue;
      const diff = goalEnd.diff(goalStart, series.granularity);
      const totalRange = metric.goal.value - goalValue;
      const dailyGoal = totalRange / diff;
      goalPerDay = range(diff + 1).map(i => {
        const datetime = goalStart.format(TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT);
        const x = {
          datetime,
          value: goalValue + dailyGoal * i,
          matchingSampleValue: sampleByDate[datetime]?.value,
        };
        goalStart.add(1, series.granularity);
        return x;
      });
    }

    transformedSeries[series.granularity] = {
      ...series,
      lastSample,
      goalPerDay,
      lastSampleGoalValue: goalPerDay?.find(g => g.datetime === lastSample.datetime)?.value,
      nonPartialSamples: series.samples.filter(s => s.isPartial === false),
    };
  }

  return {
    ...metric,
    transformedSeries,
  };
};

export const getGoalParameters = (
  metric: ExtendedHomepageMetric,
  granularity: ModelSeriesGranularity
) => {
  if (!metric.goal) {
    return;
  }
  let series = metric.transformedSeries[granularity];
  if (!series) {
    series = values(metric.transformedSeries)[0];
  }
  if (!series) {
    return;
  }
  const lastSample = last(series.samples);
  let compareTo = lastSample.expectedValue;
  const isBehind = series.lastSampleGoalValue && series.lastSampleGoalValue > compareTo;
  return {
    isBehind,
    icon: isBehind ? TriangleExclamationSolidIcon : CircleCheckSolidIcon,
    helperText: isBehind
      ? i18n.t(TransKeys.HOMEPAGE.LABELS.GOAL_BEHIND_HELPER)
      : i18n.t(TransKeys.HOMEPAGE.LABELS.GOAL_AHEAD_HELPER),
    goalValue:
      metric.valueType === MetricValueType.PERCENTAGE
        ? `${number2k(metric.goal.value * 100)}%`
        : number2k(metric.goal.value),
    lastSampleGoalValue:
      metric.valueType === MetricValueType.PERCENTAGE
        ? `${number2k(series.lastSampleGoalValue * 100)}%`
        : number2k(series.lastSampleGoalValue),
    averageValue:
      metric.valueType === MetricValueType.PERCENTAGE
        ? `${number2k(compareTo * 100)}%`
        : number2k(compareTo),
    text: isBehind
      ? i18n.t(TransKeys.HOMEPAGE.LABELS.GOAL_BEHIND)
      : i18n.t(TransKeys.HOMEPAGE.LABELS.GOAL_AHEAD),
  };
};
