import * as React from 'react';
import {Route, Switch} from 'react-router';
import {AppRoutes} from '../../constants/app-routes';
import {MetricPage} from './pages/metric-page/metric-page.component';

export const MetricsRouting: React.FC = props => {
  return (
    <Switch>
      {/*<Route*/}
      {/*  path={AppRoutes.metrics().pathname}*/}
      {/*  component={}*/}
      {/*  exact*/}
      {/*/>*/}
      <Route path={AppRoutes.viewMetric().pathname} component={MetricPage} exact />
    </Switch>
  );
};
