import {MetricType, MetricValueType} from './metric.model';
import {Goal} from './goal.model';
import {TableEntity} from './table.model';
import {ModelSeriesGranularity} from './model-sample-series.model';
import {Annotation} from './annotation.model';

export interface HomepageSample {
  id: number;
  value: number;
  expectedValue: number;
  upper: number;
  lower: number;
  denominator: number;
  sampleDatetime: string;
  datetime: string;
  warning: string;
  isPartial: boolean;
  rcaAnalysisId: number;
  rcaAnalysisResultId: number;
}

export enum AnomalyMode {
  LOOPS_ALGO = 'loops_algo',
  XOX_SAME_WEEKDAY = 'xox_same_weekday',
}

export enum HomepageSubscriptionVIA {
  MAIL = 'mail',
  SLACK = 'slack',
}

export enum HomepageSubscriptionPushType {
  SIGNIFICANT_CHANGE = 'significant_change',
  ALWAYS = 'always',
  NEVER = 'never',
}

export enum HomepageSubscribedSegmentsType {
  ALL = 'all',
  TEAM = 'team',
  CUSTOM = 'custom',
}

export interface HomepageSettings {
  homepageId: number;
  metrics: number[];
}

export interface HomepageSubscriptionMetricSettings {
  [metricId: number]: HomepageSubscriptionPushType;
}

export interface HomepageSubscription {
  homepageId: number;
  isActive: boolean;
  via: HomepageSubscriptionVIA[];
  slackChannels: string[];
  anomalyMode: AnomalyMode;
  anomalyThreshold: number;
  subscribedSegmentsType: HomepageSubscribedSegmentsType;
  metricSettings: HomepageSubscriptionMetricSettings;
}

export interface HomepageSeries {
  id: number;
  granularity: ModelSeriesGranularity;
  hasDenominator: boolean;
  samples: HomepageSample[];
}

export interface HomepageMetric {
  id: number;
  name: string;
  type: MetricType;
  valueType: MetricValueType;
  entity: TableEntity;
  shortDescription: string;
  isNorthStar: boolean;
  signalId: number;
  signalDefinition: any;
  signalPopulationFilter: any;
  goal: Partial<Goal>;
  series: HomepageSeries[];
  higherIsBetter: boolean;
  maxSampleDate?: string;
  minSampleDate?: string;
  createdOn: string;
}

export interface HomepageAnnotation extends Annotation {}
