import * as React from 'react';
import classNames from 'classnames';
import classes from './user-property-column-query-builder.module.scss';
import {LiteralValueType, QueryBuilderFactory} from 'ui-components';
import {
  METADATA_KEY,
  PARAMETERS_METADATA_KEY,
} from '../../../../../constants/parameters-saved-keys';
import {TableColumnSmartSelector} from '../../smart-selector/table-column-smart-selector.component';
import {useCallback, useMemo} from 'react';
import {TableType} from '../../../../../objects/models/table.model';
import {TableColumn} from '../../../../../objects/models/table-column.model';

interface OwnProps {
  query: any;
  onChange?: (query: any) => void;
  errors?: any;
  filters?: any;
  disabled?: boolean;
  className?: string;
}

type AllProps = OwnProps;

export const createUserPropertyColumnInitialQuery = (tableColumn?: TableColumn) => {
  const q = QueryBuilderFactory.createTableColumn();

  q.table_id = tableColumn?.tableId;
  q.column = tableColumn?.name;
  q[PARAMETERS_METADATA_KEY] = {
    [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: 'UserPropertyColumnQueryBuilder',
    [METADATA_KEY.TABLE_COLUMN_ID_KEY]: tableColumn?.id,
    [METADATA_KEY.TABLE_COLUMN_TYPE_KEY]: tableColumn?.literalType,
  };

  return q;
};

export const TABLE_COLUMN_ALLOWED_TYPES = [
  // no date
  LiteralValueType.INTEGER,
  LiteralValueType.FLOAT,
  LiteralValueType.STRING,
  LiteralValueType.BOOLEAN,
];

const TABLE_COLUMN_FILTERS = {
  tableType: TableType.ENTITY_PROPERTIES,
  literalType: TABLE_COLUMN_ALLOWED_TYPES,
};

export const UserPropertyColumnQueryBuilder: React.FC<AllProps> = (props: AllProps) => {
  const {query, onChange, errors, filters, disabled, className} = props;

  const allFilters = useMemo(
    () => ({
      ...TABLE_COLUMN_FILTERS,
      ...filters,
    }),
    [filters]
  );

  const onTableColumnChange = useCallback(
    (_: number, tableColumn: any) => {
      const newQuery = createUserPropertyColumnInitialQuery(tableColumn);
      onChange(newQuery);
    },
    [onChange]
  );

  return (
    <div className={classNames(classes.UserPropertyColumnQueryBuilder, className)}>
      <TableColumnSmartSelector
        value={query?.[PARAMETERS_METADATA_KEY]?.[METADATA_KEY.TABLE_COLUMN_ID_KEY]}
        placeholder={'Property'}
        onChange={onTableColumnChange}
        error={Boolean(errors)}
        filters={allFilters}
        disabled={disabled}
      />
    </div>
  );
};

UserPropertyColumnQueryBuilder.defaultProps = {
  filters: {},
};
