import * as React from 'react';
import {useCallback, useMemo} from 'react';
import {
  DatePickerInput,
  APICallInputOptions,
  EnumInputOptions,
  MultiSelect,
  Select,
  Textarea,
  TextInput,
} from 'ui-components';
import {FormLabelInput} from './form-label-input.component';
import {TIME_FORMATS} from '../../../../constants/time-formats';

interface SharedProps {
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
  label: string;
  name: string;
  className?: string;
  helperText?: string;
  placeholder?: string;
  onChange?: (v) => void;
  value?: any;
  maxLength?: number;
  renderRight?: JSX.Element;
}

interface TextareaFormInputProps extends SharedProps {
  rows?: number;
}

export const TextareaFormInput: React.FC<TextareaFormInputProps> = (
  props: TextareaFormInputProps
) => {
  const {
    disabled,
    name,
    required,
    label,
    placeholder,
    className,
    helperText,
    onChange: onChange_,
    value: value_,
    maxLength,
    renderRight,
    rows,
  } = props;
  const onChange = useCallback(v => onChange_ && onChange_(v), [onChange_]);
  const controlledValue = useMemo(() => (value_ ? {value: value_} : {}), [value_]);
  const inputProps = useMemo(() => ({maxLength, rows: rows || 1}), [maxLength, rows]);

  return (
    <FormLabelInput
      label={label}
      name={name}
      required={required}
      className={className}
      helperText={helperText}
      maxLength={maxLength}
      renderRight={renderRight}
      render={field => (
        <Textarea
          {...field}
          disabled={disabled}
          inputProps={inputProps}
          onChange={v => {
            field.onChange(v);
            onChange(v);
          }}
          placeholder={placeholder || label}
          fullWidth
          {...controlledValue}
        />
      )}
    />
  );
};

interface TextFormInputProps extends SharedProps {
  type?: 'text' | 'number' | 'password';
  maxLength?: number;
  multiple?: boolean;
  autoFocus?: boolean;
  placeholderIsValue?: boolean;
  icon?: any;
  leftIcon?: any;
}

export const TextFormInput: React.FC<TextFormInputProps> = (props: TextFormInputProps) => {
  const {
    disabled,
    error,
    name,
    required,
    label,
    placeholder,
    placeholderIsValue,
    type = 'text',
    className,
    helperText,
    onChange: onChange_,
    value: value_,
    maxLength,
    renderRight,
    multiple,
    autoFocus,
    icon,
    leftIcon,
  } = props;
  const onChange = useCallback(v => onChange_ && onChange_(v), [onChange_]);
  const controlledValue = useMemo(() => (value_ ? {value: value_} : {}), [value_]);

  return (
    <FormLabelInput
      label={label}
      name={name}
      required={required}
      className={className}
      helperText={helperText}
      maxLength={maxLength}
      renderRight={renderRight}
      render={field => (
        <TextInput
          {...field}
          onChange={v => {
            field.onChange(v);
            onChange(v);
          }}
          icon={icon}
          leftIcon={leftIcon}
          maxLength={maxLength}
          type={type}
          placeholder={placeholder || label}
          placeholderIsValue={placeholderIsValue}
          disabled={disabled}
          error={error}
          multiple={multiple}
          fullWidth
          autoFocus={autoFocus}
          {...controlledValue}
        />
      )}
    />
  );
};

interface EnumFromInputProps extends SharedProps {
  options: EnumInputOptions;
  sortValues?: boolean;
  clearable?: boolean;
  dropdownButtonClassName?: string;
}

export const EnumFormInput: React.FC<EnumFromInputProps> = (props: EnumFromInputProps) => {
  const {
    disabled,
    name,
    required,
    label,
    options,
    className,
    dropdownButtonClassName,
    helperText,
    placeholder,
    onChange: onChange_,
    value: value_,
    sortValues,
    clearable,
  } = props;
  const onChange = useCallback(v => onChange_ && onChange_(v), [onChange_]);
  const controlledValue = useMemo(() => (value_ ? {value: value_} : {}), [value_]);

  return (
    <FormLabelInput
      required={required}
      label={label}
      name={name || 'status'}
      className={className}
      helperText={helperText}
      render={field => (
        <Select
          {...field}
          onChange={v => {
            field.onChange(v);
            onChange(v);
          }}
          disabled={disabled}
          placeholder={placeholder || 'None'}
          options={options}
          searchable={false}
          clearable={clearable}
          fullWidth
          sortValues={sortValues}
          dropdownButtonClassName={dropdownButtonClassName}
          {...controlledValue}
        />
      )}
    />
  );
};

interface DateFormInputProps extends SharedProps {
  minDate?: Date;
  maxDate?: Date;
  fullWidth?: boolean;
  onChange?: (v: any) => any;
}

export const DateFormInput: React.FC<DateFormInputProps> = (props: DateFormInputProps) => {
  const {
    disabled,
    name,
    required,
    placeholder,
    label,
    minDate,
    maxDate,
    helperText,
    fullWidth,
    onChange: onChange_,
    value: value_,
  } = props;
  const onChange = useCallback(v => onChange_ && onChange_(v), [onChange_]);
  const controlledValue = useMemo(() => (value_ ? {value: value_} : {}), [value_]);

  return (
    <FormLabelInput
      label={label}
      name={name}
      required={required}
      helperText={helperText}
      fullWidth={fullWidth}
      render={field => (
        <DatePickerInput
          {...field}
          onChange={v => {
            field.onChange(v);
            onChange(v);
          }}
          placeholder={placeholder || label}
          dateFormat={TIME_FORMATS.DEFAULT_DATE_FORMAT}
          disabled={disabled}
          fullWidth={fullWidth}
          minDate={minDate}
          maxDate={maxDate}
          {...controlledValue}
        />
      )}
    />
  );
};

DateFormInput.defaultProps = {
  fullWidth: true,
};

interface MultiSelectInputProps extends SharedProps {
  options: EnumInputOptions | APICallInputOptions;
  onChange?: (v: any) => any;
  value?: any;
}

export const MultiSelectInput: React.FC<MultiSelectInputProps> = (props: MultiSelectInputProps) => {
  const {
    className,
    disabled,
    name,
    required,
    label,
    helperText,
    options,
    placeholder,
    onChange: onChange_,
    value: value_,
  } = props;
  const onChange = useCallback(v => onChange_ && onChange_(v), [onChange_]);
  const controlledValue = useMemo(() => (value_ ? {value: value_} : {}), [value_]);

  return (
    <FormLabelInput
      label={label}
      name={name}
      required={required}
      helperText={helperText}
      className={className}
      render={(field, fieldState) => (
        <MultiSelect
          {...field}
          onChange={v => {
            field.onChange(v);
            onChange(v);
          }}
          error={Boolean(fieldState.error?.message)}
          fullWidth
          options={options}
          disabled={disabled}
          placeholder={placeholder || label}
          {...controlledValue}
        />
      )}
    />
  );
};
