import {NotSignificantChangeKPI, RCAInsightSentence} from './objects';
import sharedClasses from '../insights.module.scss';
import {
  ChangeInValueRCAInsightSentencePart,
  SegmentNameRCAInsightSentencePart,
} from '../components/shared-sentence-parts/shared-sentence-parts.components';

interface OwnProps extends RCAInsightSentence {
  formattedSentenceParts: NotSignificantChangeKPI;
}
type AllProps = OwnProps;

const NotSignificantChangeKPIFormatter: React.FC<AllProps> = (props: AllProps) => {
  const {formattedSentenceParts} = props;
  const {segmentName, presentTensePerformance, changeInValue} = formattedSentenceParts;

  return (
    <div className={sharedClasses.InsightSentenceContainer}>
      <SegmentNameRCAInsightSentencePart segmentName={segmentName} />
      <span
        className={sharedClasses.InsightSentencePart}
      >{`${presentTensePerformance} vs the prior week by`}</span>
      <ChangeInValueRCAInsightSentencePart changeInValue={changeInValue} />
    </div>
  );
};

export {NotSignificantChangeKPIFormatter};
