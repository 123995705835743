import amplitude from 'amplitude-js';
import appConfig from './app.config';

// Based on: https://newbedev.com/how-do-integrate-amplitude-analytics-with-a-react-app

export const initAmplitude = () => {
  amplitude.getInstance().init(appConfig.amplitudeApiKey, null, {
    apiEndpoint: appConfig.amplitudeApiEndpoint,
  });
};

export const setAmplitudeUserDevice = installationToken => {
  amplitude.getInstance().setDeviceId(installationToken);
};

export const setAmplitudeUserId = userId => {
  amplitude.getInstance().setUserId(userId);
};

export const sendAmplitudeData = (eventType, eventProperties) => {
  amplitude.getInstance().logEvent(eventType, eventProperties);
};

export const setAmplitudeUserProperties = properties => {
  amplitude.getInstance().setUserProperties(properties);
};
