import * as React from 'react';
import {useCallback, useContext, useMemo} from 'react';
import {Button, FancyHeader, FlaskGearLightIcon, ModalLayout, Select} from 'ui-components';
import {composition} from 'front-core';
import {withDisableDemoProduct} from '../../../../core/hoc/with-disable-demo-product.hoc';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {
  EXPERIMENT_AUTOMATION_ID_PATH_PARAM,
  SIGNAL_ID_PATH_PARAM,
} from '../../../../constants/app-routes';
import {getExperimentAutomationNetworkRequest} from '../../../../http/experiment-automations.network-requests';
import {
  ExperimentAutomation,
  ExperimentAutomationType,
} from '../../../../objects/models/experiment-automation.model';
import classes from './experiment-automation-form-panel.module.scss';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useDispatch} from 'react-redux';
import {experimentAutomationDTOValidator} from '../../../../objects/dto/experiment-automation.dto';
import {SystemDescriptionBlock} from '../../../shared/components/general/system-description-block/system-description-block.component';
import {ParameterInputWrapper} from '../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import {TextFormInput} from '../../../shared/form/components/shared-form-input.component';
import {EntitySelector} from '../../../analyses/analysis-forms/components/ui-selectors/entity-selector/entity-selector.component';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {KPIsSelector} from '../../../analyses/analysis-forms/components/ui-selectors/kpis-selector/kpis-selector.component';
import {PanelKey} from '../../../../constants/panels';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {SegmentFilterSelector} from '../../../analyses/analysis-forms/components/ui-selectors/segment-filter-selector/segment-filter-selector.component';
import {TableEventsValueQueryBuilder} from '../../../shared/core/query-builders/table-events-value-query-builder/table-events-value-query-builder.component';
import {TableEntity, TableEntityBinding} from '../../../../objects/models/table.model';
import {get} from 'lodash';
import {
  createExperimentAutomation,
  updateExperimentAutomation,
} from '../../../../store/experiment-automations/experiment-automations.actions';
import {createKPISelectorFiltersFor91} from '../../../analyses/analysis-forms/analysis-parameters/analysis-91/analysis-91-form.component';
import {createKPISelectorFiltersFor109} from '../../../analyses/analysis-forms/analysis-parameters/analysis-109/analysis-109-form.component';

interface OwnProps {
  automation: ExperimentAutomation;
  type?: ExperimentAutomationType;
  onClose: () => void;
  disabled?: boolean;
}

type AllProps = OwnProps;

const SELECTED_AUTOMATION_KEY = 'EXPERIMENT_AUTOMATION_FORM_PANEL/AUTOMATION';

const createTableFilters = (entityContext: TableEntity) => ({
  entityBinding: TableEntityBinding.DEFAULT,
  entity: entityContext,
});

const THRESHOLDS = [
  {
    value: 0.01,
    label: '1%',
  },
  {
    value: 0.05,
    label: '5%',
  },
  {
    value: 0.1,
    label: '10%',
  },
  {
    value: 0.2,
    label: '20%',
  },
];

const ExperimentAutomationFormPanelComponent: React.FC<AllProps> = (props: AllProps) => {
  const {automation = {} as ExperimentAutomation, type: typeFromProps, onClose, disabled} = props;
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {openSecondaryPanel} = useContext(PanelsContext);
  const {productEntities, defaultTableEntity} = useProductData();

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      threshold: 0.05,
      type: typeFromProps,
      entity: defaultTableEntity,
      ...automation,
    },
    resolver: yupResolver(experimentAutomationDTOValidator),
  });
  const {
    handleSubmit,
    formState: {errors},
    watch,
    setValue,
    control,
  } = formMethods;
  const id = watch('id');
  const editMode = Boolean(id);
  const type = watch('type');
  const entityContext = watch('entity');
  const title = useMemo(() => {
    if (editMode) {
      return t(TransKeys.EXPERIMENT_AUTOMATION_FORM_PANEL.EDIT_TITLE);
    }
    return t(TransKeys.EXPERIMENT_AUTOMATION_FORM_PANEL.CREATE_TITLE);
  }, [editMode, t]);
  const kpiSignalFilters = useMemo(
    () =>
      typeFromProps === ExperimentAutomationType.AB_TEST
        ? createKPISelectorFiltersFor91(entityContext)
        : createKPISelectorFiltersFor109(entityContext),
    [entityContext, typeFromProps]
  );
  const tableFilters = useMemo(() => createTableFilters(entityContext), [entityContext]);
  const onEntityChange = useCallback(
    entity => {
      setValue('entity', entity, {shouldValidate: true});
      setValue('defaultGoalId', undefined, {shouldValidate: true});
      setValue('defaultSecondaryGoalIds', undefined, {shouldValidate: true});
      setValue('populationFilter', undefined, {shouldValidate: true});
    },
    [setValue]
  );
  const onKPIsChange = useCallback(
    data => {
      if ('primary_kpi' in data) {
        setValue('defaultGoalId', data.primary_kpi, {shouldValidate: true});
      }
      if ('secondary_kpis' in data) {
        setValue('defaultSecondaryGoalIds', data.secondary_kpis, {shouldValidate: true});
      }
    },
    [setValue]
  );
  const onSignalInfo = useCallback(
    signalId =>
      openSecondaryPanel(PanelKey.SIGNAL_DEFINITION_PANEL, {
        [SIGNAL_ID_PATH_PARAM]: signalId,
      }),
    [openSecondaryPanel]
  );
  const onChangeDefinition = useCallback(
    (key: 'key' | 'variant', value: any) => {
      const definitionKey = `${key}SignalDefinition`;
      const dataTypeKey = `${key}SignalDataType`;
      setValue(definitionKey as any, value, {shouldValidate: true});
      const dataType = get(value, 'cases.0.1.ui_metadata.table_column_literal_type');
      setValue(dataTypeKey as any, dataType, {shouldValidate: true});
    },
    [setValue]
  );
  const onSubmit = useCallback(
    data => {
      const action = editMode
        ? updateExperimentAutomation(data, onClose)
        : createExperimentAutomation(data, onClose);
      dispatch(action);
    },
    [dispatch, onClose, editMode]
  );
  const renderSignalBuilderByType = () => {
    if (type === ExperimentAutomationType.RELEASE) {
      return (
        <ParameterInputWrapper
          title={t(TransKeys.EXPERIMENT_AUTOMATION_FORM_PANEL.INPUTS.VERSION.TITLE)}
          subTitle={t(TransKeys.EXPERIMENT_AUTOMATION_FORM_PANEL.INPUTS.VERSION.DESCRIPTION)}
          className={classes.Parameter}
        >
          <Controller
            render={({field, fieldState}) => (
              <TableEventsValueQueryBuilder
                query={field.value}
                onChange={v => onChangeDefinition('key', v)}
                filters={tableFilters}
                thenText={t(
                  TransKeys.EXPERIMENT_AUTOMATION_FORM_PANEL.INPUTS.VERSION_SELECTOR.THEN_TEXT
                )}
                multiEvents={false}
                errors={errors['keySignalDefinition']}
                multiSelection
                sameTypeThen
              />
            )}
            name={'keySignalDefinition'}
            control={control}
          />
        </ParameterInputWrapper>
      );
    }

    if (type === ExperimentAutomationType.AB_TEST) {
      return (
        <>
          <ParameterInputWrapper
            title={t(TransKeys.EXPERIMENT_AUTOMATION_FORM_PANEL.INPUTS.EXPERIMENT_KEY.TITLE)}
            subTitle={t(
              TransKeys.EXPERIMENT_AUTOMATION_FORM_PANEL.INPUTS.EXPERIMENT_KEY.DESCRIPTION
            )}
            className={classes.Parameter}
          >
            <Controller
              render={({field, fieldState}) => (
                <TableEventsValueQueryBuilder
                  query={field.value}
                  onChange={v => onChangeDefinition('key', v)}
                  filters={tableFilters}
                  thenText={t(
                    TransKeys.EXPERIMENT_AUTOMATION_FORM_PANEL.INPUTS.EXP_KEY_SELECTOR.THEN_TEXT
                  )}
                  multiEvents={false}
                  errors={errors['keySignalDefinition']}
                  multiSelection
                  sameTypeThen
                />
              )}
              name={'keySignalDefinition'}
              control={control}
            />
          </ParameterInputWrapper>
          <ParameterInputWrapper
            title={t(TransKeys.EXPERIMENT_AUTOMATION_FORM_PANEL.INPUTS.VARIANT_KEY.TITLE)}
            subTitle={t(TransKeys.EXPERIMENT_AUTOMATION_FORM_PANEL.INPUTS.VARIANT_KEY.DESCRIPTION)}
            className={classes.Parameter}
          >
            <Controller
              render={({field, fieldState}) => (
                <TableEventsValueQueryBuilder
                  query={field.value}
                  onChange={v => onChangeDefinition('variant', v)}
                  filters={tableFilters}
                  thenText={t(
                    TransKeys.EXPERIMENT_AUTOMATION_FORM_PANEL.INPUTS.VARIANT_KEY_SELECTOR.THEN_TEXT
                  )}
                  multiEvents={false}
                  errors={errors['variantSignalDefinition']}
                  multiSelection
                  sameTypeThen
                />
              )}
              name={'variantSignalDefinition'}
              control={control}
            />
          </ParameterInputWrapper>
        </>
      );
    }
  };
  return (
    <div className={classes.ExperimentAutomationFormPanelContainer}>
      <ModalLayout
        footer={
          <div className={classes.Footer}>
            <Button onClick={onClose} variant={'outlined'}>
              {t(TransKeys.GENERAL.ACTIONS.CANCEL)}
            </Button>
            <Button disabled={disabled} onClick={handleSubmit(onSubmit)}>
              {t(TransKeys.GENERAL.ACTIONS.SAVE)}
            </Button>
          </div>
        }
      >
        <FancyHeader icon={FlaskGearLightIcon} title={title} />
        <FormProvider {...formMethods}>
          <div className={classes.Content}>
            <SystemDescriptionBlock className={classes.Parameter}>
              {t(TransKeys.EXPERIMENT_AUTOMATION_FORM_PANEL.DESCRIPTION)}
            </SystemDescriptionBlock>
            <ParameterInputWrapper
              title={t(TransKeys.EXPERIMENT_AUTOMATION_FORM_PANEL.INPUTS.NAME.TITLE)}
              className={classes.Parameter}
            >
              <TextFormInput label={''} name={'name'} placeholder={'eg. Web app release'} />
            </ParameterInputWrapper>
            <Controller
              control={control}
              name={'entity'}
              render={({field, fieldState}) => (
                <EntitySelector
                  productEntities={productEntities}
                  onChange={params => onEntityChange(params.entity)}
                  value={{entity: field.value}}
                  className={classes.Parameter}
                />
              )}
            />
            {renderSignalBuilderByType()}
            <KPIsSelector
              value={{
                primary_kpi: watch('defaultGoalId'),
                secondary_kpis: watch('defaultSecondaryGoalIds'),
              }}
              title={t(TransKeys.EXPERIMENT_AUTOMATION_FORM_PANEL.INPUTS.KPI_SELECTOR.TITLE)}
              subTitle={t(TransKeys.EXPERIMENT_AUTOMATION_FORM_PANEL.INPUTS.KPI_SELECTOR.SUB_TITLE)}
              onChange={onKPIsChange}
              filters={kpiSignalFilters}
              className={classes.Parameter}
              errors={{
                primary_kpi: errors.defaultGoalId,
                secondary_kpis: errors.defaultSecondaryGoalIds,
              }}
              onSignalInfo={onSignalInfo}
            />
            <ParameterInputWrapper
              title={t(TransKeys.EXPERIMENT_AUTOMATION_FORM_PANEL.INPUTS.THRESHOLD.TITLE)}
              subTitle={t(TransKeys.EXPERIMENT_AUTOMATION_FORM_PANEL.INPUTS.THRESHOLD.SUB_TITLE)}
              className={classes.Parameter}
            >
              <Controller
                render={({field, fieldState}) => (
                  <Select
                    value={field.value}
                    options={{options: THRESHOLDS}}
                    onChange={field.onChange}
                    clearable={false}
                    sortValues={false}
                    searchable={false}
                  />
                )}
                name={'threshold'}
                control={control}
              />
            </ParameterInputWrapper>
            <SegmentFilterSelector
              onChange={v =>
                setValue('populationFilter', v.population_filter, {shouldValidate: true})
              }
              title={t(TransKeys.EXPERIMENT_AUTOMATION_FORM_PANEL.INPUTS.POPULATION_FILTER.TITLE)}
              subTitle={t(
                TransKeys.EXPERIMENT_AUTOMATION_FORM_PANEL.INPUTS.POPULATION_FILTER.SUB_TITLE
              )}
              entityContext={entityContext}
              value={{
                population_filter: watch('populationFilter'),
              }}
              errors={{
                population_filter: errors.populationFilter,
              }}
            />
          </div>
        </FormProvider>
      </ModalLayout>
    </div>
  );
};

export const ExperimentAutomationFormPanel = composition<AllProps>(
  ExperimentAutomationFormPanelComponent,
  withDisableDemoProduct,
  withLoadBefore({
    automation: {
      selectedKey: SELECTED_AUTOMATION_KEY,
      actionKey: SELECTED_AUTOMATION_KEY,
      request: getExperimentAutomationNetworkRequest,
      mapPayloadFromProps: props => props[EXPERIMENT_AUTOMATION_ID_PATH_PARAM],
      shouldCall: props => props[EXPERIMENT_AUTOMATION_ID_PATH_PARAM] !== undefined,
    },
  })
);
