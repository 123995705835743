export enum FeatureFlag {
  EXPOSE_ARTIFACTS = 'expose-artifacts',
  HABIT_MOMENT_FILTER_USERS_WHO_DID_NOT_DO_TREATMENT = 'habit_moment_filter_users_who_did_not_do_treatment',
  AB_TEST_KPI_BOUND = 'ab-test-bound-kpi-parameter',
  PUSHER_NOTIFICATIONS = 'pusher-notifications',
  ADD_SEGMENT_FILTER_TO_KPI = 'add-segment-filter-to-kpi',
  FAVORITE_SEGMENTS = 'favorite-segments',
  KPI_PAGE = 'kpi-page',
  SEND_KPI_INSIGHTS = 'send-kpi-insights',
  BEHAVIORAL_SEGMENTS = 'behavioral-segments',
  BEHAVIORAL_MILESTONE = 'behavioral-milestone',
  HEALTH_MONITOR = 'health-monitor',
  HOMEPAGE_SUBSCRIPTION = 'homepage-subscription',
  EXPERIMENT_AUTOMATIONS = 'experiment-automations',
}
