import * as React from 'react';
import classNames from 'classnames';
import classes from './homepage-metric-list.module.scss';
import {ExtendedHomepageMetric} from '../../homepage-summary.component';
import {ModelSeriesGranularity} from '../../../../../../objects/models/model-sample-series.model';
import {
  CheckSolidIcon,
  PlusSolidIcon,
  PopoverWrapper,
  SearchIcon,
  TextInput,
  TooltipIfOverflow,
  CloseRegularIcon,
  TrendChip,
} from 'ui-components';
import {Sparklines, SparklinesLine} from 'react-sparklines';
import {takeRight, values} from 'lodash';
import {useCallback, useEffect, useLayoutEffect, useMemo, useRef} from 'react';
import {MetricValueType} from '../../../../../../objects/models/metric.model';
import {exists, number2k, withStopPropagation} from 'front-core';
import {getSampleWoWChange} from '../../homepage-summary.utils';
import {Tooltip} from '@material-ui/core';
import TransKeys from '../../../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';
import {MetricSelector} from '../metric-selector/metric-selector.component';
import {GoalStatus} from '../goal-status/goal-status.component';
import classnames from 'classnames';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';

interface OwnProps {
  searchValue: string;
  onSearchValueChange: (search: string | null) => void;
  metrics: ExtendedHomepageMetric[];
  selectedMetricId?: number;
  homepageMetricIds: number[];
  onChangeSelectedMetricId: (id: number) => void;
  onAddMetric: (id: number) => void;
  onCreateKPI: (categoryId?: number) => void;
  onDeleteMetric: (id: number) => void;
  onReorderMetrics: (ids: number[]) => void;
  addMetricButtonRef: any;
  className?: string;
}

type AllProps = OwnProps;

const METRIC_FILTERS = {
  // hasSamples: true
};

const generateListItemId = (metricId: number) => `metric-${metricId}`;

enum ListMode {
  DEFAULT,
  EDIT,
  SEARCH,
}

const DEFAULT_GRANULARITY = ModelSeriesGranularity.WEEK;
const HOMEPAGE_FIRST_METRIC_ITEM_FAKE_CLASS = 'homepage-first-metric-item';

export const HomepageMetricList: React.FC<AllProps> = (props: AllProps) => {
  const {
    metrics: metricsFromProps,
    selectedMetricId,
    onChangeSelectedMetricId,
    onAddMetric: onAddMetricFromProps,
    onCreateKPI: onCreateKPIFromProps,
    onSearchValueChange: onSearchValueChangeFromProps,
    homepageMetricIds: homepageMetricIdsFromProps,
    searchValue,
    onDeleteMetric,
    onReorderMetrics,
    addMetricButtonRef,
    className,
  } = props;
  const {t} = useTranslation();
  const selectorRef = useRef<any>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const isListItemsReady = useRef<boolean>(false);
  const [mode, setMode] = React.useState(ListMode.DEFAULT);
  const [metricOrder, setMetricOrder] = React.useState<number[]>(metricsFromProps.map(m => m.id));
  const homepageMetricIds: Set<number> = useMemo(
    () => new Set(homepageMetricIdsFromProps),
    [homepageMetricIdsFromProps]
  );
  const excludeIds = useMemo(() => metricsFromProps.map(m => m.id), [metricsFromProps]);
  const metrics = useMemo(() => {
    return metricsFromProps
      .map(metric => {
        const base = {
          ...metric,
          isOnHomepage: homepageMetricIds.has(metric.id),
          order: metricOrder.indexOf(metric.id),
        } as any;
        let series =
          metric.transformedSeries[DEFAULT_GRANULARITY] || values(metric.transformedSeries)[0];
        if (!series) {
          return base;
        }
        const lastSamples = takeRight(series.nonPartialSamples, 5).map(s => s.value);
        let [prevSample, lastSample] = takeRight(series.nonPartialSamples, 2);
        if (series.granularity === ModelSeriesGranularity.DAY) {
          prevSample = series.nonPartialSamples[series.nonPartialSamples.length - 8];
        }
        if (!lastSample) {
          return base;
        }
        let renderValue = '';
        if (metric.valueType === MetricValueType.PERCENTAGE) {
          renderValue = `${number2k(lastSample.value * 100)}%`;
        } else {
          renderValue = number2k(lastSample.value);
        }
        const trend = getSampleWoWChange(lastSample, prevSample);

        return {
          ...base,
          lastSamples,
          renderValue,
          trend,
          granularity: series.granularity,
        };
      })
      .sort((a, b) => a.order - b.order);
  }, [metricsFromProps, homepageMetricIds, metricOrder]);
  const onAddMetric = useCallback(
    (metricId: number) => {
      if (selectorRef.current) {
        selectorRef.current.close();
      }
      onAddMetricFromProps(metricId);
    },
    [onAddMetricFromProps, selectorRef]
  );
  const onCreateKPI = useCallback(
    categoryId => {
      if (selectorRef.current) {
        selectorRef.current.close();
      }
      onCreateKPIFromProps(categoryId);
    },
    [onCreateKPIFromProps, selectorRef]
  );
  const onEdit = useCallback(
    () => setMode(mode => (mode !== ListMode.EDIT ? ListMode.EDIT : ListMode.DEFAULT)),
    [setMode]
  );
  const onSearchValueChange = useCallback(
    (term: string) => {
      onSearchValueChangeFromProps(term);
      if (!exists(term)) {
        setMode(ListMode.DEFAULT);
        return;
      }
      setMode(ListMode.SEARCH);
    },
    [onSearchValueChangeFromProps]
  );
  const onDragEnd = useCallback(
    data => {
      if (!data.destination) {
        return metricOrder;
      }
      const startIndex = data.source.index;
      const endIndex = data.destination.index;
      const result = [...metricOrder];
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      setMetricOrder(result);
      onReorderMetrics(result);
    },
    [setMetricOrder, metricOrder, onReorderMetrics]
  );
  useEffect(() => {
    setMetricOrder(metricsFromProps.map(m => m.id));
  }, [metricsFromProps, setMetricOrder]);
  // this is used to bypass the issue when Draggable items are rendered long after the list is ready!
  const isListItemsReadyCurrent = isListItemsReady.current;
  useLayoutEffect(() => {
    if (listRef.current && selectedMetricId && isListItemsReadyCurrent) {
      const elem = listRef.current.querySelector(`#${generateListItemId(selectedMetricId)}`);
      if (elem === null) {
        return;
      }
      // @ts-ignore
      elem.scrollIntoViewIfNeeded?.({behavior: 'smooth'});
    }
  }, [selectedMetricId, isListItemsReadyCurrent]);

  return (
    <div className={classNames(classes.HomepageMetricList, className)}>
      <div className={classes.Search}>
        <TextInput
          value={searchValue}
          onChange={v => onSearchValueChange((v || '').toString())}
          className={classnames(classes.SearchBox, className)}
          iconClassName={classes.SearchIcon}
          inputClassName={classes.SearchInput}
          placeholder={'Search'}
          icon={SearchIcon}
          clearable
        />
        {mode !== ListMode.SEARCH && (
          <div onClick={() => onEdit()} className={classes.EditButton}>
            {mode === ListMode.EDIT
              ? t(TransKeys.GENERAL.ACTIONS.DONE)
              : t(TransKeys.GENERAL.ACTIONS.EDIT)}
          </div>
        )}
      </div>
      {/*BASED ON: https://codesandbox.io/p/sandbox/k260nyxq9v?file=%2Findex.js%3A57%2C24 */}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {provided => (
            <div
              {...provided.droppableProps}
              className={classes.List}
              ref={ref => {
                listRef.current = ref;
                provided.innerRef(ref);
              }}
            >
              {exists(searchValue) && metrics.length === 0 && (
                <div className={classes.EmptyState}>
                  {t(TransKeys.HOMEPAGE.NO_RESULTS_FOR_SEARCH_EMPTY_STATE, {
                    search_value: searchValue,
                  })}
                </div>
              )}
              {metrics.map((metric, idx) => (
                <Draggable
                  index={idx}
                  key={metric.id}
                  draggableId={metric.id.toString()}
                  isDragDisabled={mode === ListMode.SEARCH}
                >
                  {provided => (
                    <div
                      id={generateListItemId(metric.id)}
                      ref={ref => {
                        provided.innerRef(ref);
                        isListItemsReady.current = true;
                      }}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div
                        onClick={() => onChangeSelectedMetricId(metric.id)}
                        className={classNames(
                          classes.MetricItemWrapper,
                          idx === 0 && HOMEPAGE_FIRST_METRIC_ITEM_FAKE_CLASS,
                          selectedMetricId === metric.id && classes.Selected,
                          mode === ListMode.EDIT && classes.EditMode
                        )}
                      >
                        <div className={classes.MetricItem}>
                          <div className={classes.Start}>
                            {mode === ListMode.EDIT && (
                              <Tooltip
                                title={t(TransKeys.HOMEPAGE.ACTIONS.DELETE)}
                                interactive={false}
                                placement={'top'}
                              >
                                <div
                                  onClick={withStopPropagation(() => onDeleteMetric(metric.id))}
                                  className={classes.Delete}
                                >
                                  <CloseRegularIcon className={classes.Icon} />
                                </div>
                              </Tooltip>
                            )}
                            {mode === ListMode.SEARCH && (
                              <Tooltip
                                title={
                                  metric.isOnHomepage
                                    ? t(TransKeys.HOMEPAGE.ACTIONS.DELETE)
                                    : t(TransKeys.GENERAL.ACTIONS.ADD_KPI)
                                }
                                interactive={false}
                                placement={'top'}
                              >
                                <div
                                  onClick={() =>
                                    metric.isOnHomepage
                                      ? onDeleteMetric(metric.id)
                                      : onAddMetric(metric.id)
                                  }
                                  className={classNames(
                                    classes.Attach,
                                    metric.isOnHomepage && classes.Active
                                  )}
                                >
                                  {metric.isOnHomepage ? (
                                    <CheckSolidIcon className={classes.Icon} />
                                  ) : (
                                    <PlusSolidIcon className={classes.Icon} />
                                  )}
                                </div>
                              </Tooltip>
                            )}
                          </div>
                          <div className={classes.Main}>
                            <TooltipIfOverflow title={metric.name}>
                              <div className={classes.Title}>{metric.name}</div>
                            </TooltipIfOverflow>
                            {metric.goal && (
                              <GoalStatus metric={metric} granularity={metric.granularity} small />
                            )}
                            {!metric.goal && (
                              <div className={classes.MissingGoal}>
                                {t(TransKeys.HOMEPAGE.LABELS.MISSING_GOAL)}
                              </div>
                            )}
                          </div>
                          <div className={classes.Chart}>
                            {metric.lastSamples && (
                              <Sparklines height={30} width={50} data={metric.lastSamples}>
                                <SparklinesLine
                                  style={{fill: 'none', strokeWidth: 2}}
                                  color={
                                    selectedMetricId === metric.id
                                      ? 'rgba(52, 131, 255, 1)'
                                      : 'rgba(33, 33, 35, 1)'
                                  }
                                />
                              </Sparklines>
                            )}
                          </div>
                          <div className={classes.Stats}>
                            <div className={classes.Value}>{metric.renderValue}</div>
                            {metric.trend && (
                              <Tooltip
                                title={t(TransKeys.HOMEPAGE.METRIC_TREND_HELPER_TEXT, {
                                  granularity: metric.granularity,
                                })}
                                placement={'top'}
                                interactive={false}
                              >
                                <TrendChip
                                  value={metric.trend.value}
                                  isSignificant={metric.trend.isSignificant}
                                  size={'xsmall'}
                                />
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {mode === ListMode.DEFAULT && (
        <PopoverWrapper
          ref={selectorRef}
          placement={'top'}
          buttonRenderer={({onClick, isOpen}) => (
            <div className={classes.AddMetric} onClick={onClick} ref={addMetricButtonRef}>
              <PlusSolidIcon className={classes.AddIcon} />
              {t(TransKeys.GENERAL.ACTIONS.ADD_KPI)}
            </div>
          )}
        >
          <MetricSelector
            onChange={onAddMetric}
            excludeIds={excludeIds}
            filters={METRIC_FILTERS}
            onCreate={onCreateKPI}
          />
        </PopoverWrapper>
      )}
    </div>
  );
};
