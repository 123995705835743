import * as React from 'react';
import {
  MultiLoadResponse,
  SmartSelectorParameters,
  SmartSelectorSource,
  SmartSelector,
  useRemoteSourceStated,
} from 'ui-components';
import {useCallback, useContext, useEffect, useMemo} from 'react';
import {HttpClientContext} from 'front-core';
import {metricSmartSelectorNetworkRequest} from '../../../../../../http/smart-selector.network-requests';
import classes from './metric-selector.module.scss';
import {getMetricCategoriesNetworkRequest} from '../../../../../../http/metric-categories.network-requests';
import {groupBy} from 'lodash';
import {useTeamFilter} from '../../../../../../core/contexts/team-filter.context';

interface OwnProps {
  onChange: (value: number, item?: any) => void;
  excludeIds?: number[];
  emptyState?: any;
  onCreate?: (categoryId?: number) => void;
  filters?: any;
}

type AllProps = OwnProps;

export const MetricSelector: React.FC<AllProps> = (props: AllProps) => {
  const {onChange, excludeIds, filters, emptyState, onCreate} = props;
  const http = useContext(HttpClientContext);
  const {source: metricCategories, exec: getMetricCategories} = useRemoteSourceStated({
    networkRequest: getMetricCategoriesNetworkRequest,
  });
  const {teamId} = useTeamFilter();
  const sources: SmartSelectorSource[] = useMemo(() => {
    if (!metricCategories) {
      return [];
    }
    return [
      ...metricCategories
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .map(category => ({
          key: category.id,
          name: category.name,
          onSelect: item => onChange(item.id, item),
          onCreate: onCreate ? () => onCreate(category.id) : undefined,
          createLabel: `Create ${category.name} KPI`,
        })),
      {
        key: null,
        name: 'General KPIs',
        onSelect: item => onChange(item.id, item),
        onCreate: onCreate ? () => onCreate() : undefined,
        createLabel: 'Create General KPI',
      },
    ];
  }, [onChange, onCreate, metricCategories]);

  const load = useCallback(
    async (keys: string[], parameters: SmartSelectorParameters): Promise<MultiLoadResponse> => {
      // @ts-ignore
      const metrics = await http.exec(
        metricSmartSelectorNetworkRequest({
          ...(filters || {}),
          page: parameters.page,
          q: parameters.query,
          limit: 1000,
          excludeId: excludeIds,
          teamId,
        })
      );
      const res = {};
      const groups = groupBy(metrics.data, 'categoryId');
      Object.keys(groups).forEach(key => {
        res[key] = {
          data: groups[key],
          total: groups[key].length,
          hasNext: false,
        };
      });
      return res;
    },
    [http, excludeIds, filters, teamId]
  );

  useEffect(() => {
    getMetricCategories();
  }, [getMetricCategories]);

  return (
    <div className={classes.MetricSelector}>
      <SmartSelector
        className={classes.Selector}
        sources={sources}
        load={load}
        emptyState={emptyState}
        withPreview={false}
      />
    </div>
  );
};

MetricSelector.defaultProps = {};
