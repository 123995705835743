import * as React from 'react';
import {useCallback, useState} from 'react';
import {composition} from 'front-core';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {getFunnelNetworkRequest} from '../../../../http/funnels.network-requests';
import {FUNNEL_ID_PATH_PARAM, SIGNAL_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys';
import {
  Button,
  FancyHeader,
  FeatureIcon,
  FunnelIcon,
  LabelWrapper,
  ModalLayout,
  ModelType,
  PrimaryTabs,
} from 'ui-components';
import TransKeys from '../../../../constants/translation-keys';
import {Funnel, FunnelStep} from '../../../../objects/models/funnel.model';
import classes from './funnel-view-panel.module.scss';
import {useTranslation} from 'react-i18next';
import {get} from 'lodash';
import {Modifiers} from '../../../shared/components/general/modifiers/modifiers.component';
import {FunnelTabs} from '../../../shared/components/general/funnel-tabs/funnel-tabs.component';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {TitleWithIcon} from '../../../shared/components/general/title/title.component';
import {withModalErrorHandler} from '../../../../core/hoc/with-model-error-handler.hoc';
import {AdHocIndication} from '../../../shared/components/general/ad-hoc-indication/ad-hoc-indication.component';
import {useDispatch} from 'react-redux';
import {publishAdHocFunnelConfirmed} from '../../../../store/funnels/funnels.actions';
import {getSelected} from '../../../../store/selected/selected.actions';
import usePermissions from '../../../../core/hooks/use-permissions.hook';
import {Action, Subject} from '../../../../constants/permissions';
import QueryDefinition from '../../../shared/components/general/query-definition/query-definition.component';
import SignalSql from '../../../shared/components/general/signal-sql/signal-sql.component';
import {ViewModelSeries} from '../../components/view-model-series/view-model-series.component';
import {ModelSampleSeriesModel} from '../../../../objects/models/model-sample-series.model';
import {useHiddenFlag} from '../../../../core/hooks/use-hidden-flag.hook';
import {QuerySqlTabsNames} from '../../../shared/core/query-sql-tabs/query-sql-tabs.component';
import {ValidationStatus} from '../../../../objects/models/signal.model';
import {getEntityIcon} from '../../../../constants/entity.consts';

interface OwnProps {
  funnel: Funnel;
  onClose?: () => void;
  [SIGNAL_ID_PATH_PARAM]?: number;
  initialTab?: QuerySqlTabsNames;
}

type AllProps = OwnProps;

const SELECTED_FUNNEL_KEY = SharedSelectionKeys.FUNNEL_VIEW__FUNNEL;

export const FunnelViewPanelComponent: React.FC<AllProps> = (props: AllProps) => {
  const {onClose, funnel, [SIGNAL_ID_PATH_PARAM]: signalId, initialTab} = props;
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {can} = usePermissions();
  const showHidden = useHiddenFlag();
  const {productEntitiesMap} = useProductData();
  const [selectedTabKey, setSelectedTabKey] = useState(initialTab || 'query');

  const [selectedFunnelTab, setSelectedFunnelTab] = useState<string>(
    signalId
      ? funnel.steps.find(s => s.id === signalId).id.toString()
      : funnel.steps[0].id.toString()
  );

  const funnelTabs = funnel.steps.map((step: FunnelStep) => ({
    key: step.id.toString(),
    title: step.name,
    warningText:
      step.signalValidationStatus === ValidationStatus.ERROR
        ? t(TransKeys.FUNNELS.SIGNAL_VALIDATION_STATUS_HELPER_TEXT)
        : null,
    render: () => {
      const query = step.definition;
      const signalId = step.id;

      return (
        <PrimaryTabs
          key={signalId}
          selected={selectedTabKey}
          onChange={setSelectedTabKey}
          tabs={[
            {
              label: 'Definition',
              key: 'query',
              render: () => (
                <div className={classes.TabWrapper}>
                  <QueryDefinition query={query} />
                </div>
              ),
            },
            {
              label: 'SQL',
              key: 'sql',
              render: () => (
                <div className={classes.TabWrapper}>
                  <SignalSql signalId={signalId} />
                </div>
              ),
            },
            {
              label: 'Samples (admin only)',
              key: 'samples',
              hide: !showHidden,
              render: () => (
                <div className={classes.TabWrapper}>
                  <ViewModelSeries
                    modelType={ModelSampleSeriesModel.FUNNEL}
                    modelId={funnel.id}
                    entity={funnel.entity}
                    signalId={signalId}
                  />
                </div>
              ),
            },
          ]}
        />
      );
    },
  }));

  const onPublish = useCallback(
    () =>
      dispatch(
        publishAdHocFunnelConfirmed(funnel?.id, () => [getSelected(SELECTED_FUNNEL_KEY, funnel.id)])
      ),
    [dispatch, funnel?.id]
  );

  return (
    <div className={classes.FunnelViewPanelContainer}>
      <ModalLayout
        footer={
          funnel.isAdHoc &&
          can(Subject.FUNNEL, Action.CREATE) && (
            <Button
              caps={false}
              helperText={t(TransKeys.GENERAL.ACTIONS.SAVE_TO_DMP_HELPER_TEXT, {
                model: t(TransKeys.MODELS.FUNNEL),
              })}
              onClick={onPublish}
            >
              {t(TransKeys.GENERAL.ACTIONS.SAVE_TO_DMP, {model: t(TransKeys.MODELS.FUNNEL)})}
            </Button>
          )
        }
      >
        <div className={classes.FunnelViewPanel}>
          <FancyHeader
            icon={FunnelIcon}
            title={
              <>
                {t(TransKeys.MODELS.FUNNEL)}
                {funnel.isAdHoc && (
                  <AdHocIndication className={classes.AdHocIndication} model={ModelType.FUNNEL} />
                )}
              </>
            }
            caps
            onClose={onClose}
            className={classes.Header}
          />
          <div className={classes.Body}>
            <div className={classes.Block}>
              <div className={classes.Name}>{funnel.name}</div>
              {funnel.shortDescription && (
                <div className={classes.Description}>{funnel.shortDescription}</div>
              )}
            </div>
            {funnel.entity && (
              <div className={classes.Block}>
                <LabelWrapper label={t(TransKeys.GENERAL.LABELS.ENTITY)}>
                  <TitleWithIcon
                    text={productEntitiesMap[funnel.entity].name}
                    icon={getEntityIcon(funnel.entity)}
                  />
                </LabelWrapper>
              </div>
            )}
            <div className={classes.Block}>
              <LabelWrapper label={t(TransKeys.GENERAL.LABELS.STEPS)}>
                <FunnelTabs
                  tabs={funnelTabs}
                  onTabChange={setSelectedFunnelTab}
                  selectedKey={selectedFunnelTab}
                />
              </LabelWrapper>
            </div>
            {funnel.history.length > 0 && (
              <div className={classes.Block}>
                <Modifiers history={funnel.history} />
              </div>
            )}
          </div>
        </div>
      </ModalLayout>
    </div>
  );
};

const FunnelViewPanel = composition<AllProps>(
  FunnelViewPanelComponent,
  withModalErrorHandler({
    modalTitle: TransKeys.MODELS.FUNNEL,
    modalIcon: FeatureIcon,
    extractErrorCodeFromProps: props => get(props.errors, 'funnel.errorCode'),
  }),
  withLoadBefore({
    funnel: {
      selectedKey: SELECTED_FUNNEL_KEY,
      actionKey: SELECTED_FUNNEL_KEY,
      request: getFunnelNetworkRequest,
      mapPayloadFromProps: props => props[FUNNEL_ID_PATH_PARAM],
      shouldCall: props => props[FUNNEL_ID_PATH_PARAM] !== undefined,
    },
  })
);

export default FunnelViewPanel;
