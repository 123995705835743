import {action, OnSuccessActionHook} from 'front-core';
import {HomepageSettings} from '../../objects/models/homepage.model';
import {ReorderHomepageMetricsDTO, UpdateHomepageMetricDTO} from '../../objects/dto/homepage.dto';

export enum HomepageActionType {
  ADD_METRIC_TO_HOMEPAGE = '@@homepage/ADD_METRIC_TO_HOMEPAGE',
  REMOVE_METRIC_FROM_HOMEPAGE = '@@homepage/REMOVE_METRIC_FROM_HOMEPAGE',
  UPDATE_HOMEPAGE_SUBSCRIPTION = '@@homepage/UPDATE_HOMEPAGE_SUBSCRIPTION',
  REORDER_HOMEPAGE_METRICS = '@@homepage/REORDER_HOMEPAGE_METRICS',
}

export const addMetricToHomepage = (
  data: UpdateHomepageMetricDTO,
  onSuccess?: OnSuccessActionHook
) => action(HomepageActionType.ADD_METRIC_TO_HOMEPAGE, data, {onSuccess});

export const removeMetricFromHomepage = (
  data: UpdateHomepageMetricDTO,
  onSuccess?: OnSuccessActionHook
) => action(HomepageActionType.REMOVE_METRIC_FROM_HOMEPAGE, data, {onSuccess});

export const updateHomepageSubscription = (
  data: Partial<HomepageSettings>,
  onSuccess?: OnSuccessActionHook
) => action(HomepageActionType.UPDATE_HOMEPAGE_SUBSCRIPTION, data, {onSuccess});

export const reorderHomepageMetrics = (
  data: ReorderHomepageMetricsDTO,
  onSuccess?: OnSuccessActionHook
) => action(HomepageActionType.REORDER_HOMEPAGE_METRICS, data, {onSuccess});
