import yup from '../../../../../config/yup.config';
import {PARAMETERS_METADATA_KEY} from '../../../../../constants/parameters-saved-keys';
import {get, values} from 'lodash';
import {TableEntity} from '../../../../../objects/models/table.model';
import {queryElementValidatorFactory} from '../../../../../objects/dto/query-builder.dto';
import moment from 'moment/moment';
import {TIME_FORMATS} from '../../../../../constants/time-formats';
import {treatmentValidator} from '../shared-validators';
import {ParameterType} from 'ui-components';
import {
  AnalysisParametersTransformer,
  baseAnalysisParametersTransformer,
} from '../shared-transformers';

export const analysis109ParametersValidator = yup.object().shape({
  parameters: yup
    .object()
    .shape({
      [PARAMETERS_METADATA_KEY]: yup.object().nullable(),
      entity: yup.string().oneOf(values(TableEntity)).required(),
      goal: yup
        .number()
        .required()
        .test(
          'validate_kpis_not_exists_in_features',
          'KPI cannot be a released feature',
          function (value, context) {
            const {features = []} = context.parent;
            return features.indexOf(value) === -1;
          }
        ),
      secondary_goal: yup
        .array()
        .of(
          yup
            .number()
            .required()
            .test(
              'validate_kpis_not_exists_in_features',
              'KPI cannot be a released feature',
              function (value, context) {
                const {features = []} = get(context, 'from.0.value');
                return features.indexOf(value) === -1;
              }
            )
        )
        .nullable(),
      population_filter: queryElementValidatorFactory(false),
      release_date: yup.string().required(),
      ...treatmentValidator('feature_usage_tag'),
      features: yup.array().of(yup.number()).nullable(),
      max_days_from_release: yup.number().min(1).nullable(),
      days_before: yup.number().min(1),
      experiment_threshold: yup.number().min(0).max(1).required(),
      gradual_release_query: queryElementValidatorFactory(false),
      // pre_release_date: yup
      //   .string()
      //   .nullable()
      //   .test(
      //     'is_less_then_start_date',
      //     'Gradual release must be before release date',
      //     function (pre_release_date: string) {
      //       if (!exists(pre_release_date)) {
      //         return true;
      //       }
      //       const {release_date} = this.parent;
      //       if (!release_date) {
      //         return true;
      //       }
      //       const releaseDate = moment(release_date, TIME_FORMATS.PARAMETER_DATE_FORMAT);
      //       const preReleaseDate = moment(pre_release_date, TIME_FORMATS.PARAMETER_DATE_FORMAT);
      //       return releaseDate.isAfter(preReleaseDate);
      //     }
      //   ),
    })
    .required(),
  runParameters: yup
    .object()
    .shape({
      end_date: yup.string().nullable(),
    })
    .test({
      name: 'end_date_after_release_date',
      test: function (obj: any, context: any) {
        const {release_date} = context.parent.parameters;
        if (!release_date) {
          return true;
        }
        if (!obj.end_date) {
          return true;
        }
        const startDate = moment(release_date, TIME_FORMATS.PARAMETER_DATE_FORMAT);
        const endDate = moment(obj.end_date, TIME_FORMATS.PARAMETER_DATE_FORMAT);
        if (startDate.isSameOrAfter(endDate)) {
          return this.createError({
            message: 'Release date must be before end date',
            path: 'parameters.release_date',
          });
        }
        return true;
      },
    }),
});

export const analysis109ParametersTransformer: AnalysisParametersTransformer = (
  values,
  schema,
  productEntities
) => {
  [values, schema] = baseAnalysisParametersTransformer(values, schema, productEntities);
  const newValues = {...values};

  if ('release_date' in schema) {
    schema['release_date'].order = 1;
    schema['release_date'].name = 'Release Date';
  }
  if ('goal' in schema) {
    schema['goal'].order = 2;
    schema['goal'].name = 'Primary KPI';
  }
  if ('secondary_goal' in schema) {
    schema['secondary_goal'].order = 3;
    schema['secondary_goal'].name = 'Secondary KPIs';
  }
  delete schema['feature_usage_tag'];
  delete schema['treatments_signals'];
  schema['feature_usage_tag'] = {
    name: 'Scope',
    type: ParameterType.__MERGED_TREATMENTS,
    key: 'feature_usage_tag',
    order: 4,
  };
  newValues['feature_usage_tag'] = [
    ...(newValues['feature_usage_tag'] || []),
    ...(newValues['treatments_signals'] || []),
  ];
  if ('experiment_threshold' in schema) {
    schema['experiment_threshold'].order = 5;
    schema['experiment_threshold'].name = 'Statistical significance';
    schema['experiment_threshold'].type = ParameterType.STRING;
    newValues['experiment_threshold'] = `${newValues['experiment_threshold'] * 100}%`;
  }
  if ('population_filter' in schema) {
    schema['population_filter'].name = 'Experiment population';
    schema['population_filter'].order = 6;
  }
  if ('feature_usage_tag' in schema) {
    schema['feature_usage_tag'].order = 7;
  }
  if ('gradual_release_query' in schema) {
    schema['gradual_release_query'].order = 8;
    schema['gradual_release_query'].name = 'Gradual release';
  }
  if ('days_before' in schema) {
    schema['days_before'].order = 10;
    schema['days_before'].name = 'Pre-release period';
  }
  if ('features' in schema) {
    schema['features'].order = 11;
    schema['features'].name = 'Released features';
  }

  return [newValues, schema];
};
