import * as React from 'react';
import {useCallback, useMemo} from 'react';
import {isSignificantValue} from '../../../../../../../utils/general.utils';
import {ModelSampleSeries} from '../../../../../../../objects/models/model-sample-series.model';
import moment from 'moment';
import {groupBy, maxBy, minBy, values} from 'lodash';
import pluralize from 'pluralize';
import TransKeys from 'translations';
import {ANNOTATION_ICONS} from '../../../../../../../constants/ui';
import {TIME_FORMATS} from '../../../../../../../constants/time-formats';
import i18n from 'i18next';
import {useTranslation} from 'react-i18next';
import {useProductData} from '../../../../../../../core/hooks/use-product-data.hook';
import {
  HomepageAnnotation,
  AnomalyMode,
  HomepageSample,
} from '../../../../../../../objects/models/homepage.model';
import {LineChart} from 'ui-components';
import {exists} from 'front-core';
import {ConfidenceIntervalConfig} from '../../../../../../../objects/models/user-settings.model';

export interface ChartConfig {
  showCI: boolean;
  showAnnotations: boolean;
  showQuarters: boolean;
  showTarget: boolean;
}

interface OwnProps {
  metricName: string;
  series: ModelSampleSeries;
  chartConfig: ChartConfig;
  confidenceIntervalConfig: ConfidenceIntervalConfig;
  isPercentage?: boolean;
  entity?: string;
  goal?: number;
  xLabel?: string;
  hasCountEntities?: boolean;
  showLegend?: boolean;
  annotations?: HomepageAnnotation[];
  onAnnotationsClicked: (annotations: HomepageAnnotation[]) => void;
  onSampleClicked: (sample: HomepageSample) => void;
  className?: string;
}

type AllProps = OwnProps;

const GOOD_COLOR = 'green';
const BAD_COLOR = 'red';

const getPointTooltipData = (isSignificantPositive: boolean, config: ConfidenceIntervalConfig) => {
  if (isSignificantPositive === undefined || config === undefined) {
    return {
      pointTooltipTitle: undefined,
      pointTooltipCta: undefined,
    };
  }
  if (config.anomalyMode === AnomalyMode.LOOPS_ALGO) {
    if (isSignificantPositive) {
      return {
        pointTooltipTitle: i18n.t(TransKeys.HOMEPAGE.METRIC_CHART.LABELS.INVESTIGATE_RISE_TITLE),
        pointTooltipCta: i18n.t(TransKeys.HOMEPAGE.METRIC_CHART.LABELS.INVESTIGATE_RISE_CTA),
      };
    }
    return {
      pointTooltipTitle: i18n.t(TransKeys.HOMEPAGE.METRIC_CHART.LABELS.INVESTIGATE_DROP_TITLE),
      pointTooltipCta: i18n.t(TransKeys.HOMEPAGE.METRIC_CHART.LABELS.INVESTIGATE_DROP_CTA),
    };
  }
  if (config.anomalyMode === AnomalyMode.XOX_SAME_WEEKDAY) {
    if (isSignificantPositive) {
      return {
        pointTooltipTitle: i18n.t(
          TransKeys.HOMEPAGE.METRIC_CHART.LABELS.WOW_INVESTIGATE_RISE_TITLE,
          {percent: config.anomalyThreshold * 100}
        ),
        pointTooltipCta: i18n.t(TransKeys.HOMEPAGE.METRIC_CHART.LABELS.INVESTIGATE_RISE_CTA),
      };
    }
    return {
      pointTooltipTitle: i18n.t(TransKeys.HOMEPAGE.METRIC_CHART.LABELS.WOW_INVESTIGATE_DROP_TITLE, {
        percent: config.anomalyThreshold * 100,
      }),
      pointTooltipCta: i18n.t(TransKeys.HOMEPAGE.METRIC_CHART.LABELS.INVESTIGATE_DROP_CTA),
    };
  }
};

export const HomepageMetricChart: React.FC<AllProps> = (props: AllProps) => {
  const {
    metricName,
    series,
    goal,
    isPercentage,
    entity,
    hasCountEntities,
    chartConfig,
    annotations,
    xLabel = 'date',
    onSampleClicked,
    onAnnotationsClicked,
    confidenceIntervalConfig,
    showLegend,
    className,
  } = props;
  const {t} = useTranslation();
  const {productEntitiesMap} = useProductData();

  const onDatasetPointClick = useCallback(
    pointData => onSampleClicked(pointData.point.metadata.sample),
    [onSampleClicked]
  );

  const chartProps = useMemo(() => {
    if (!series || series.samples.length === 0) {
      return undefined;
    }
    let firstPartialDate = null;
    let lastPartialDate = null;
    // values dataset
    const valuesDataset = series.samples.map((s, idx) => {
      if (s.isPartial) {
        firstPartialDate = firstPartialDate === null ? s.datetime : firstPartialDate;
        lastPartialDate = s.datetime;
      }
      const isSignificant = isSignificantValue(s.value, s.lower, s.upper);
      let markColor = undefined;
      let isSignificantPositive = undefined;
      if (isSignificant && !s.isPartial) {
        if (s.value > s.upper) {
          markColor = GOOD_COLOR;
          isSignificantPositive = true;
        } else {
          markColor = BAD_COLOR;
          isSignificantPositive = false;
        }
      }
      const base: any = {
        x: s.datetime,
        y: isPercentage ? s.value * 100 : s.value,
        dashed: s.isPartial,
        markColor,
        isSignificantPositive,
        metadata: {sample: s},
        clickable: !s.isPartial,
        upper: s.isPartial || !exists(s.upper) ? undefined : isPercentage ? s.upper * 100 : s.upper,
        lower: s.isPartial || !exists(s.lower) ? undefined : isPercentage ? s.lower * 100 : s.lower,
        ...getPointTooltipData(isSignificantPositive, confidenceIntervalConfig),
      };
      return base;
    });
    // For metric which has day granularity we might have too much significant points.
    // In order to reduce the noise, we want to show only the highest significant value and the lowest significant
    // in each calendaric week
    // to do so, we will change filtered points markColor to undefined
    if (series.granularity) {
      const weekSignificantPoints = valuesDataset.reduce((acc, curr) => {
        const week = moment(curr.x).isoWeek();
        if (!acc[week]) {
          acc[week] = [curr];
        } else {
          acc[week].push(curr);
        }
        return acc;
      }, {});
      values(weekSignificantPoints).forEach(weekPoints => {
        const groupedPoints = groupBy(weekPoints, 'isSignificantPositive');
        const significantPositive = groupedPoints['true'];
        const significantNegative = groupedPoints['false'];
        if (significantPositive && significantPositive.length > 1) {
          const maxSignificantPositive = maxBy(significantPositive, 'y');
          significantPositive.forEach(point => {
            if (point !== maxSignificantPositive) {
              point.markColor = undefined;
            }
          });
        }
        if (significantNegative && significantNegative.length > 1) {
          const minSignificantNegative = minBy(significantNegative, 'y');
          significantNegative.forEach(point => {
            if (point !== minSignificantNegative) {
              point.markColor = undefined;
            }
          });
        }
      });
    }

    let denominatorDataset;
    if (hasCountEntities) {
      denominatorDataset = series.samples.map(
        s =>
          ({
            x: s.datetime,
            y: s.denominator || 0,
            dashed: s.isPartial,
          }) as any
      );
    }
    const datasets: any[] = [];
    datasets.push({
      id: 'samples',
      label: metricName,
      data: valuesDataset,
    });

    if (denominatorDataset) {
      datasets.push({
        id: `denominator`,
        label: pluralize(productEntitiesMap[entity].name),
        data: denominatorDataset,
        yAxis: 'secondary',
      });
    }

    const colors = ['#B8ADF9', '#9AB9F3'];
    const chartAnnotations = annotations.map(annotation => ({
      ...annotation,
      type: t(TransKeys.ANNOTATION.TYPE[annotation.type.toUpperCase()]),
      icon: ANNOTATION_ICONS[annotation.type],
      textColor: annotation.relatedExperimentId ? 'rgba(52, 131, 255, 1)' : undefined,
    }));

    const lines = [];
    if (goal && chartConfig.showTarget) {
      const goalValue = isPercentage ? Number((goal * 100).toFixed(2)) : goal;
      const goalValueText = isPercentage ? `${goalValue}%` : goalValue;

      lines.push({
        label: 'Target',
        title: (
          <span>
            {t(TransKeys.HOMEPAGE.LABELS.QUARTERLY_TARGET)}: <b>{goalValueText}</b>
          </span>
        ),
        description: '',
        dashed: true,
        position: goalValue,
        direction: 'horizontal',
      });
    }

    const markAreas = [];
    if (firstPartialDate && lastPartialDate) {
      markAreas.push({
        from: moment
          .utc(firstPartialDate)
          .subtract(1, series.granularity)
          .format(TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT),
        to: lastPartialDate,
        title: t(TransKeys.HOMEPAGE.CHART.INCOMPLETE_TITLE),
        description: t(TransKeys.HOMEPAGE.CHART.INCOMPLETE_DESCRIPTION),
      });
    }

    return {
      key: annotations.length,
      datasets: datasets,
      displayedDatasetIds: ['samples'],
      options: {
        annotations: chartConfig.showAnnotations ? chartAnnotations : undefined,
        lines,
        labels: {
          dateFormat: TIME_FORMATS.READABLE_DATE_NO_YEAR,
          type: 'date',
          timeUnit: series.granularity,
        },
        yLabelSuffix: isPercentage ? '%' : undefined,
        yAxisMaxTicks: 5,
        minimalXAxisTicks: true,
        errorBar: chartConfig.showCI,
        showQuarters: chartConfig.showQuarters,
        showHideCI: false,
        showHideAnnotation: false,
        xLabel: xLabel,
        colors,
        markAreas: markAreas,
        showLegend,
      },
      pointTooltipCta: t(TransKeys.HOMEPAGE.METRIC_CHART.TOOLTIP_CTA),
      pointTooltipTitle: 'Recommended Action',
      onAnnotationClicked: onAnnotationsClicked,
      onDatasetPointClick: onDatasetPointClick,
    } as any;
  }, [
    series,
    metricName,
    isPercentage,
    hasCountEntities,
    productEntitiesMap,
    onDatasetPointClick,
    xLabel,
    annotations,
    chartConfig,
    onAnnotationsClicked,
    confidenceIntervalConfig,
    entity,
    goal,
    showLegend,
    t,
  ]);

  return <LineChart {...chartProps} className={className} />;
};
