import {Epic} from 'redux-observable';
import {ActionKey} from '../../constants/action-key';
import HttpClient from '../../services/http-client.service';
import {modelCreated, modelDeleted, notifyEvent} from '../core/core.actions';
import {createRequestEpic} from 'front-core';
import {AmplitudeEvent} from '../../constants/amplitude-event';
import {showToastMessage} from '../interface/interface.actions';
import {ToastType} from '../../objects/system/toast-type.enum';
import {HomepageActionType} from './homepage.actions';
import {
  addMetricToHomepageNetworkRequest,
  removeMetricFromHomepageNetworkRequest,
  reorderHomepageMetricsNetworkRequest,
  updateHomepageSubscriptionNetworkRequest,
} from '../../http/homepage.network-requests';
import {ModelKey} from '../../constants/model-key';
import {demoProductLimitedActionsFilter} from '../store.utils';

export const addMetricToHomepageEpic: Epic = createRequestEpic(
  {
    types: [HomepageActionType.ADD_METRIC_TO_HOMEPAGE],
    actionKey: ActionKey.ADD_METRIC_TO_HOMEPAGE,
    request: addMetricToHomepageNetworkRequest,
    onSuccess: (_, data) => [
      modelCreated(data, ModelKey.HOMEPAGE),
      notifyEvent(AmplitudeEvent.ADD_METRIC_TO_HOMEPAGE, data),
    ],
    onError: err_ => [showToastMessage('Error adding KPI to homepage', ToastType.ERROR)],
  },
  HttpClient
);

export const removeMetricFromHomepageEpic: Epic = createRequestEpic(
  {
    types: [HomepageActionType.REMOVE_METRIC_FROM_HOMEPAGE],
    actionKey: ActionKey.REMOVE_METRIC_FROM_HOMEPAGE,
    request: removeMetricFromHomepageNetworkRequest,
    onSuccess: (_, data) => [
      modelDeleted(data, ModelKey.HOMEPAGE),
      notifyEvent(AmplitudeEvent.REMOVE_METRIC_FROM_HOMEPAGE, data),
    ],
    onError: err_ => [showToastMessage('Error adding KPI to homepage', ToastType.ERROR)],
  },
  HttpClient
);

export const updateHomepageSubscriptionEpic: Epic = createRequestEpic(
  {
    types: [HomepageActionType.UPDATE_HOMEPAGE_SUBSCRIPTION],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.UPDATE_HOMEPAGE_SUBSCRIPTION,
    request: updateHomepageSubscriptionNetworkRequest,
    onSuccess: configuration => [
      showToastMessage('Updated Homepage Subscription', ToastType.SUCCESS),
      notifyEvent(AmplitudeEvent.UPDATE_HOMEPAGE_SUBSCRIPTION, configuration),
    ],
    onError: err_ => [
      showToastMessage('Error while updating homepage subscription', ToastType.ERROR),
    ],
  },
  HttpClient
);

export const reorderHomepageMetricsEpic: Epic = createRequestEpic(
  {
    types: [HomepageActionType.REORDER_HOMEPAGE_METRICS],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.REORDER_HOMEPAGE_METRICS,
    request: reorderHomepageMetricsNetworkRequest,
    onSuccess: () => [notifyEvent(AmplitudeEvent.REORDER_HOMEPAGE_METRICS)],
    onError: err_ => [showToastMessage('Error while updating homepage', ToastType.ERROR)],
  },
  HttpClient
);
