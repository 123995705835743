import * as React from 'react';
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import classes from './metric-page.module.scss';
import {
  ANALYSIS_TYPE_ID_PATH_PARAM,
  AppRoutes,
  GOAL_ID_PATH_PARAM,
  METRIC_ID_PATH_PARAM,
} from '../../../../constants/app-routes';
import {useHistory, useParams} from 'react-router';
import PageLayout from '../../../shared/components/layout/page-layout/index';
import {PageHeader} from '../../../shared/components/layout/page-header/page-header.component';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys';
import {useDispatch, useSelector} from 'react-redux';
import {
  createSelected,
  getSelected,
  removeSelected,
} from '../../../../store/selected/selected.actions';
import {ModelKey} from '../../../../constants/model-key';
import {
  getMetricPageNetworkRequest,
  getRCAForMetricByDate,
} from '../../../../http/metrics.network-requests';
import {
  getReducedLoadingStateSelector,
  getSingleSelectedSelector,
} from '../../../../store/store.selectors';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {MetricPageHeader} from './components/metric-page-header/metric-page-header.component';
import {InputKPIs} from './components/input-kpis/input-kpis.component';
import {
  addInputMetric,
  removeInputMetric,
  setMetricOwner,
} from '../../../../store/metrics/metrics.actions';
import {
  registerActionListener,
  removeActionListener,
} from '../../../../store/actions-listener/actions-listener.actions';
import {CoreActionsType} from '../../../../store/core/core.actions';
import {
  DocumentElementType,
  FancyBlock,
  useRemoteSourceStated,
  RCAFigureViewer,
  LabelWrapper,
  Chip,
  ChipVariant,
  CircleInfoRegularIcon,
  TextInput,
  RootDocumentViewer,
  GraphIcon,
  UsersIcon,
  RocketLaunchLightIcon,
  WandMagicSparklesLightIcon,
} from 'ui-components';
import {HomepageMetricViewer} from '../../../homepage/components/homepage-summary/components/homepage-metric-viewer/homepage-metric-viewer.component';
import {PanelKey} from '../../../../constants/panels';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {HomepageAnnotation} from '../../../../objects/models/homepage.model';
import moment from 'moment';
import {TIME_FORMATS} from '../../../../constants/time-formats';
import {KPISegmentation} from './components/document-fragments/kpi-segmentation/kpi-segmentation.component';
import {MetricPage as MetricPageModel} from '../../../../objects/models/metric-page.model';
import {GoalDrivers} from './components/document-fragments/kpi-drivers/goal-drivers.component';
import {EngagementDrivers} from './components/document-fragments/kpi-drivers/engagement-drivers.component';
import {MetricSuggestions} from '../../../homepage/components/homepage-summary/components/metric-suggestions/metric-suggestions.component';
import {ModelSeriesGranularity} from '../../../../objects/models/model-sample-series.model';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {StringParam, useQueryParam, withDefault} from 'use-query-params';
import {values} from 'lodash';
import {AnalysisResults} from '../../../shared/core/document-viewer/analysis-results.component';
import {getAnalysisResultNetworkRequest} from '../../../../http/analysis-results.network-requests';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import QueryDefinition from '../../../shared/components/general/query-definition/query-definition.component';
import {PopulationSegmentQueryBuilder} from '../../../shared/core/query-builders/population-segment-query-builder/population-segment-query-builder.component';
import SignalSql from '../../../shared/components/general/signal-sql/signal-sql.component';
import {RCADatePicker} from './components/rca-date-picker/rca-date-picker.component';
import {exists, HttpClientContext} from 'front-core';
import {RCAPanelFormParameters} from '../../../shared/follow-ups/panels/rca-follow-up-panel/rca-follow-up-panel.component';
import {AmplitudeEvent} from '../../../../constants/amplitude-event';
import {MetricType} from '../../../../objects/models/metric.model';
import {AggregationMode} from '../../../../objects/models/signal.model';
import {AppRCAOverviewViewer} from '../../../shared/core/document-viewer/viewers/app-rca-overview-viewer.component';
import {DocumentFragmentFooter} from './components/document-fragments/document-fragment-footer/document-fragment-footer.component';
import queryString from 'query-string';

interface OwnProps {}

type AllProps = OwnProps;

const SELECTED_METRIC_KEY = SharedSelectionKeys.KPI_PAGE__METRIC;

export enum MetricPageTab {
  RCA = 'RCA',
  PERFORMANCE = 'performance',
  DEFINITION = 'definition',
}

export const METRIC_PAGE_DATE_QUERY_PARAM = 'date';
export const METRIC_PAGE_TAB_QUERY_PARAM = 'tab';

const getInitialDate = () => {
  const parsed = queryString.parse(window.location.search);
  return parsed[METRIC_PAGE_DATE_QUERY_PARAM];
};

export const MetricPage: React.FC<AllProps> = (props: AllProps) => {
  const {[METRIC_ID_PATH_PARAM]: metricIdFromParams} = useParams<any>();
  const http = useContext(HttpClientContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const {t} = useTranslation();
  const {userHomepageId, userSettings} = useProductData();
  const {openSecondaryPanel} = useContext(PanelsContext);
  const initialDateRef = useRef(getInitialDate());
  const tabsRef = useRef<HTMLDivElement>(null);
  const metricId = Number(metricIdFromParams);
  const [tab, setTab] = useQueryParam<string>(
    METRIC_PAGE_TAB_QUERY_PARAM,
    withDefault(StringParam, MetricPageTab.RCA)
  );
  const [rcaDate, setRCADate] = useQueryParam<string>(METRIC_PAGE_DATE_QUERY_PARAM, StringParam);
  const [rcaAnalysisResultId, setRCAAnalysisResultId] = useState(null);
  const {
    source: analysisResult,
    exec: getRCA,
    isLoading: isLoadingResult,
  } = useRemoteSourceStated({
    networkRequest: getAnalysisResultNetworkRequest,
  });

  const metric: MetricPageModel = useSelector(state =>
    getSingleSelectedSelector(SELECTED_METRIC_KEY, state)
  );
  const isLoading = useSelector(state =>
    getReducedLoadingStateSelector(SELECTED_METRIC_KEY)(state)
  );
  const onRequestAnalysis = useCallback(
    (analysisTypeId: number, parameters: any) => {
      openSecondaryPanel(PanelKey.ANALYSIS_FORM_PANEL, {
        [ANALYSIS_TYPE_ID_PATH_PARAM]: analysisTypeId,
        parameters,
      });
    },
    [openSecondaryPanel]
  );
  const onAddInputKPI = useCallback(
    (inputMetricId: number) =>
      dispatch(
        addInputMetric({
          metricId: metric.id,
          inputMetricId,
        })
      ),
    [dispatch, metric]
  );
  const onRemoveInputKPI = useCallback(
    (inputMetricId: number) =>
      dispatch(
        removeInputMetric({
          metricId: metric.id,
          inputMetricId,
        })
      ),
    [dispatch, metric]
  );
  const onCreateEditGoal = useCallback(
    (goalId?: number) => {
      openSecondaryPanel(PanelKey.GOAL_FORM_PANEL, {
        [METRIC_ID_PATH_PARAM]: metricId,
        [GOAL_ID_PATH_PARAM]: goalId,
      });
    },
    [openSecondaryPanel, metricId]
  );
  const onAnnotationsClicked = useCallback(
    (annotations: HomepageAnnotation[]) => {
      openSecondaryPanel(PanelKey.HOMEPAGE_ANNOTATIONS_PANEL, {
        date: moment(annotations[0].timestamp).format(TIME_FORMATS.PARAMETER_DATE_FORMAT),
      });
    },
    [openSecondaryPanel]
  );
  const onEdit = useCallback(
    () =>
      openSecondaryPanel(PanelKey.METRIC_FORM_PANEL, {
        [METRIC_ID_PATH_PARAM]: metricId,
      }),
    [openSecondaryPanel, metricId]
  );
  const onOwnerChange = useCallback(
    (userId: number) =>
      dispatch(
        setMetricOwner({
          metricId: metric.id,
          userId,
        })
      ),
    [dispatch, metric]
  );
  const granularity = useMemo(() => {
    if (!metric) {
      return;
    }
    const week = metric.series.find(s => s.granularity === ModelSeriesGranularity.WEEK);
    if (week) {
      return ModelSeriesGranularity.WEEK;
    }
    return metric.series[0].granularity;
  }, [metric]);
  const onChangeRCADate = useCallback(
    async (date: string) => {
      if (!granularity) {
        return;
      }
      const analysisResultId: any = await http.exec(
        getRCAForMetricByDate({
          metricId,
          date: moment.utc(date, 'YYYY-MM-DD').format(TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT),
          granularity,
        })
      );
      if (!analysisResultId) {
        let analysisMode = undefined;
        if (metric.type === MetricType.USAGE || metric.type === MetricType.REVENUE) {
          analysisMode = AggregationMode.SUM;
        }
        const rcaPanelFormParams: RCAPanelFormParameters = {
          goal: metric.signalId,
          timeAggregation: granularity,
          startDateAnomaly: date,
          useLoopsAnomalyDetectionAlgo: true,
          entity: metric.entity,
          higherIsBetter: metric.higherIsBetter,
          analysisMode: analysisMode,
        };
        openSecondaryPanel(PanelKey.RCA_FOLLOW_UP_PANEL, {
          fromToLabel: moment(date).format(TIME_FORMATS.DEFAULT_DATE_FORMAT),
          title: t(TransKeys.HOMEPAGE.LINE_CHART_WITH_FOLLOW_UP_RCA.PANEL.TITLE, {
            change: 'changed',
            timeGranularity: t(
              TransKeys.GENERAL.LABELS.TIME_GRANULARITY[granularity.toUpperCase()]
            ),
          }),
          notifyAmplitudeSubmitted:
            AmplitudeEvent.CREATE_RCA_FOLLOW_UP_ACTION_FROM_HOMEPAGE_LINE_CHART_CLICKED,
          analysisFormParameters: rcaPanelFormParams,
        });
        return;
      }
      setRCAAnalysisResultId(analysisResultId);
      setRCADate(date, 'replaceIn');
    },
    [setRCADate, http, metricId, granularity, metric, openSecondaryPanel, t]
  );
  const tabs = useMemo(
    () =>
      values(MetricPageTab).map(tab => ({
        key: tab,
        label: t(TransKeys.METRIC_PAGE.TABS[tab.toUpperCase()]),
      })),
    [t]
  );
  const onSampleClicked = useCallback(
    sample =>
      onChangeRCADate(
        moment
          .utc(sample.datetime, TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT)
          .format(TIME_FORMATS.PARAMETER_DATE_FORMAT)
      ),
    [onChangeRCADate]
  );
  const documentComponentsMap = useMemo(
    () => ({
      [DocumentElementType.RCA_FIGURE]: props => (
        <RCAFigureViewer {...props} renderSideMenu={false} renderHeaderName={false} />
      ),
      [DocumentElementType.RCA_OVERVIEW_FIGURE]: props => (
        <AppRCAOverviewViewer {...props} onSampleClicked={onSampleClicked} />
      ),
      [DocumentElementType.DOCUMENT]: props => (
        <RootDocumentViewer {...props} className={classes.Document} />
      ),
    }),
    [onSampleClicked]
  );
  useEffect(() => {
    dispatch(
      createSelected({
        selectedKey: SELECTED_METRIC_KEY,
        actionKey: SELECTED_METRIC_KEY,
        request: getMetricPageNetworkRequest,
        modelKey: ModelKey.METRIC_OF_KPI_PAGE,
      })
    );
    return () => {
      dispatch(removeSelected(SELECTED_METRIC_KEY));
    };
  }, [dispatch]);
  useEffect(() => {
    metricId && dispatch(getSelected(SELECTED_METRIC_KEY, metricId));
  }, [metricId, dispatch]);
  useEffect(() => {
    let requestedDate = rcaDate;
    if (rcaDate) {
      requestedDate = rcaDate;
    } else if (metric && metric.rcaAnalysisResultId) {
      requestedDate = metric.rcaDate;
    } else if (metric && metric.maxSampleDate) {
      requestedDate = metric.maxSampleDate;
    }
    onChangeRCADate(requestedDate);
  }, [metric, onChangeRCADate, rcaDate]);
  useEffect(() => {
    rcaAnalysisResultId && getRCA(rcaAnalysisResultId);
  }, [rcaAnalysisResultId, getRCA, initialDateRef, tabsRef]);
  const onDocumentResolved = useCallback(() => {
    if (!exists(initialDateRef.current)) {
      return;
    }
    setTimeout(() => {
      tabsRef.current?.scrollIntoView({behavior: 'smooth'});
      initialDateRef.current = null;
    }, 0);
  }, [initialDateRef, tabsRef]);

  useEffect(() => {
    const listener = action => {
      const {modelKey, data} = action.payload;
      if (modelKey === ModelKey.METRIC && data.id === metricId) {
        dispatch(getSelected(SELECTED_METRIC_KEY, metricId));
      }
    };
    dispatch(registerActionListener(CoreActionsType.MODEL_UPDATED, listener));
    return () => {
      dispatch(removeActionListener(CoreActionsType.MODEL_UPDATED, listener));
    };
  }, [dispatch, metricId]);

  const renderTab = () => {
    if (tab === MetricPageTab.RCA) {
      return (
        <div className={classes.RCA}>
          {isLoadingResult && <GenericLoading />}
          <div className={classes.Result}>
            {analysisResult && (
              <AnalysisResults
                analysisResult={analysisResult}
                isLoading={isLoadingResult}
                componentsMap={documentComponentsMap}
                onDocumentResolvedSuccess={onDocumentResolved}
                navigationDisabled
              />
            )}
          </div>
        </div>
      );
    }
    if (tab === MetricPageTab.PERFORMANCE) {
      return (
        <>
          <div className={classes.Section}>
            <div className={classes.TitleWrapper}>
              <div className={classes.Title}>
                <GraphIcon />
                <span>{t(TransKeys.KPI_PAGE.SECTIONS.KPI_GRAPH.TITLE)}</span>
              </div>
            </div>
            <FancyBlock addPadding className={classes.GraphView}>
              <HomepageMetricViewer
                isLoading={isLoading}
                metricId={metricId}
                homepageId={userHomepageId}
                onAnnotationsClicked={onAnnotationsClicked}
                onCreateEditGoal={onCreateEditGoal}
                configuration={{
                  anomalyMode: userSettings.anomalyMode,
                  anomalyThreshold: userSettings.anomalyThreshold,
                }}
                showHeader={false}
              />
            </FancyBlock>
          </div>
          {metric.kpiAnalysisResultId && (
            <div className={classes.Section}>
              <div className={classes.TitleWrapper}>
                <div className={classes.Title}>
                  <UsersIcon />
                  <span>{t(TransKeys.KPI_PAGE.SECTIONS.KPI_SEGMENTATION.TITLE)}</span>
                </div>
              </div>
              <FancyBlock>
                <KPISegmentation analysisResultId={metric.kpiAnalysisResultId} />
              </FancyBlock>
              <DocumentFragmentFooter
                analysisId={metric.kpiAnalysisId}
                analysisResultId={metric.kpiAnalysisResultId}
                buttonText={t(TransKeys.KPI_PAGE.SECTIONS.KPI_SEGMENTATION.FOOTER_BUTTON_TEXT)}
                text={<span>{t(TransKeys.KPI_PAGE.SECTIONS.KPI_SEGMENTATION.FOOTER_TEXT)}</span>}
              />
            </div>
          )}
          {metric.goalDriversAnalysisResultId && (
            <div className={classes.Section}>
              <div className={classes.TitleWrapper}>
                <div className={classes.Title}>
                  <RocketLaunchLightIcon />
                  <span>{t(TransKeys.KPI_PAGE.SECTIONS.GOAL_DRIVERS.TITLE)}</span>
                </div>
              </div>
              <GoalDrivers analysisResultId={metric.goalDriversAnalysisResultId} />
              <DocumentFragmentFooter
                analysisId={metric.goalDriversAnalysisId}
                analysisResultId={metric.goalDriversAnalysisResultId}
                buttonText={t(TransKeys.KPI_PAGE.SECTIONS.GOAL_DRIVERS.FOOTER_BUTTON_TEXT)}
                text={<span>{t(TransKeys.KPI_PAGE.SECTIONS.GOAL_DRIVERS.FOOTER_TEXT)}</span>}
              />
            </div>
          )}
          {metric.engagementDriversAnalysisResultId && (
            <div className={classes.Section}>
              <div className={classes.TitleWrapper}>
                <div className={classes.Title}>
                  <RocketLaunchLightIcon />
                  <span>{t(TransKeys.KPI_PAGE.SECTIONS.ENGAGEMENT_DRIVERS.TITLE)}</span>
                </div>
              </div>
              <EngagementDrivers analysisResultId={metric.engagementDriversAnalysisResultId} />
              <DocumentFragmentFooter
                analysisId={metric.engagementDriversAnalysisId}
                analysisResultId={metric.engagementDriversAnalysisResultId}
                buttonText={t(TransKeys.KPI_PAGE.SECTIONS.ENGAGEMENT_DRIVERS.FOOTER_BUTTON_TEXT)}
                text={<span>{t(TransKeys.KPI_PAGE.SECTIONS.ENGAGEMENT_DRIVERS.FOOTER_TEXT)}</span>}
              />
            </div>
          )}
          <div className={classes.Section}>
            <div className={classes.TitleWrapper}>
              <div className={classes.Title}>
                <WandMagicSparklesLightIcon />
                {t(TransKeys.HOMEPAGE.METRIC_SUGGESTIONS.TITLE, {name: metric.name})}
              </div>
            </div>
            <FancyBlock addPadding>
              <MetricSuggestions
                metric={metric as any}
                experiments={[]}
                onRequestAnalysis={onRequestAnalysis}
                granularity={granularity}
              />
            </FancyBlock>
          </div>
          <div className={classes.SpacerBottom} />
        </>
      );
    }
    if (tab === MetricPageTab.DEFINITION) {
      return (
        <div className={classes.DefinitionTab}>
          <FancyBlock title={'Definition'} addPadding className={classes.Definition}>
            <QueryDefinition query={metric.signalDefinition} />
            {metric.signalPopulationFilter && (
              <LabelWrapper
                className={classes.SegmentationFilter}
                label={
                  <div className={classes.SegmentationFilterLabel}>
                    <span>{t(TransKeys.METRIC_FORM_PANEL.INPUTS.SEGMENTATION_FILTER.LABEL)}</span>
                    <Chip label={'Beta'} variant={ChipVariant.NEW} size={'xsmall'} />
                  </div>
                }
                helperText={
                  <div className={classes.SegmentationFilterHelperText}>
                    <CircleInfoRegularIcon />
                    {t(TransKeys.METRIC_FORM_PANEL.INPUTS.SEGMENTATION_FILTER.HELPER_TEXT)}
                  </div>
                }
              >
                <PopulationSegmentQueryBuilder query={metric.signalPopulationFilter} disabled />
              </LabelWrapper>
            )}
            {metric.aggregationMode && (
              <LabelWrapper
                className={classes.AggregationModeContainer}
                label={t(TransKeys.METRIC_FORM_PANEL.INPUTS.AGGREGATION_MODE.LABEL)}
              >
                <TextInput
                  disabled={true}
                  value={
                    t(
                      TransKeys.ANALYSIS_FORMS.ANALYSIS_101.ANALYSIS_MODES[
                        metric.aggregationMode.toUpperCase()
                      ]?.LABEL
                    ) as any
                  }
                />
              </LabelWrapper>
            )}
          </FancyBlock>
          <FancyBlock title={'SQL'} className={classes.SQL}>
            <div className={classes.SQLContent}>
              <SignalSql signalId={metric.signalId} border={false} />
            </div>
          </FancyBlock>
        </div>
      );
    }
  };

  if (!metric) {
    return <GenericLoading />;
  }

  return (
    <PageLayout.Layout>
      <PageHeader
        title={metric.name}
        crumbs={[
          {
            name: 'KPIs',
            navigateTo: AppRoutes.homepage(),
          },
        ]}
      />
      <PageLayout.Body noPadding isLoading={false}>
        <div className={classes.MetricPage}>
          <div className={classes.Main}>
            <div className={classes.ContentSection}>
              <MetricPageHeader
                metric={metric}
                onEdit={onEdit}
                onOwnerChange={onOwnerChange}
                className={classes.Header}
              />
              <InputKPIs
                metric={metric}
                onAddKPI={metricId => onAddInputKPI(metricId)}
                onRemoveKPI={metricId => onRemoveInputKPI(metricId)}
                onKPIClicked={metricId => history.push(AppRoutes.viewMetric(metricId))}
              />
            </div>
            <div className={classes.TabsWrapper}>
              <div className={classes.Tabs} ref={tabsRef}>
                {tabs.map(t => (
                  <div
                    key={t.key}
                    onClick={() => setTab(t.key, 'replaceIn')}
                    className={classNames(classes.Tab, tab === t.key && classes.Selected)}
                  >
                    {t.label}
                    {t.key === MetricPageTab.RCA && (
                      <div className={classes.DateSelection}>
                        <span className={classes.For}>for</span>
                        <RCADatePicker
                          selected={rcaDate}
                          onChange={onChangeRCADate}
                          maxDate={metric.maxSampleDate}
                          granularity={granularity as any}
                          isLoading={isLoadingResult}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div
              className={classNames(
                classes.ContentSection,
                tab !== MetricPageTab.RCA && classes.MarginBottom
              )}
            >
              {renderTab()}
            </div>
          </div>
        </div>
      </PageLayout.Body>
    </PageLayout.Layout>
  );
};
