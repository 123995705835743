import {NotSignificantUsersChange, RCAInsightSentence} from './objects';
import sharedClasses from '../insights.module.scss';
import {
  ChangeInTotalEntitiesRCAInsightSentencePart,
  SegmentNameRCAInsightSentencePart,
} from '../components/shared-sentence-parts/shared-sentence-parts.components';

interface OwnProps extends RCAInsightSentence {
  formattedSentenceParts: NotSignificantUsersChange;
}
type AllProps = OwnProps;

const NotSignificantUsersChangeFormatter: React.FC<AllProps> = (props: AllProps) => {
  const {formattedSentenceParts} = props;
  const {segmentName, changeInTotalEntities, entityChangeDescription, entity} =
    formattedSentenceParts;

  return (
    <div className={sharedClasses.InsightSentenceContainer}>
      <SegmentNameRCAInsightSentencePart segmentName={segmentName} />
      <span className={sharedClasses.InsightSentencePart}>{`had`}</span>
      <ChangeInTotalEntitiesRCAInsightSentencePart changeInTotalEntities={changeInTotalEntities} />
      <span
        className={sharedClasses.InsightSentencePart}
      >{`${entityChangeDescription} ${entity}  compared to the prior week.`}</span>
    </div>
  );
};

export {NotSignificantUsersChangeFormatter};
