import * as React from 'react';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import classNames from 'classnames';
import classes from '../../analysis-forms.module.scss';
import {TimeFrameSelector} from '../../components/ui-selectors/time-frame-selector/time-frame-selector.component';
import {ParametersFormContext} from '../../../../shared/core/parameters-form/parameters-form.context';
import {startEndDatesDefaultHandler} from '../../../../shared/core/parameters-form/parameters-form-default-handlers.utils';
import {SegmentFilterSelector} from '../../components/ui-selectors/segment-filter-selector/segment-filter-selector.component';
import {ParameterInputWrapper} from '../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import {FunnelSmartSelector} from '../../../../shared/core/smart-selector/funnel-smart-selector.component';
import {SimpleBooleanSelector} from '../../components/ui-selectors/simple-boolean-selector/simple-boolean-selector.component';
import TransKeys from '../../../../../constants/translation-keys';
import {ExtendedParameters} from '../../../../shared/form/form-layout/extended-parameters/extended-parameters.component';
import {useTranslation} from 'react-i18next';
import {DaysCountSelector} from '../../components/ui-selectors/days-count-selector/days-count-selector.component';
import {AnalysisFormProps} from '../../analysis-forms.types';
import {TableEntity, TableEntityBinding} from '../../../../../objects/models/table.model';
import {values} from 'lodash';
import {createUndefinedObject, hasError} from '../../../../../utils/general.utils';
import {EntitySelector} from '../../components/ui-selectors/entity-selector/entity-selector.component';
import {useProductData} from '../../../../../core/hooks/use-product-data.hook';
import {PanelKey} from '../../../../../constants/panels';
import {PanelsContext} from '../../../../../core/contexts/panels.context';
import {ALLOW_AD_HOC_PROP_KEY} from '../../../../../constants/shared-component-prop-key';
import {FUNNEL_ID_PATH_PARAM} from '../../../../../constants/app-routes';
import {SegmentCategoriesSelector} from '../../components/ui-selectors/segment-categories-selector/segment-categories-selector.component';
import {AnalysisParametersFormMode} from '../../../panels/analysis-form-panel/components/analysis-parameters-tab/analysis-parameters-tab.component';
import {AnalysisSelectorVariant} from '../../components/ui-selectors/analysis-selector.types';
import {Action, Subject} from '../../../../../constants/permissions';
import usePermissions from '../../../../../core/hooks/use-permissions.hook';
import {
  AnalysisFormPart,
  AnalysisFormSentence,
  AnalysisSimpleForm,
} from '../../components/simple-form-layout/analysis-simple-form.component';
import {ConfoundersSelector} from '../../components/ui-selectors/confounders-selector/confounders-selector.component';
import {SignalDataType, SignalType} from '../../../../../objects/models/signal.model';

export const FUNNEL_PARAMETER_KEY = 'funnel';
const LINEARITY_SCHEMA_MAPPING = {boolean_key: 'linearity'};
const FILTER_NEW_USERS_SCHEMA_MAPPING = {boolean_key: 'filter_new_users'};
export const MAX_DAYS_SCHEMA_KEYS = {
  days_count_key: 'max_days_for_conversion',
};
const entitySchemaMapping = {
  entity: 'entity',
};
const segmentFilterSchemaMapping = {
  population_filter: 'population_filter',
};
const segmentCategoriesSchemaMapping = {
  included_segments_tag: 'included_segments_tag',
  included_segments_signals: 'included_segments_signals',
};
const confoundersSelectorMap = {
  confounders_tag: 'confounders_tag',
  confounders_signals: 'confounders_signals',
};
const CONFOUNDERS_SIGNAL_FILTERS = [
  {type: SignalType.MEASURE, data_type: [SignalDataType.BOOLEAN]},
  {
    type: SignalType.DIMENSION,
    data_type: [
      SignalDataType.STRING,
      SignalDataType.INTEGER,
      SignalDataType.DECIMAL,
      SignalDataType.BOOLEAN,
    ],
  },
];

export const Analysis49Form: React.FC<AnalysisFormProps> = (props: AnalysisFormProps) => {
  const {className, onSignalInfo} = props;
  const {productEntities, defaultTableEntity} = useProductData();
  const {
    errors,
    parameters,
    formMode,
    changeParametersValue,
    registerDefaultHandler,
    removeDefaultHandler,
  } = useContext(ParametersFormContext);
  const {openSecondaryPanel} = useContext(PanelsContext);
  const {can} = usePermissions();
  const [isOpenAdvancedParams, setIsOpenAdvancedParams] = useState(false);
  const {t} = useTranslation();
  const entityContext = parameters[entitySchemaMapping.entity];
  const funnelFilters = useMemo(
    () => ({
      entityContext: entityContext,
      entityBinding: TableEntityBinding.ONE_WAY,
    }),
    [entityContext]
  );

  useEffect(() => {
    registerDefaultHandler('start_end_dates', startEndDatesDefaultHandler);
    registerDefaultHandler('analysis_49', parameters => {
      return {
        [entitySchemaMapping.entity]: defaultTableEntity,
        [LINEARITY_SCHEMA_MAPPING.boolean_key]: true,
        [MAX_DAYS_SCHEMA_KEYS.days_count_key]: 7,
      };
    });

    return () => {
      removeDefaultHandler('start_end_dates');
      removeDefaultHandler('analysis_49');
    };
  }, [registerDefaultHandler, removeDefaultHandler, defaultTableEntity]);

  const onChangeEntityContext = useCallback(
    (entity: TableEntity) => {
      const resetKeys = Array.from(
        new Set([
          FUNNEL_PARAMETER_KEY,
          ...values(segmentFilterSchemaMapping),
          ...values(segmentCategoriesSchemaMapping),
        ])
      );
      const resetParameters = createUndefinedObject(resetKeys);
      changeParametersValue({
        [entitySchemaMapping.entity]: entity,
        ...resetParameters,
      });
    },
    [changeParametersValue]
  );
  const onCreateEditFunnel = useCallback(
    (funnelId?: number) => {
      openSecondaryPanel(PanelKey.FUNNEL_FORM_PANEL, {
        [ALLOW_AD_HOC_PROP_KEY]: true,
        [FUNNEL_ID_PATH_PARAM]: funnelId,
        onSuccess: funnel => {
          changeParametersValue({[FUNNEL_PARAMETER_KEY]: funnel.id});
        },
      });
    },
    [openSecondaryPanel, changeParametersValue]
  );
  const isOpenAdvancedParamsOrError = useMemo(() => {
    return isOpenAdvancedParams || hasError(errors, [...values(FILTER_NEW_USERS_SCHEMA_MAPPING)]);
  }, [isOpenAdvancedParams, errors]);
  const renderFunnelSelector = (inline = false) => (
    <FunnelSmartSelector
      placeholder={inline ? 'funnel' : 'Select funnel'}
      value={parameters[FUNNEL_PARAMETER_KEY]}
      onChange={v => changeParametersValue({[FUNNEL_PARAMETER_KEY]: v})}
      onCreate={can(Subject.FUNNEL, Action.CREATE) ? onCreateEditFunnel : undefined}
      onEdit={can(Subject.FUNNEL, Action.EDIT) ? onCreateEditFunnel : undefined}
      onSignalInfo={onSignalInfo}
      filters={funnelFilters}
      error={Boolean(errors[FUNNEL_PARAMETER_KEY])}
      addButton={
        can(Subject.FUNNEL, Action.CREATE) && formMode === AnalysisParametersFormMode.ADVANCED
      }
    />
  );

  const renderAdvancedMode = () => (
    <div className={classNames(classes.AnalysisForm, className)}>
      <EntitySelector
        value={parameters}
        productEntities={productEntities}
        schemaKeysMapping={entitySchemaMapping}
        onChange={v => onChangeEntityContext(v[entitySchemaMapping.entity])}
        className={classes.Parameter}
      />
      <ParameterInputWrapper
        title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_49.FUNEEL_DEFINITION.TITLE)}
        subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_49.FUNEEL_DEFINITION.SUB_TITLE)}
        helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_49.FUNEEL_DEFINITION.HELPER_TEXT)}
        className={classes.Parameter}
        error={errors?.[FUNNEL_PARAMETER_KEY]}
      >
        {renderFunnelSelector()}
      </ParameterInputWrapper>
      {/*used to solve race condition in SegmentCategoriesForAnalysisSmartSelector*/}
      {entityContext && (
        <SegmentCategoriesSelector
          onChange={changeParametersValue}
          value={parameters}
          schemaKeysMapping={segmentCategoriesSchemaMapping}
          className={classes.Parameter}
          entityContext={entityContext}
          errors={errors}
        />
      )}
      <SimpleBooleanSelector
        title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_49.STEPS_ORDER.TITLE)}
        subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_49.STEPS_ORDER.SUB_TITLE)}
        helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_49.STEPS_ORDER.HELPER_TEXT)}
        text={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_49.STEPS_ORDER.LABEL)}
        className={classes.Parameter}
        onChange={changeParametersValue}
        schemaKeysMapping={LINEARITY_SCHEMA_MAPPING}
        value={parameters}
        errors={errors}
      />
      <DaysCountSelector
        title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_49.MAX_DAYS_FOR_CONVERSION.TITLE)}
        subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_49.MAX_DAYS_FOR_CONVERSION.SUB_TITLE)}
        helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_49.MAX_DAYS_FOR_CONVERSION.HELPER_TEXT)}
        prefix={'Complete funnel within'}
        className={classes.Parameter}
        schemaKeysMapping={MAX_DAYS_SCHEMA_KEYS}
        value={parameters}
        errors={errors}
        onChange={changeParametersValue}
        clearable
      />
      <TimeFrameSelector className={classes.Parameter} errors={errors} />
      {entityContext && (
        <SegmentFilterSelector
          onChange={changeParametersValue}
          value={parameters}
          className={classes.Parameter}
          schemaKeysMapping={segmentFilterSchemaMapping}
          entityContext={entityContext}
          errors={errors}
        />
      )}
      <ExtendedParameters
        className={classes.SpaceBottom}
        label={t(TransKeys.GENERAL.LABELS.ADVANCED_PARAMETERS)}
        isOpen={isOpenAdvancedParamsOrError}
        onOpenChanged={() => setIsOpenAdvancedParams(!isOpenAdvancedParams)}
      >
        <SimpleBooleanSelector
          title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_49.FILTER_NEW_USERS.TITLE)}
          subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_49.FILTER_NEW_USERS.SUB_TITLE)}
          helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_49.FILTER_NEW_USERS.HELPER_TEXT)}
          className={classes.Parameter}
          text={
            'Include only users who joined during the time frame (important for activation funnel)'
          }
          onChange={changeParametersValue}
          schemaKeysMapping={FILTER_NEW_USERS_SCHEMA_MAPPING}
          value={parameters}
          errors={errors}
        />
        <ConfoundersSelector
          onChange={changeParametersValue}
          value={parameters}
          schemaKeysMapping={confoundersSelectorMap}
          entityContext={entityContext}
          signalFilters={CONFOUNDERS_SIGNAL_FILTERS}
          className={classes.Parameter}
        />
      </ExtendedParameters>
    </div>
  );

  const renderSimpleMode = () => (
    <AnalysisSimpleForm
      parameters={parameters}
      errors={errors}
      changeParametersValue={changeParametersValue}
      onChangeEntityContext={onChangeEntityContext}
    >
      <AnalysisFormSentence>
        <AnalysisFormPart>
          {t(TransKeys.ANALYSIS_FORMS.ANALYSIS_49.SIMPLE.SEGMENT_SELECTOR_PREFIX)}
        </AnalysisFormPart>
        <AnalysisFormPart>
          {entityContext && (
            <SegmentCategoriesSelector
              onChange={changeParametersValue}
              value={parameters}
              schemaKeysMapping={segmentCategoriesSchemaMapping}
              className={classes.Parameter}
              entityContext={entityContext}
              errors={errors}
              variant={AnalysisSelectorVariant.INLINE}
            />
          )}
        </AnalysisFormPart>
        <AnalysisFormPart>
          {t(TransKeys.ANALYSIS_FORMS.ANALYSIS_49.SIMPLE.KPI_SELECTOR_PREFIX)}
        </AnalysisFormPart>
        <AnalysisFormPart>{renderFunnelSelector(true)}</AnalysisFormPart>
      </AnalysisFormSentence>
    </AnalysisSimpleForm>
  );

  return formMode === AnalysisParametersFormMode.ADVANCED
    ? renderAdvancedMode()
    : renderSimpleMode();
};
