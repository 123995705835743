import {TestConfig} from 'yup/lib/util/createValidation';
import {get, values} from 'lodash';
import {exists} from 'front-core';
import moment from 'moment/moment';
import {TIME_FORMATS} from '../../../../constants/time-formats';
import httpClientService from '../../../../services/http-client.service';
import {
  validateAnalysisTimeframeRequest,
  validateSignalBoundingDaysRequest,
} from '../../../../http/validations.network-requests';
import TransKeys from 'translations';
import i18n from 'i18next';
import yup from '../../../../config/yup.config';
import {SqlElementType} from 'ui-components';
import {
  queryBuilderValidators,
  queryElementValidatorFactory,
} from '../../../../objects/dto/query-builder.dto';
import {SQL_PROPERTY_TEMPLATE_NAME} from '../../../shared/core/query-builders/custom-sql-query-builder/custom-sql-query-builder.component';
import {TableType} from '../../../../objects/models/table.model';
import {AggregationStrategy, SignalDataType} from '../../../../objects/models/signal.model';
import {BEHAVIORAL_CHURN_TEMPLATE_NAME} from '../../../shared/core/query-builders/behavioral-churn-query-builder/behavioral-churn-query-builder.component';
import {HABIT_MOMENT_TEMPLATE_NAME} from '../../../shared/core/query-builders/habit-moment-query-builder/habit-moment-query-builder.component';
import {METADATA_KEY} from '../../../../constants/parameters-saved-keys';
import {ComputedTemplatesNames} from '../../../../constants/query-builder';

export const TIME_UNIT_OPTIONS = ['day', 'week', 'month', 'year'];

export const startDateBeforeEndDateTest = (
  optionalEndDate: boolean = false
): TestConfig<any, any> => ({
  name: 'end_after_start',
  test: function (dates, context) {
    const startDate = moment(dates.start_date, TIME_FORMATS.PARAMETER_DATE_FORMAT);
    if (optionalEndDate && !exists(dates.end_date)) {
      return true;
    }
    const endDate = moment(dates.end_date, TIME_FORMATS.PARAMETER_DATE_FORMAT);
    if (endDate.isBefore(startDate)) {
      return this.createError({
        message: 'Invalid time frame, start date must be before end date',
        path: `${context.path}.start_date`,
      });
    }
    return true;
  },
});

export const startEndDatesValidator = yup
  .object()
  .shape({
    start_date: yup.string().required(),
    end_date: yup.string().required(),
  })
  .test(startDateBeforeEndDateTest());

export const validateSignalTimeframeBoundingFor = (props: {
  signalIdParameter?: string;
  signalQueryParameter?: string;
  parameterName?: string;
  startDateParameterName?: string;
  endDateParameterName?: string;
  parametersPrefix?: string;
  timeAggregationParameterName?: string;
  timeAggregationDefaultValue?: string;
}): TestConfig<any, any> => ({
  name: 'validate_timeframe_bounding_for',
  test: async function (runParameters: any, context) {
    const {
      signalIdParameter,
      signalQueryParameter,
      parameterName,
      startDateParameterName = 'start_date',
      endDateParameterName = 'end_date',
      parametersPrefix = 'parameters',
      timeAggregationParameterName = 'time_granularity',
      timeAggregationDefaultValue = null,
    } = props;
    let parameters = context.parent;
    if (parametersPrefix) {
      parameters = get(context.parent, parametersPrefix);
    }
    const timeAggregation = timeAggregationParameterName
      ? get(parameters, timeAggregationParameterName)
      : timeAggregationDefaultValue;
    const signalId = signalIdParameter ? get(parameters, signalIdParameter) : null;
    const signalQuery = signalQueryParameter ? get(parameters, signalQueryParameter) : null;
    if (!exists(signalId) && !exists(signalQueryParameter)) {
      return true;
    }

    const startDate = moment(
      runParameters[startDateParameterName],
      TIME_FORMATS.PARAMETER_DATE_FORMAT
    );
    const endDate = moment(runParameters[endDateParameterName], TIME_FORMATS.PARAMETER_DATE_FORMAT);
    try {
      await httpClientService.exec(
        validateAnalysisTimeframeRequest({
          timeAggregation,
          signalId,
          signalQuery,
          startDate: startDate.format(TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT),
          endDate: endDate.format(TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT),
        })
      );
      return true;
    } catch (e: any) {
      const errorParameters = e.data.parameters || {};
      const {minimum_date, signal_name, template_name, count, unit} = errorParameters;
      let message = '';
      const transMessage = template_name
        ? TransKeys.ANALYSIS_VALIDATIONS.TIMEFRAME_BOUNDING[template_name.toUpperCase()]
        : null;
      if (transMessage) {
        message = i18n.t(transMessage, {
          count,
          unit: `${unit}s`,
          signal_name: signal_name ? ` (${signal_name})` : '',
          parameter_name: parameterName,
          minimum_date: minimum_date ? moment(minimum_date).format(TIME_FORMATS.READABLE_DATE) : '',
        });
      } else {
        message = e.data.message;
      }
      return this.createError({
        message,
        path: `${context.path}.${startDateParameterName}`,
      });
    }
  },
});

export const segmentsGroupsValidator = (
  includedSegmentsTagName: string = 'included_segments_tag',
  includedSegmentsSignalsName: string = 'included_segments_signals'
) => ({
  [includedSegmentsTagName]: yup.string().test({
    name: 'required_if_included_segment_signals_not_exists',
    test: function (v, context) {
      const segments_signals = context.parent[includedSegmentsSignalsName];
      if (exists(v)) {
        return true;
      }
      if (!exists(segments_signals) || segments_signals.length === 0) {
        return this.createError({
          message: 'Required',
        });
      }
      return true;
    },
  }),
  [includedSegmentsSignalsName]: yup
    .array()
    .of(yup.number())
    .when(includedSegmentsTagName, {
      is: s => !exists(s),
      then: schema => schema.required().min(1),
      otherwise: schema => schema.nullable(),
    }),
});

export const treatmentValidator = (
  treatmentsTagName: string = 'treatments_tag',
  treatmentsSignalsName: string = 'treatments_signals'
) => ({
  [treatmentsTagName]: yup
    .string()
    .nullable()
    .test({
      name: 'required_if_treatments_signals_not_exists',
      test: function (v, context) {
        const treatment_signals = context.parent[treatmentsSignalsName];
        if (exists(v)) {
          return true;
        }
        if (!exists(treatment_signals) || treatment_signals.length === 0) {
          return this.createError({
            message: 'Required',
          });
        }
        return true;
      },
    }),
  [treatmentsSignalsName]: yup
    .array()
    .of(yup.number())
    .when(treatmentsTagName, {
      is: s => !exists(s),
      then: schema => schema.required().min(1),
      otherwise: schema => schema.nullable(),
    }),
});

export const confoundersValadator = {
  confounders_tag: yup.string().nullable(),
  confounders_signals: yup.array().of(yup.number()).nullable(),
};

export const queryValidator = (required: boolean = true) =>
  yup.lazy(obj => {
    if (!required && !exists(obj)) {
      return yup.object().nullable();
    }
    const type = obj?.type;
    if (!type) {
      return yup.object().required();
    }
    switch (type) {
      case SqlElementType.TEMPLATE:
        if (obj?.template === 'bounded_action_ts') {
          return yup.object().shape({
            parameters: yup
              .array()
              .of(yup.mixed())
              .length(5)
              // @ts-ignore
              .byPosition([
                queryElementValidatorFactory(true),
                yup.number().required(),
                yup.number().required(),
                yup.string().oneOf(TIME_UNIT_OPTIONS).required(),
                queryElementValidatorFactory(true),
              ]),
          });
        }
        if (obj?.template === 'bounded_actions_ts') {
          return yup.object().shape({
            parameters: yup
              .array()
              .of(yup.mixed())
              .length(5)
              // @ts-ignore
              .byPosition([
                yup.array().of(queryElementValidatorFactory(true)).min(1),
                yup.number().required(),
                yup.number().required(),
                yup.string().oneOf(TIME_UNIT_OPTIONS).required(),
                queryElementValidatorFactory(true),
              ]),
          });
        }
        if (obj?.template === 'dod' || obj?.template === 'wow' || obj?.template === 'mom') {
          return yup.object().shape({
            parameters: yup
              .array()
              .of(yup.mixed())
              .length(1)
              // @ts-ignore
              .byPosition([yup.array().of(queryElementValidatorFactory(true)).min(1)]),
          });
        }
        if (obj?.template === SQL_PROPERTY_TEMPLATE_NAME) {
          return yup.object().shape({
            parameters: yup
              .array()
              .of(yup.mixed())
              .length(12)
              // @ts-ignore
              .byPosition([
                yup.string().required(), // sql_definition
                yup.string().oneOf(values(TableType)).required(), // table_type
                yup.string().oneOf(values(SignalDataType)).required(), // data_type
                yup.string().required(), // entity_id_column
                yup.string().required(), // value_column
                yup.string().oneOf(values(AggregationStrategy)).required(), // dimension_aggregation
                yup.string().nullable(), // slug
                yup.string().when('parameters.1', {
                  is: TableType.STATE,
                  then: yup.string().oneOf(TIME_UNIT_OPTIONS).nullable(),
                  otherwise: yup.string().nullable(),
                }), // table_granularity
                yup.string().when('parameters', {
                  is: () => obj.parameters[1] === TableType.ENTITY_PROPERTIES,
                  then: yup.string().nullable(),
                  otherwise: yup.string().required(),
                }), // timestamp_column
                yup.string().when('parameters.1', {
                  is: () => obj.parameters[1] === TableType.ENTITY_PROPERTIES,
                  then: yup.string().nullable(),
                  otherwise: yup.string().oneOf(values(AggregationStrategy)),
                }), // time_aggregation
                yup.string().nullable(), // event_name_column
                yup.string().nullable(), // partition_ts_column
              ]),
          });
        }
        if (obj?.template === BEHAVIORAL_CHURN_TEMPLATE_NAME) {
          return yup.object().shape({
            parameters: yup
              .array()
              .of(yup.mixed())
              .length(3)
              // @ts-ignore
              .byPosition([
                queryElementValidatorFactory(true),
                yup.string().oneOf(TIME_UNIT_OPTIONS).required(),
                yup.number().required(),
              ]),
          });
        }
        if (obj?.template === HABIT_MOMENT_TEMPLATE_NAME) {
          return yup.object().shape({
            parameters: yup
              .array()
              .of(yup.mixed())
              .length(6)
              // @ts-ignore
              .byPosition([
                yup.array().of(queryElementValidatorFactory(true)).min(1),
                queryElementValidatorFactory(true),
                yup.string().required(),
                yup.number().required(),
                yup.string().required(),
                yup.number().required(),
              ]),
          });
        }
        if (obj?.template === 'dau' || obj?.template === 'wau' || obj?.template === 'mau') {
          return yup.object().shape({
            parameters: yup
              .array()
              .of(yup.mixed())
              .length(1)
              // @ts-ignore
              .byPosition([yup.array().of(queryElementValidatorFactory(true)).min(1)]),
          });
        }
        if (obj?.template === 'l7' || obj?.template === 'l28') {
          return yup.object().shape({
            parameters: yup
              .array()
              .of(yup.mixed())
              .length(1)
              // @ts-ignore
              .byPosition([yup.array().of(queryElementValidatorFactory(true)).min(1)]),
          });
        }
        if (obj?.template === 'rate') {
          return yup.object().shape({
            parameters: yup
              .array()
              .of(yup.mixed())
              .length(2)
              // @ts-ignore
              .byPosition([queryElementValidatorFactory(true), queryElementValidatorFactory(true)]),
          });
        }
        if (obj?.template === 'subscription_retention') {
          return yup.object().shape({
            parameters: yup
              .array()
              .of(yup.mixed())
              .length(3)
              // @ts-ignore
              .byPosition([
                yup.string().required(),
                yup.number().required(),
                yup.string().required(),
              ]),
            ui_metadata: yup.object().shape({
              [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: yup.string().required(),
              [METADATA_KEY.PLAN_SIGNAL_ID_KEY]: yup.number().required(),
              [METADATA_KEY.FIRST_PAYMENT_SIGNAL_ID_KEY]: yup.number().required(),
              [METADATA_KEY.CHURN_SIGNAL_ID_KEY]: yup.number().required(),
            }),
          });
        }
        if (
          [ComputedTemplatesNames.LAST_VALUE, ComputedTemplatesNames.FIRST_VALUE].includes(
            obj?.template
          )
        ) {
          return yup.object().shape({
            parameters: yup
              .array()
              .of(yup.mixed())
              .length(3)
              // @ts-ignore
              .byPosition([
                queryElementValidatorFactory(true),
                yup.string().required(),
                yup.string().required(),
              ]),
          });
        }
        if (
          [
            ComputedTemplatesNames.SIMPLE_FIRST_TIME,
            ComputedTemplatesNames.SIMPLE_LAST_TIME,
          ].includes(obj?.template)
        ) {
          return yup.object().shape({
            parameters: yup
              .array()
              .of(yup.mixed())
              .length(1)
              // @ts-ignore
              .byPosition([queryElementValidatorFactory(true)]),
          });
        }
        return queryBuilderValidators[type];
      default:
        return queryBuilderValidators[type];
    }
  });

export const timeRangeValidator = (
  countParameter: string,
  unitParameter: string, // default "day"
  message: string,
  canBeEquals: boolean = false
): TestConfig<any, any> => {
  return {
    name: 'time_range_validation',
    test: function (runParameters: any, context: any) {
      const {parameters} = context.parent;
      const startDate = moment(runParameters.start_date, TIME_FORMATS.PARAMETER_DATE_FORMAT);
      const endDate = moment(runParameters.end_date, TIME_FORMATS.PARAMETER_DATE_FORMAT);
      const totalRunDuration = Math.floor(endDate.diff(startDate, 'day'));
      const goalDuration = Math.floor(
        moment
          .duration(get(parameters, countParameter), get(parameters, unitParameter, 'day'))
          .asDays()
      );
      const hasError = canBeEquals
        ? totalRunDuration < goalDuration
        : totalRunDuration <= goalDuration;
      if (hasError) {
        return this.createError({
          message: message,
          path: 'runParameters.start_date',
        });
      }
      return true;
    },
  };
};

export const validateSignalBoundingDays = (props: {
  signalIdParameter?: string;
  signalQueryParameter?: string;
  optional?: boolean;
}): TestConfig<any, any> => ({
  name: 'validate_goal_query_bounding_days',
  test: async function (days: any, context: any) {
    const {signalIdParameter, signalQueryParameter, optional = false} = props;
    const signalId = signalIdParameter ? get(context.parent, signalIdParameter) : null;
    const signalQuery = signalQueryParameter ? get(context.parent, signalQueryParameter) : null;

    if (!exists(days) && optional) {
      return true;
    }

    try {
      await httpClientService.exec(
        validateSignalBoundingDaysRequest({
          numberOfDays: days,
          signalId,
          signalQuery,
        })
      );
      return true;
    } catch (e: any) {
      return this.createError({
        message:
          e.data?.message ||
          `The preliminary period needs to be shorter than time-bound you defined`,
        path: context.path,
      });
    }
  },
});
