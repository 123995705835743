import * as React from 'react';
import {composition} from 'front-core';
import {
  withPartialDocument,
  WithPartialDocumentInjectedProps,
} from '../../../../../../shared/core/document-viewer/with-partial-document-hoc/with-partial-document.hoc';
import {DocumentElementType, TreatmentAdoptionSimulationFigure} from 'ui-components';
import {TreatmentAdoption} from './treatment-adoption.component';

interface OwnProps extends WithPartialDocumentInjectedProps {
  className?: string;
}

type AllProps = OwnProps;

const EngagementDriversComponent: React.FC<AllProps> = (props: AllProps) => {
  const {analysisResult, elementData, className} = props;
  let {[DocumentElementType.ENGAGEMENT_DRIVERS_SIMULATION_FIGURE]: data} = elementData as any;
  const drivers = data as TreatmentAdoptionSimulationFigure;

  if (!drivers) {
    return null;
  }

  return (
    <TreatmentAdoption feature={drivers} analysisResult={analysisResult} className={className} />
  );
};

export const EngagementDrivers = composition<AllProps>(
  EngagementDriversComponent,
  withPartialDocument({
    elementType: [DocumentElementType.ENGAGEMENT_DRIVERS_SIMULATION_FIGURE],
  })
);
