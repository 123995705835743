import * as React from 'react';
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import classNames from 'classnames';
import classes from './homepage-metric-viewer.module.scss';
import {ExtendedHomepageMetric} from '../../homepage-summary.component';
import {captureException} from '@sentry/react';
import {
  HomepageAnnotation,
  AnomalyMode,
  HomepageSample,
} from '../../../../../../objects/models/homepage.model';
import {MetricType, MetricValueType} from '../../../../../../objects/models/metric.model';
import {ModelSeriesGranularity} from '../../../../../../objects/models/model-sample-series.model';
import moment from 'moment/moment';
import {capitalize, intersection, values} from 'lodash';
import TransKeys from 'translations';
import {TIME_FORMATS} from '../../../../../../constants/time-formats';
import {useProductData} from '../../../../../../core/hooks/use-product-data.hook';
import {useTranslation} from 'react-i18next';
import {extendHomepageMetric, extractXLabelFromMetric} from '../../homepage-summary.utils';
import {CircularProgress} from '@material-ui/core';
import {
  ActionsDropdown,
  DateRange,
  DateRangeDialog,
  PopoverWrapper,
  RCAMode,
  Select,
  SettingsIcon,
  useLocalStorage,
  useRemoteSourceStated,
} from 'ui-components';
import {PanelKey} from '../../../../../../constants/panels';
import {ANALYSIS_RESULT_ID_PATH_PARAM, AppRoutes} from '../../../../../../constants/app-routes';
import {notifyEvent} from '../../../../../../store/core/core.actions';
import {AmplitudeEvent} from '../../../../../../constants/amplitude-event';
import {useDispatch} from 'react-redux';
import {PanelsContext} from '../../../../../../core/contexts/panels.context';
import {
  ChartConfig,
  HomepageMetricChart,
} from './homepage-metric-chart/homepage-metric-chart.component';
import {getHomepageMetricDataNetworkRequest} from '../../../../../../http/homepage.network-requests';
import {GenericLoading} from '../../../../../shared/components/general/generic-loading/generic-loading.component';
import {HomepageMetricHeader} from './homepage-metric-header/homepage-metric-header.component';
import {isSignificantValue} from '../../../../../../utils/general.utils';
import {RCAPanelFormParameters} from '../../../../../shared/follow-ups/panels/rca-follow-up-panel/rca-follow-up-panel.component';
import {AggregationMode} from '../../../../../../objects/models/signal.model';
import {DurationInputArg1, DurationInputArg2} from 'moment';
import {exists, HttpClientContext} from 'front-core';
import {useHistory} from 'react-router';
import {researchSampleNetworkRequest} from '../../../../../../http/model-sample-series.network-requests';
import {METRIC_PAGE_DATE_QUERY_PARAM} from '../../../../../metrics/pages/metric-page/metric-page.component';
import {ConfidenceIntervalConfig} from '../../../../../../objects/models/user-settings.model';

interface OwnProps {
  metricId: number;
  homepageId: number;
  configuration?: ConfidenceIntervalConfig;
  onAnnotationsClicked: (annotations: HomepageAnnotation[]) => void;
  onCreateEditGoal: (goalId?: number) => void;
  onViewMetric?: () => void;
  onViewMetricOpportunities?: () => void;
  isLoading: boolean;
  showCI?: boolean;
  showHeader?: boolean;
  showOptions?: boolean;
  showLegend?: boolean;
  className?: string;
}

type AllProps = OwnProps;

const NOT_SAMPLED_METRIC_TYPES = [MetricType.CUSTOM_SQL];

interface TimeframeOption {
  count: DurationInputArg1;
  unit: DurationInputArg2;
}

const TIMEFRAME_OPTIONS: TimeframeOption[] = [
  {count: 30, unit: 'd'},
  {count: 3, unit: 'M'},
  {count: 6, unit: 'M'},
  // {count: 12, unit: 'M'},
];
const DEFAULT_TIMEFRAME = TIMEFRAME_OPTIONS[1];
const HOMEPAGE_CHART_CONFIG = 'hp-chart-config';
const DEFAULT_CHART_CONFIG: ChartConfig = {
  showCI: true,
  showAnnotations: true,
  showQuarters: true,
  showTarget: true,
};

const getDateFromTimeframeOption = (lastValid?: string, option?: TimeframeOption) => {
  return moment
    .utc(
      lastValid ? lastValid : undefined,
      lastValid ? TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT : undefined
    )
    .startOf('d')
    .subtract(option?.count || 0, option?.unit || 'd')
    .format(TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT);
};

const SHOW_ALL_POINTS_END_DATE = moment
  .utc()
  .startOf('d')
  .add(1, 'y')
  .format(TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT);

interface Filters {
  fromDate?: string;
  toDate?: string;
}

export const HomepageMetricViewer: React.FC<AllProps> = (props: AllProps) => {
  const {
    homepageId,
    metricId,
    onAnnotationsClicked,
    onCreateEditGoal,
    onViewMetric,
    onViewMetricOpportunities,
    configuration,
    showHeader,
    showOptions,
    showLegend,
    className,
    isLoading: isLoadingFromProps,
  } = props;
  const homepageRef = useRef<number>(homepageId);
  homepageRef.current = homepageId;
  const {productEntitiesMap, defaultSource} = useProductData();
  const {t} = useTranslation();
  const history = useHistory();
  const http = useContext(HttpClientContext);
  const dispatch = useDispatch();

  const customSelectorRef = useRef<any>(null);
  const [isLoadingRCA, setIsLoadingRCA] = useState(false);
  const [showAllPartialPoints, setShowAllPartialPoints] = useState(false);
  const [filters, setFilters] = useState<Filters>(() => ({
    fromDate: getDateFromTimeframeOption(defaultSource.lastValidDate, DEFAULT_TIMEFRAME),
    toDate: getDateFromTimeframeOption(defaultSource.lastValidDate),
  }));
  const [isCustomRange, setIsCustomRange] = useState(false);
  const [xLabel, setXLabel] = useState<string>('');
  const {openSecondaryPanel} = useContext(PanelsContext);
  const [chartConfig, setChartConfig] = useLocalStorage<ChartConfig>(
    HOMEPAGE_CHART_CONFIG,
    DEFAULT_CHART_CONFIG
  );
  const [granularity, setGranularity] = useState<ModelSeriesGranularity>(
    ModelSeriesGranularity.WEEK
  );
  const {
    source: data,
    exec: getMetric,
    isLoading,
  } = useRemoteSourceStated({
    networkRequest: getHomepageMetricDataNetworkRequest,
  });
  const {metric: rawMetric, annotations = []} = data || {};
  const metric: ExtendedHomepageMetric = useMemo(
    () => (rawMetric ? extendHomepageMetric(rawMetric) : undefined),
    [rawMetric]
  );
  const metricInstanceId = metric?.id;
  const reviewedSeries = useMemo(() => {
    if (!metric) {
      return undefined;
    }
    return metric.transformedSeries[granularity] || values(metric.transformedSeries)[0];
  }, [metric, granularity]);
  const isJustCreated = useMemo(() => {
    if (!metric) {
      return;
    }
    const createdOn = moment.utc(metric.createdOn, TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT);
    return (
      moment.utc().diff(createdOn, 'hour') < 12 &&
      (!reviewedSeries || reviewedSeries.samples.length === 0) &&
      NOT_SAMPLED_METRIC_TYPES.indexOf(metric.type) === -1
    );
  }, [reviewedSeries, metric]);
  const onRCAFollowUp = useCallback(
    async (sample: HomepageSample) => {
      const rcaMode =
        configuration.anomalyMode === AnomalyMode.LOOPS_ALGO
          ? RCAMode.LOOPS_ALGO
          : RCAMode.COMPARE_TO_DATE;
      if (exists(sample.rcaAnalysisId) && exists(sample.rcaAnalysisResultId)) {
        history.push(
          AppRoutes.viewMetric(metric.id, {
            rcaMode,
            [METRIC_PAGE_DATE_QUERY_PARAM]: moment
              .utc(sample.datetime)
              .format(TIME_FORMATS.PARAMETER_DATE_FORMAT),
          })
        );
        return;
      }
      try {
        setIsLoadingRCA(true);
        const res: any = await http.exec(researchSampleNetworkRequest(sample.id));
        setIsLoadingRCA(false);
        if (reviewedSeries.granularity === ModelSeriesGranularity.DAY) {
          history.push(
            AppRoutes.viewAnalysis(res.analysisId, {
              [ANALYSIS_RESULT_ID_PATH_PARAM]: res.analysisResultId,
              rcaMode,
            })
          );
          return;
        }
        history.push(
          AppRoutes.viewMetric(metric.id, {
            rcaMode,
            [METRIC_PAGE_DATE_QUERY_PARAM]: moment
              .utc(sample.datetime)
              .format(TIME_FORMATS.PARAMETER_DATE_FORMAT),
          })
        );
        dispatch(
          notifyEvent(AmplitudeEvent.RCA_TRIGGERED_FROM_CHART_HOMEPAGE, {metric_id: metric.id})
        );
      } catch (error) {
        captureException(error);
        const increased = sample.value > sample.upper;
        const isSignificant = isSignificantValue(sample.value, sample.lower, sample.upper);
        const change = isSignificant
          ? t(TransKeys.GENERAL.LABELS[increased ? 'INCREASED' : 'DECREASED'])
          : 'changed';
        let analysisMode = undefined;
        if (metric.type === MetricType.USAGE || metric.type === MetricType.REVENUE) {
          analysisMode = AggregationMode.SUM;
        }
        const rcaPanelFormParams: RCAPanelFormParameters = {
          goal: metric.signalId,
          timeAggregation: reviewedSeries.granularity,
          startDateAnomaly: sample.datetime,
          useLoopsAnomalyDetectionAlgo: true,
          entity: metric.entity,
          higherIsBetter: metric.higherIsBetter,
          analysisMode: analysisMode,
        };
        openSecondaryPanel(PanelKey.RCA_FOLLOW_UP_PANEL, {
          fromToLabel: moment(sample.datetime).format(TIME_FORMATS.DEFAULT_DATE_FORMAT),
          title: t(TransKeys.HOMEPAGE.LINE_CHART_WITH_FOLLOW_UP_RCA.PANEL.TITLE, {
            change,
            timeGranularity: t(
              TransKeys.GENERAL.LABELS.TIME_GRANULARITY[reviewedSeries.granularity.toUpperCase()]
            ),
          }),
          notifyAmplitudeSubmitted:
            AmplitudeEvent.CREATE_RCA_FOLLOW_UP_ACTION_FROM_HOMEPAGE_LINE_CHART_CLICKED,
          analysisFormParameters: rcaPanelFormParams,
        });
        dispatch(
          notifyEvent(AmplitudeEvent.RCA_MODAL_TRIGGERED_FROM_CHART_HOMEPAGE, {
            metric_id: metric.id,
          })
        );
      }
    },
    [t, openSecondaryPanel, dispatch, reviewedSeries, metric, history, http, configuration]
  );
  const onChangeGranularity = useCallback(
    (v: ModelSeriesGranularity) => {
      setGranularity(v);
    },
    [setGranularity]
  );
  const getXLabel = useCallback(async () => {
    if (!metric) {
      return;
    }
    const xLabel = await extractXLabelFromMetric(
      metric,
      productEntitiesMap[metric.entity].name,
      granularity
    );
    setXLabel(capitalize(xLabel));
  }, [metric, productEntitiesMap, granularity]);
  const granularityOptions = useMemo(() => {
    if (!metric) {
      return;
    }
    const base = [ModelSeriesGranularity.DAY, ModelSeriesGranularity.WEEK];
    const actualOptions = metric.series.map(s => s.granularity);
    const granularities = intersection(base, actualOptions);
    if (granularities.length < 2) {
      return;
    }
    return {
      options: [ModelSeriesGranularity.DAY, ModelSeriesGranularity.WEEK].map(g => ({
        label: t(TransKeys.GENERAL.LABELS.GRANULARITY[g.toUpperCase()]),
        value: g,
      })),
    };
  }, [t, metric]);
  const changeFilters = useCallback(
    (newFilters: Filters) =>
      setFilters(filters => ({
        ...filters,
        ...newFilters,
      })),
    [setFilters]
  );
  const onTimeframeOptionSelected = useCallback(
    (option: TimeframeOption) => {
      setFilters(filters => ({
        ...filters,
        fromDate: getDateFromTimeframeOption(defaultSource.lastValidDate, option),
        toDate: showAllPartialPoints
          ? SHOW_ALL_POINTS_END_DATE
          : getDateFromTimeframeOption(defaultSource.lastValidDate),
      }));
      setIsCustomRange(false);
    },
    [setFilters, setIsCustomRange, showAllPartialPoints, defaultSource.lastValidDate]
  );
  const onCustomDatesSelected = useCallback(
    (value: DateRange) => {
      changeFilters({
        fromDate: moment
          .utc(value.from)
          .startOf('d')
          .format(TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT),
        toDate: moment.utc(value.to).startOf('d').format(TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT),
      });
      setIsCustomRange(true);
      customSelectorRef.current?.close();
    },
    [changeFilters, customSelectorRef]
  );
  const onChangeShowPartialPoint = useCallback(
    (show: boolean) => {
      setShowAllPartialPoints(show);
      changeFilters({
        toDate: show
          ? SHOW_ALL_POINTS_END_DATE
          : getDateFromTimeframeOption(defaultSource.lastValidDate),
      });
    },
    [setShowAllPartialPoints, changeFilters, defaultSource.lastValidDate]
  );
  const chartActions = useMemo(() => {
    if (!metric) {
      return [];
    }
    return [
      {
        title: `${chartConfig.showAnnotations ? 'Hide' : 'Show'} annotations`,
        onClick: () =>
          setChartConfig({...chartConfig, showAnnotations: !chartConfig.showAnnotations}),
      },
      {
        title: `${chartConfig.showTarget ? 'Hide' : 'Show'} target`,
        onClick: () => setChartConfig({...chartConfig, showTarget: !chartConfig.showTarget}),
        hide: !metric.goal,
      },
      {
        title: t(TransKeys.HOMEPAGE.ACTIONS.DEFINE_QUARTERLY_TARGET),
        onClick: () => onCreateEditGoal(),
        hide: Boolean(metric.goal),
      },
      {
        title: `${chartConfig.showCI ? 'Hide' : 'Show'} confidence interval`,
        onClick: () => setChartConfig({...chartConfig, showCI: !chartConfig.showCI}),
      },
      {
        title: `${chartConfig.showQuarters ? 'Hide' : 'Show'} quarters`,
        onClick: () => setChartConfig({...chartConfig, showQuarters: !chartConfig.showQuarters}),
      },
      {
        title: showAllPartialPoints ? 'Show first partial point' : 'Show all partial points',
        onClick: () => onChangeShowPartialPoint(!showAllPartialPoints),
        hide: isCustomRange,
      },
    ];
  }, [
    t,
    chartConfig,
    setChartConfig,
    onCreateEditGoal,
    showAllPartialPoints,
    onChangeShowPartialPoint,
    isCustomRange,
    metric,
  ]);
  const timeframeOptions = useMemo(() => {
    if (!reviewedSeries) {
      return;
    }
    return TIMEFRAME_OPTIONS.map(o => {
      const value = getDateFromTimeframeOption(defaultSource.lastValidDate, o);
      return {
        label: `${o.count}${o.unit}`.toUpperCase(),
        isSelected: value === filters.fromDate && !isCustomRange,
        onClick: () => onTimeframeOptionSelected(o),
      };
    });
  }, [
    reviewedSeries,
    filters,
    isCustomRange,
    onTimeframeOptionSelected,
    defaultSource.lastValidDate,
  ]);
  const dateRangeText = useMemo(() => {
    const start = moment.utc(filters.fromDate);
    const end = filters.toDate ? moment.utc(filters.toDate) : undefined;
    let str = `${start.format(TIME_FORMATS.READABLE_DATE)}`;
    if (end) {
      str += ` - ${end.format(TIME_FORMATS.READABLE_DATE)}`;
    }
    return str;
  }, [filters]);
  const minMaxDates = useMemo(() => {
    if (!metric) {
      return {};
    }
    return {
      min: metric.minSampleDate ? moment.utc(metric.minSampleDate).toDate() : undefined,
      max: metric.maxSampleDate ? moment.utc(metric.maxSampleDate).toDate() : undefined,
    };
  }, [metric]);

  useEffect(() => {
    getXLabel();
  }, [getXLabel]);
  useEffect(() => {
    metricId &&
      getMetric({
        metricId,
        filters,
        homepageId: homepageRef.current,
      });
  }, [
    getMetric,
    metricId,
    homepageRef,
    configuration?.anomalyMode,
    configuration?.anomalyThreshold,
    filters,
  ]);

  const noSeries = !reviewedSeries || reviewedSeries.samples.length === 0;
  const hasNoData = isJustCreated || noSeries;

  if (isJustCreated) {
    return (
      <div className={classNames(classes.EmptyState, className)}>
        <CircularProgress size={20} className={classes.Progress} />
        {t(TransKeys.HOMEPAGE.SAMPLING_IN_PROGRESS_EMPTY_STATE)}
      </div>
    );
  }

  if (!metric && isLoading) {
    return <GenericLoading />;
  }

  if (noSeries) {
    return (
      <div className={classNames(classes.EmptyState, className)}>
        {t(TransKeys.HOMEPAGE.METRIC_NO_SAMPLES_EMPTY_STATE)}
      </div>
    );
  }

  return (
    <div className={classNames(classes.HomepageMetricViewer, className)}>
      {(isLoading || isLoadingRCA) && !isLoadingFromProps && <GenericLoading />}
      {metric && !hasNoData && showHeader && (
        <HomepageMetricHeader
          metric={metric}
          onViewMetric={onViewMetric}
          onViewMetricOpportunities={onViewMetricOpportunities}
          configuration={configuration}
        />
      )}
      {showOptions && (
        <div className={classes.Options}>
          {granularityOptions && (
            <Select
              dropdownButtonClassName={classes.GranularityDropdownButton}
              options={granularityOptions}
              value={granularity}
              onChange={onChangeGranularity as any}
              clearable={false}
              searchable={false}
              disabled={isLoading}
              fitContent
            />
          )}
          {!granularityOptions && (
            <div className={classes.GranularityDisplay}>
              {t(TransKeys.GENERAL.LABELS.GRANULARITY[reviewedSeries.granularity.toUpperCase()])}
            </div>
          )}
          {timeframeOptions && (
            <div className={classes.TimeframeSelection}>
              {timeframeOptions.map(o => (
                <div
                  key={o.label}
                  onClick={isLoading ? undefined : o.onClick}
                  className={classNames(
                    classes.Option,
                    o.isSelected && classes.Selected,
                    isLoading && classes.Disabled
                  )}
                >
                  {o.label}
                </div>
              ))}
              <PopoverWrapper
                ref={customSelectorRef}
                buttonRenderer={({onClick, isOpen}) => (
                  <div
                    key={'custom'}
                    onClick={isLoading ? undefined : onClick}
                    className={classNames(
                      classes.Option,
                      isOpen && classes.Selected,
                      isCustomRange && classes.Selected,
                      isLoading && classes.Disabled
                    )}
                  >
                    <span>{isCustomRange ? dateRangeText : 'Custom'}</span>
                  </div>
                )}
              >
                <DateRangeDialog
                  // @ts-ignore
                  value={
                    isCustomRange
                      ? {
                          from: filters.fromDate,
                          to: filters.toDate,
                        }
                      : undefined
                  }
                  showQuickOptions={false}
                  maxDate={minMaxDates.max}
                  minDate={minMaxDates.min}
                  onSubmit={onCustomDatesSelected}
                />
              </PopoverWrapper>
            </div>
          )}
          <ActionsDropdown
            actions={chartActions}
            label={'Options'}
            icon={SettingsIcon}
            iconDropdown
          />
        </div>
      )}
      <div className={classes.Chart}>
        <HomepageMetricChart
          key={metricInstanceId}
          metricName={metric.name}
          series={reviewedSeries as any}
          chartConfig={chartConfig}
          confidenceIntervalConfig={configuration}
          isPercentage={metric.valueType === MetricValueType.PERCENTAGE}
          entity={metric.entity}
          goal={metric.goal?.value}
          xLabel={xLabel}
          hasCountEntities={reviewedSeries.hasDenominator}
          annotations={annotations}
          onAnnotationsClicked={onAnnotationsClicked}
          onSampleClicked={onRCAFollowUp}
          showLegend={showLegend}
        />
      </div>
    </div>
  );
};

HomepageMetricViewer.defaultProps = {
  showHeader: true,
  showOptions: true,
};
