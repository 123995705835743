import * as React from 'react';
import {useCallback} from 'react';
import {LoginOauthProviderButton, OauthProvider} from 'ui-components';
import appConfig from '../../../../config/app.config';
import classes from './oauth-providers.module.scss';
import classNames from 'classnames';

interface OwnProps {
  providers: OauthProvider[];
  action: 'Login' | 'Signup';
  redirectTo?: string;
}

type AllProps = OwnProps;

const OauthProviders: React.FC<AllProps> = (props: AllProps) => {
  const {action, redirectTo, providers} = props;

  const onLoginWithProvider = useCallback(
    (oauthProvider: OauthProvider) => {
      let href = `${appConfig.serverUrl}/auth/login/${oauthProvider}`;
      if (redirectTo) {
        href = `${href}?redirect_to=${redirectTo}`;
      }
      window.location.href = href;
    },
    [redirectTo]
  );

  return (
    <div className={classes.LoginProvidersContainer}>
      <div className={classNames(classes.SeparatorContainer, classes.Using)}>
        <hr className={classNames(classes.LineSeparator, classes.Left)} />
        <span>{`${action} using`}</span>
        <hr className={classNames(classes.LineSeparator, classes.Right)} />
      </div>
      <div className={classes.LoginProviders}>
        {providers.map((oauthProvider: OauthProvider) => (
          <LoginOauthProviderButton
            key={oauthProvider}
            provider={oauthProvider}
            onClick={() => onLoginWithProvider(oauthProvider)}
          />
        ))}
      </div>
      <div className={classNames(classes.SeparatorContainer, classes.Or)}>
        <hr className={classNames(classes.LineSeparator, classes.Left)} />
        <span>Or</span>
        <hr className={classNames(classes.LineSeparator, classes.Right)} />
      </div>
    </div>
  );
};

export {OauthProviders};
