import * as React from 'react';
import {useCallback, useContext, useEffect, useMemo} from 'react';
import {composition, exists} from 'front-core';
import TransKeys from '../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';
import {
  AnalysisFileIcon,
  AppTabs,
  Button,
  CopyIcon,
  EditIcon,
  ExperimentsMenuIcon,
  Filters,
  FlaskGearLightIcon,
  IconButton,
  InteractionContext,
  InteractionType,
  ModelType,
  MoreIcon,
  RerunIcon,
  RSSIcon,
  ShareIcon,
  TextButton,
  TrashIcon,
} from 'ui-components';
import {experimentTypeAnalysisTypeFilter, queryFilter} from '../../../../constants/filters';
import {
  ANALYSIS_TYPE_ID_PATH_PARAM,
  AppRoutes,
  EXPERIMENT_AUTOMATION_ID_PATH_PARAM,
  EXPERIMENT_ID_PATH_PARAM,
} from '../../../../constants/app-routes';
import {Experiment, ExperimentStatus} from '../../../../objects/models/experiment.model';
import {ModelKey} from '../../../../constants/model-key';
import {getExperimentsNetworkRequest} from '../../../../http/experiments.network-requests';
import {experimentToastCreator} from '../../../../store/toasts.actions';
import {useDispatch} from 'react-redux';
import {PanelKey} from '../../../../constants/panels';
import {PanelType} from '../../../../objects/system/panel-type.enum';
import {
  deleteExperimentConfirmed,
  patchExperimentConfirmed,
  updateExperimentConfirmed,
} from '../../../../store/experiments/experiments.actions';
import {replaceList} from '../../../../store/remote-lists/remote-list.actions';
import {
  registerActionListener,
  removeActionListener,
} from '../../../../store/actions-listener/actions-listener.actions';
import {CoreActionsType} from '../../../../store/core/core.actions';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {values} from 'lodash';
import {FlexHorizontal} from '../../../shared/components/layout/flex-layout/general-flex-layouts.component.';
import {Title, TitleWithIcon} from '../../../shared/components/general/title/title.component';
import {StringParam, useQueryParams, withDefault} from 'use-query-params';
import {useRemoteList} from '../../../../core/hooks/use-remote-list.hook';
import ExperimentEmptyState from '../../components/experiment-empty-state/experiment-empty-state.component';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {getAnalysisTypesCatalogNetworkRequest} from '../../../../http/analysis-types.network-requests';
import {AnalysisType} from '../../../../objects/models/analysis-type.model';
import {AnalysisTypeId} from '../../../../constants/analysis-type-id';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {AnalysisParameters} from '../../../analyses/components/analysis-parameters/analysis-parameters.component';
import {withStopPropagation} from '../../../../utils/general.utils';
import {ShareResourceType} from '../../../../objects/models/share.model';
import {AmplitudeEvent} from '../../../../constants/amplitude-event';
import {useAmplitude} from '../../../../core/hooks/amplitude.hook';
import {NavLink} from '../../../shared/components/navigation/left-navigation/left-navigation.component';
import {sharedClasses} from '../../../shared';
import {useRerunExperiment} from '../../../../core/hooks/use-rerun.hook';
import PageLayout from '../../../shared/components/layout/page-layout/index';
import ExperimentStartDateColumn from './components/experiment-start-date-column.component';
import ExperimentResultColumn from './components/experiment-result-column.component';
import {GridTable} from '../../../shared/components/general/grid-table/grid-table.component';
import {TableColumn} from '../../../shared/components/general/grid-table/grid-table.types';
import {ModelActionsDropdown} from '../../../shared/core/model-actions-dropdown/model-actions-dropdown.component';
import {useDemoProduct} from '../../../../core/hooks/use-demo-product.hook';
import {useFeatureIsOn} from '@growthbook/growthbook-react';
import {FeatureFlag} from '../../../../constants/feature-flags';
import classes from './experiments-main.module.scss';

interface OwnProps {
  analysisTypes: AnalysisType[];
}

type AllProps = OwnProps;

const createListKey = (key: string = 'EXPERIMENT') => `EXPERIMENTS_MAIN/${key}`;

export enum ExperimentsTab {
  CURRENT = 'ongoing',
  COMPLETED = 'completed',
}

const ExperimentsMainComponent: React.FC<AllProps> = (props: AllProps) => {
  const {analysisTypes} = props;
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {postMessage} = useContext(InteractionContext);
  const {openPrimaryPanel} = useContext(PanelsContext);
  const productData = useProductData();
  const {isDemoProduct} = useDemoProduct();
  const {onRerun, getExperimentRerunHelperText, shouldDisableRerun} = useRerunExperiment();
  const showAutomations = useFeatureIsOn(FeatureFlag.EXPERIMENT_AUTOMATIONS as any);
  const tabs = useMemo(
    () =>
      values(ExperimentsTab).map(tab => ({
        key: tab,
        label: t(TransKeys.EXPERIMENTS.TABS[tab.toUpperCase()]),
      })),
    [t]
  );
  const [selectedTab, setSelectedTab] = useQueryParams({
    tab: withDefault(StringParam, ExperimentsTab.CURRENT),
  });
  const notify = useAmplitude();
  const config = useMemo(
    () => ({
      listKey: createListKey(),
      actionKey: createListKey(),
      request: getExperimentsNetworkRequest,
      onError: err => [experimentToastCreator('GET_ERROR')],
      modelKey: ModelKey.EXPERIMENT,
    }),
    []
  );
  const filtersDef = useMemo(() => [queryFilter(), experimentTypeAnalysisTypeFilter()], []);
  const defaultFilters = useMemo(
    () => ({
      orderBy: 'startDate',
      order: 'desc',
    }),
    []
  );
  const tabFilters = useMemo(() => {
    const init = {
      ...defaultFilters,
    };
    switch (selectedTab.tab) {
      case ExperimentsTab.CURRENT:
        return {
          ...init,
          status: [
            ExperimentStatus.IN_PROGRESS,
            ExperimentStatus.PENDING,
            ExperimentStatus.STOPPED,
          ],
        };
      case ExperimentsTab.COMPLETED:
        return {
          ...init,
          status: ExperimentStatus.DONE,
        };
    }
  }, [defaultFilters, selectedTab.tab]);

  const {isLoading, listsData, onFiltersChange, onPageChange, onSort, filters} = useRemoteList({
    config,
    filtersDef,
    defaultFilters: tabFilters,
    syncQueryFilters: true,
  });
  const onDelete = useCallback(item => dispatch(deleteExperimentConfirmed(item.id)), [dispatch]);

  const onCreateExperiment = useCallback(
    (analysisTypeId?: number) =>
      openPrimaryPanel(PanelKey.EXPERIMENT_FORM_PANEL, {
        [ANALYSIS_TYPE_ID_PATH_PARAM]: analysisTypeId,
      }),
    [openPrimaryPanel]
  );
  const onAutomationsClicked = useCallback(
    () => openPrimaryPanel(PanelKey.EXPERIMENT_AUTOMATIONS_PANEL),
    [openPrimaryPanel]
  );
  const onSubscribe = useCallback(
    item =>
      openPrimaryPanel(PanelKey.EXPERIMENT_SUBSCRIPTION_FORM_PANEL, {
        [EXPERIMENT_ID_PATH_PARAM]: item.id,
      }),
    [openPrimaryPanel]
  );
  const onShare = useCallback(
    (exp: Experiment) => {
      openPrimaryPanel(
        PanelKey.SHARE_RESOURCE_PANEL,
        {
          modelId: exp.id,
          type: ShareResourceType.EXPERIMENT,
          copyLink: AppRoutes.asUrl(AppRoutes.viewExperiment(exp.id)),
        },
        PanelType.MODAL
      );
      notify(AmplitudeEvent.RESOURCE_SHARE_CLICKED, {
        type: ShareResourceType.EXPERIMENT,
        id: exp.id,
      });
    },
    [openPrimaryPanel, notify]
  );
  const onEditDuplicate = useCallback(
    (exp: Experiment, cloneMode = false) => {
      const onApprove = () =>
        openPrimaryPanel(
          PanelKey.EXPERIMENT_FORM_PANEL,
          {
            [EXPERIMENT_ID_PATH_PARAM]: exp.id,
            [ANALYSIS_TYPE_ID_PATH_PARAM]: exp.analysis.analysisTypeId,
            cloneMode,
          },
          PanelType.MODAL
        );
      if (cloneMode) {
        return onApprove();
      }
      dispatch(updateExperimentConfirmed(onApprove));
    },
    [openPrimaryPanel, dispatch]
  );
  const onMarkComplete = useCallback(
    (exp: Experiment) =>
      dispatch(
        patchExperimentConfirmed({
          id: exp.id,
          status: ExperimentStatus.DONE,
        })
      ),
    [dispatch]
  );
  const onMainKpiClick = useCallback(
    signalId =>
      postMessage({
        type: InteractionType.REFERENCE,
        payload: {
          modelId: signalId,
          modelType: ModelType.SIGNAL,
        },
      }),
    [postMessage]
  );

  const contentWrapper = useCallback(
    (experiment: Experiment, children: React.ReactNode) => (
      <NavLink
        key={experiment.id}
        className={sharedClasses.UnstyledLink}
        to={AppRoutes.viewExperiment(experiment.id)}
      >
        {children}
      </NavLink>
    ),
    []
  );
  const viewExperimentAutomation = useCallback(
    (id: number) =>
      openPrimaryPanel(PanelKey.EXPERIMENT_AUTOMATION_FORM_PANEL, {
        [EXPERIMENT_AUTOMATION_ID_PATH_PARAM]: id,
      }),
    [openPrimaryPanel]
  );
  const columns: TableColumn[] = useMemo(
    () => [
      {
        key: 'name',
        title: t(TransKeys.GENERAL.HEADERS.NAME),
        width: '28rem',
        sticky: 'left',
        stretch: true,
        render: (exp: Experiment) => (
          <div className={classes.ExperimentTitle}>
            {exp.experimentAutomation && (
              <div className={classes.Automation}>
                Automation:{' '}
                <span
                  onClick={withStopPropagation(() =>
                    viewExperimentAutomation(exp.experimentAutomation.id)
                  )}
                  className={classes.AutomationName}
                >
                  {exp.experimentAutomation.name}
                </span>
              </div>
            )}
            <Title text={exp.name} caps={false} />
          </div>
        ),
      },
      {
        key: 'analysisName',
        title: t(TransKeys.GENERAL.LABELS.TYPE),
        width: '16rem',
        render: (exp: Experiment) => {
          if (!exp.analysis) {
            return null;
          }
          return (
            <TitleWithIcon
              text={exp.analysis?.analysisName}
              icon={AnalysisFileIcon}
              size={'medium'}
            />
          );
        },
      },
      {
        key: 'mainKpi',
        title: t(TransKeys.EXPERIMENTS.TABLE.HEADERS.MAIN_KPI),
        width: '18rem',
        render: (exp: Experiment) => {
          if (!exists(exp?.mainKpi)) {
            return null;
          }
          return (
            <TextButton onClick={withStopPropagation(() => onMainKpiClick(exp.mainKpi.id))}>
              {exp.mainKpi.name}
            </TextButton>
          );
        },
      },
      {
        key: 'result',
        title: t(TransKeys.EXPERIMENTS.TABLE.HEADERS.RESULT.TITLE),
        width: '16rem',
        render: (exp: Experiment) => <ExperimentResultColumn experiment={exp} />,
      },
      {
        key: 'startDate',
        sortable: true,
        title: t(TransKeys.EXPERIMENTS.TABLE.HEADERS.START_DATE),
        width: '14rem',
        render: (exp: Experiment) => <ExperimentStartDateColumn experiment={exp} />,
      },
      {
        key: 'actions',
        title: '',
        width: '24rem',
        sticky: 'right',
        sortable: false,
        align: 'right',
        render: (exp: Experiment) => {
          const params: any = {};
          if (exp.lastCompletedAnalysisResult) {
            params.analysisResultId = exp.lastCompletedAnalysisResult.id;
          } else if (exp.lastAnalysisResultId) {
            params.analysisResultId = exp.lastAnalysisResultId;
          } else {
            params.analysisId = exp.analysisId;
          }

          return (
            <FlexHorizontal verticalAlignCenter spacing>
              {exp?.status === ExperimentStatus.IN_PROGRESS && (
                <Button
                  variant="outlined"
                  disabled={isDemoProduct}
                  onClick={withStopPropagation(() => onMarkComplete(exp))}
                >
                  {t(TransKeys.GENERAL.ACTIONS.COMPLETE)}
                </Button>
              )}
              <AnalysisParameters
                {...params}
                label={t(TransKeys.GENERAL.LABELS.PARAMETERS)}
                allowDuplicate={false}
                asIcon
              />
              <IconButton
                tooltipText={t(TransKeys.GENERAL.ACTIONS.SHARE)}
                icon={ShareIcon}
                onClick={withStopPropagation(() => onShare(exp))}
              />
              <ModelActionsDropdown
                actions={[
                  {
                    key: 'edit',
                    title: t(TransKeys.GENERAL.ACTIONS.EDIT),
                    onClick: () => onEditDuplicate(exp),
                    disabled: exp.status === ExperimentStatus.DONE,
                    icon: EditIcon,
                    showEnabled: true,
                  },
                  {
                    key: 'duplicate',
                    title: t(TransKeys.GENERAL.ACTIONS.DUPLICATE),
                    onClick: () => onEditDuplicate(exp, true),
                    icon: CopyIcon,
                    showEnabled: true,
                  },
                  {
                    key: 'delete',
                    title: t(TransKeys.GENERAL.ACTIONS.DELETE),
                    onClick: () => onDelete(exp),
                    icon: TrashIcon,
                  },
                  {
                    key: 'subscribe',
                    title: t(TransKeys.GENERAL.ACTIONS.SUBSCRIBE),
                    onClick: () => onSubscribe(exp),
                    disabled: exp.status === ExperimentStatus.DONE,
                    icon: RSSIcon,
                    showEnabled: true,
                  },
                  {
                    key: 'rerun',
                    title: t(TransKeys.GENERAL.ACTIONS.RERUN),
                    onClick: () => onRerun(exp),
                    disabled: shouldDisableRerun(exp),
                    helperText: getExperimentRerunHelperText(exp),
                    icon: RerunIcon,
                  },
                ]}
                label={t(TransKeys.GENERAL.LABELS.MORE_DOTS)}
                icon={MoreIcon}
                iconDropdown
              />
            </FlexHorizontal>
          );
        },
      },
    ],
    [
      t,
      onDelete,
      onSubscribe,
      onMainKpiClick,
      onEditDuplicate,
      onMarkComplete,
      onRerun,
      onShare,
      getExperimentRerunHelperText,
      shouldDisableRerun,
      isDemoProduct,
      viewExperimentAutomation,
    ]
  );

  useEffect(() => {
    const listener = action => {
      if (action.payload.modelKey === ModelKey.EXPERIMENT) {
        dispatch(replaceList(config.listKey, {...tabFilters, page: 1}, 'replace'));
      }
    };
    dispatch(registerActionListener(CoreActionsType.MODEL_CREATED, listener));
    dispatch(registerActionListener(CoreActionsType.MODEL_UPDATED, listener));
    return () => {
      dispatch(removeActionListener(CoreActionsType.MODEL_CREATED, listener));
      dispatch(removeActionListener(CoreActionsType.MODEL_UPDATED, listener));
    };
  }, [dispatch, config, tabFilters]);

  if (productData.experimentsCount === 0) {
    return (
      <ExperimentEmptyState
        analysisTypes={analysisTypes}
        onSelect={(analysisTypeId: number) => onCreateExperiment(analysisTypeId)}
      />
    );
  }

  return (
    <PageLayout.Layout>
      <PageLayout.Title title={t(TransKeys.EXPERIMENTS.HEADER.TITLE)} icon={ExperimentsMenuIcon} />
      <PageLayout.Actions>
        {showAutomations && (
          <Button
            onClick={() => onAutomationsClicked()}
            size={'large'}
            variant={'outlined'}
            icon={FlaskGearLightIcon}
          >
            {t(TransKeys.EXPERIMENTS.ACTIONS.AUTOMATIONS)}
          </Button>
        )}
        <Button onClick={() => onCreateExperiment()} size={'large'}>
          {t(TransKeys.GENERAL.ACTIONS.CREATE_EXPERIMENT)}
        </Button>
      </PageLayout.Actions>
      <PageLayout.Tabs>
        <AppTabs
          tabs={tabs}
          selected={selectedTab.tab}
          onChange={tab => setSelectedTab({tab})}
          border={false}
        />
      </PageLayout.Tabs>
      <PageLayout.Filters>
        <Filters
          selected={filters}
          defaultFilters={defaultFilters}
          hideDefaultFilters
          onChange={onFiltersChange}
          onClearAll={() => onFiltersChange(defaultFilters)}
          freeSearchFilterKey={'q'}
          filters={filtersDef}
        />
      </PageLayout.Filters>
      <PageLayout.Body>
        {listsData && (
          <GridTable
            data={listsData.list}
            dataKey={'id'}
            columns={columns}
            pagination={true}
            paginationMode={'pages'}
            onPageChange={(_, page) => onPageChange(page + 1)}
            total={listsData.meta?.total}
            page={listsData.meta?.page - 1}
            perPage={listsData.meta?.numPerPage}
            onSort={(_, key) => onSort(key)}
            order={filters?.order}
            orderBy={filters?.orderBy}
            isLoading={isLoading}
            emptyStateProps={{
              title: t(TransKeys.EXPERIMENTS.EMPTY_STATE.TITLE),
              subTitle: t(TransKeys.EXPERIMENTS.EMPTY_STATE.SUB_TITLE),
            }}
            contentWrapper={contentWrapper}
          />
        )}
      </PageLayout.Body>
    </PageLayout.Layout>
  );
};

const ExperimentsMain = composition<OwnProps>(
  ExperimentsMainComponent,
  withLoadBefore<OwnProps>({
    analysisTypes: {
      selectedKey: createListKey('ANALYSIS_TYPES'),
      actionKey: createListKey('ANALYSIS_TYPES'),
      request: getAnalysisTypesCatalogNetworkRequest,
      shouldCall: props => true,
      mapPayloadFromProps: props => ({
        id: [AnalysisTypeId.RELEASE_IMPACT, AnalysisTypeId.A_B_TEST],
      }),
    },
  })
);

export default ExperimentsMain;
