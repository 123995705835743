import {NRC} from './index';
import {RequestType, toCamelCase, toSnakeCase} from 'front-core';
import {RCAInsightsDTO} from '../objects/dto/insights.dto';

export const getRCAInsightsNetworkRequest: NRC<void, RCAInsightsDTO> = (
  payload: RCAInsightsDTO
) => ({
  method: RequestType.GET,
  relativeUrl: `/app/insights/rca`,
  query: payload,
  queryTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});
