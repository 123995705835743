import * as React from 'react';
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import classNames from 'classnames';
import classes from './homepage-summary.module.scss';
import {
  HomepageAnnotation,
  AnomalyMode,
  HomepageMetric,
  HomepageSample,
  HomepageSeries,
} from '../../../../objects/models/homepage.model';
import {
  AnomalyModeButton,
  Button,
  CircleInfoLightIcon,
  KPIIcon,
  PlusLightIcon,
  RSSIcon,
  useRemoteSource,
  useRemoteSourceStated,
  UserIcon,
  useLocalStorage,
  TeamSelector,
} from 'ui-components';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {ModelSeriesGranularity} from '../../../../objects/models/model-sample-series.model';
import {useDispatch} from 'react-redux';
import {
  addMetricToHomepage,
  removeMetricFromHomepage,
  reorderHomepageMetrics,
} from '../../../../store/homepage/homepage.actions';
import {
  AppRoutes,
  CATEGORY_ID_PATH_PARAM,
  GOAL_ID_PATH_PARAM,
  HOMEPAGE_ID_PATH_PARAM,
  METRIC_ID_PATH_PARAM,
} from '../../../../constants/app-routes';
import {PanelKey} from '../../../../constants/panels';
import {CoreActionsType, notifyEvent} from '../../../../store/core/core.actions';
import {AmplitudeEvent} from '../../../../constants/amplitude-event';
import moment from 'moment/moment';
import {TIME_FORMATS} from '../../../../constants/time-formats';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {
  getHomepageDataNetworkRequest,
  sendHomepageSubscriptionTestNetworkRequest,
} from '../../../../http/homepage.network-requests';
import {ModelKey} from '../../../../constants/model-key';
import {
  registerActionListener,
  removeActionListener,
} from '../../../../store/actions-listener/actions-listener.actions';
import {Metric} from '../../../../objects/models/metric.model';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {extendHomepageMetric} from './homepage-summary.utils';
import {HomepageMetricList} from './components/homepage-metric-list/homepage-metric-list.component';
import {HomepageMetricViewer} from './components/homepage-metric-viewer/homepage-metric-viewer.component';
import {exists} from 'front-core';
import {debounce} from 'lodash';
import {NumberParam, useQueryParam} from 'use-query-params';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {useFeatureIsOn} from '@growthbook/growthbook-react';
import {FeatureFlag} from '../../../../constants/feature-flags';
import {LocalStorageKey} from '../../../../constants/local-storage-key';
import {Team} from '../../../../objects/models/team.model';
import {TeamFilterProvider} from '../../../../core/contexts/team-filter.context';
import {useHistory} from 'react-router';
import {
  METRIC_PAGE_TAB_QUERY_PARAM,
  MetricPageTab,
} from '../../../metrics/pages/metric-page/metric-page.component';

interface OwnProps {
  className?: string;
}

type AllProps = OwnProps;

const ACTIONS = [
  CoreActionsType.MODEL_UPDATED,
  CoreActionsType.MODEL_CREATED,
  CoreActionsType.MODEL_DELETED,
];
const MODELS = [ModelKey.HOMEPAGE, ModelKey.USER_SETTINGS, ModelKey.ANNOTATION, ModelKey.GOAL];

export interface MetricGoalAtTime {
  datetime: string;
  value: number;
  matchingSampleValue?: number;
}

export interface TransformedHomepageSeries extends HomepageSeries {
  goalPerDay?: MetricGoalAtTime[];
  lastSample?: HomepageSample;
  lastSampleGoalValue?: number;
  nonPartialSamples: HomepageSample[];
}

export type TransformedSeriesMap = {
  [k in ModelSeriesGranularity]?: TransformedHomepageSeries;
};

export interface ExtendedHomepageMetric extends HomepageMetric {
  transformedSeries: TransformedSeriesMap;
}

const HOMEPAGE_SUBSCRIBE_BUTTON_FAKE_CLASS = 'homepage-subscribe-button';
const HOMEPAGE_CHANGE_MODE_BUTTON_FAKE_CLASS = 'homepage-change-mode-button';

export const HomepageSummary: React.FC<AllProps> = (props: AllProps) => {
  const {className} = props;
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const {openSecondaryPanel} = useContext(PanelsContext);
  const {
    productId,
    defaultHomepageId,
    userHomepageId,
    teams,
    actualTeams,
    defaultSource,
    userSettings,
  } = useProductData();
  const [selectedHomepageId, setSelectedHomepageId] = useLocalStorage<number>(
    `${productId}_${LocalStorageKey.SELECTED_HOMEPAGE_ID}`,
    defaultHomepageId
  );
  const showSendKpiInsightsButton = useFeatureIsOn(FeatureFlag.SEND_KPI_INSIGHTS as string);
  const {
    source: homepageData,
    exec: getHomepageDataExec,
    isLoading,
  } = useRemoteSourceStated({
    networkRequest: getHomepageDataNetworkRequest,
    initialValue: {},
  });
  const {exec: sendHomepageSubscriptionTest, isLoading: sendingInsights} = useRemoteSource({
    networkRequest: sendHomepageSubscriptionTestNetworkRequest,
  });
  const {homepageMetricIds, lastUpdate} = homepageData;
  const addMetricButtonRef = useRef<any>(null);
  const [selectedMetricId, setSelectedMetricId] = useQueryParam('kpi', NumberParam);
  const [searchValue, setSearchValue] = useState('');
  const showSubscription = useFeatureIsOn(FeatureFlag.HOMEPAGE_SUBSCRIPTION as string);
  const teamId = useMemo(
    () => actualTeams.find(t => t.homepageId === selectedHomepageId)?.id || null,
    [actualTeams, selectedHomepageId]
  );
  const homepageOptions = useMemo(
    () => [
      {
        id: userHomepageId,
        icon: UserIcon,
        name: t(TransKeys.HOMEPAGE.MY_HOMEPAGE_LABEL),
        teamId: null,
      },
      ...teams.map((team: Team) => ({...team, id: team.homepageId, teamId: team.id})),
    ],
    [userHomepageId, teams, t]
  );
  const extendedMetrics = useMemo(() => {
    if (!homepageData?.metrics) {
      return [];
    }
    return homepageData.metrics.map(m => extendHomepageMetric(m));
  }, [homepageData]);
  const reviewedMetric = useMemo(
    () => extendedMetrics.find(m => m.id === selectedMetricId) || extendedMetrics[0],
    [extendedMetrics, selectedMetricId]
  );
  const onAddMetric = useCallback(
    (metricId: number) => dispatch(addMetricToHomepage({metricId, homepageId: selectedHomepageId})),
    [dispatch, selectedHomepageId]
  );
  const onDeleteMetric = useCallback(
    (metricId: number) =>
      dispatch(removeMetricFromHomepage({metricId, homepageId: selectedHomepageId})),
    [dispatch, selectedHomepageId]
  );
  const onAddAnnotation = useCallback(
    () => openSecondaryPanel(PanelKey.ANNOTATION_FORM_PANEL),
    [openSecondaryPanel]
  );
  const onCreateEditGoal = useCallback(
    (metricId: number, goalId?: number) => {
      openSecondaryPanel(PanelKey.GOAL_FORM_PANEL, {
        [METRIC_ID_PATH_PARAM]: metricId,
        [GOAL_ID_PATH_PARAM]: goalId,
      });
      dispatch(
        notifyEvent(AmplitudeEvent.GOAL_MODAL_TRIGGERED_FROM_HOMEPAGE, {metric_id: metricId})
      );
    },
    [openSecondaryPanel, dispatch]
  );
  const onCreateKPI = useCallback(
    categoryId => {
      openSecondaryPanel(PanelKey.METRIC_FORM_PANEL, {
        [CATEGORY_ID_PATH_PARAM]: categoryId,
        onSuccess: (metric: Metric) => onAddMetric(metric.id),
      });
    },
    [onAddMetric, openSecondaryPanel]
  );
  const onReorderMetrics = useCallback(
    metricIds =>
      dispatch(
        reorderHomepageMetrics({
          homepageId: selectedHomepageId,
          metricIds,
        })
      ),
    [dispatch, selectedHomepageId]
  );
  const onAnnotationsClicked = useCallback(
    (annotations: HomepageAnnotation[]) => {
      openSecondaryPanel(PanelKey.HOMEPAGE_ANNOTATIONS_PANEL, {
        date: moment(annotations[0].timestamp).format(TIME_FORMATS.PARAMETER_DATE_FORMAT),
      });
    },
    [openSecondaryPanel]
  );
  const onEditHomepageConfiguration = useCallback(
    () => openSecondaryPanel(PanelKey.HOMEPAGE_CONFIGURATION_PANEL),
    [openSecondaryPanel]
  );
  const onEditHomepageSubscription = useCallback(
    () =>
      openSecondaryPanel(PanelKey.HOMEPAGE_SUBSCRIPTION_PANEL, {
        [HOMEPAGE_ID_PATH_PARAM]: selectedHomepageId,
      }),
    [openSecondaryPanel, selectedHomepageId]
  );
  const onSearchValueChange = useCallback(
    (term: string) => {
      setSelectedMetricId(null);
      setSearchValue(term);
    },
    [setSearchValue, setSelectedMetricId]
  );
  const onViewMetric = useCallback(
    (metricId: number, tab?: MetricPageTab) =>
      history.push(
        AppRoutes.viewMetric(metricId, {
          [METRIC_PAGE_TAB_QUERY_PARAM]: tab,
        })
      ),
    [history]
  );
  const anomalyModeText = userSettings?.anomalyMode
    ? t(
        TransKeys.HOMEPAGE_CONFIGURATION_PANEL.MODES[userSettings?.anomalyMode?.toUpperCase()][
          'LABEL'
        ]
      )
    : undefined;
  const lastUpdateText = useMemo(() => {
    if (!exists(lastUpdate)) {
      return;
    }
    const lastUpdateAgo = moment.utc(lastUpdate, TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT).fromNow();
    let text = `Updated ${lastUpdateAgo}`;
    if (defaultSource.daysDelayed) {
      text += ` (delayed ${defaultSource.daysDelayed} day(s))`;
    }
    return text;
  }, [lastUpdate, defaultSource]);
  const getHomepageDataDebounced = useMemo(
    () =>
      debounce((homepageId: number, filters) => getHomepageDataExec(homepageId, filters), 500, {
        leading: true,
        trailing: true,
      }),
    [getHomepageDataExec]
  );
  const getHomepageData = useCallback(
    () =>
      getHomepageDataDebounced(selectedHomepageId, {
        q: searchValue,
      }),
    [getHomepageDataDebounced, selectedHomepageId, searchValue]
  );
  useEffect(() => {
    getHomepageData();
  }, [getHomepageData]);
  useEffect(() => {
    if (!homepageData?.metrics || exists(selectedMetricId)) {
      return;
    }
    setSelectedMetricId(homepageData.metrics[0]?.id);
  }, [homepageData?.metrics, selectedMetricId, setSelectedMetricId]);
  useEffect(() => {
    const listener = async action => {
      const {modelKey} = action.payload;
      if (MODELS.indexOf(modelKey) === -1) {
        return;
      }
      switch (modelKey) {
        case ModelKey.HOMEPAGE:
          await getHomepageData();
          if (action.type === CoreActionsType.MODEL_CREATED) {
            setSelectedMetricId(action.payload.data?.metricId);
          }
          if (action.type === CoreActionsType.MODEL_DELETED) {
            setSelectedMetricId(null);
          }
          break;
        case ModelKey.USER_SETTINGS:
        case ModelKey.ANNOTATION:
        case ModelKey.GOAL:
          await getHomepageData();
          break;
      }
    };
    dispatch(registerActionListener(ACTIONS, listener));
    return () => {
      dispatch(removeActionListener(ACTIONS, listener));
    };
  }, [dispatch, getHomepageData, setSelectedMetricId]);

  return (
    <TeamFilterProvider teamId={teamId}>
      <div className={classNames(classes.HomepageSummary, className)}>
        <div className={classes.Header}>
          <div className={classes.TitleWrapper}>
            <KPIIcon className={classes.Icon} />
            <div className={classes.Title}>
              <span>{t(TransKeys.HOMEPAGE.SECTIONS.TOP_METRICS.TITLE)}</span>
            </div>
            {homepageOptions.length > 1 && (
              <TeamSelector
                value={selectedHomepageId}
                onChange={setSelectedHomepageId as any}
                teams={homepageOptions}
                clearable={false}
              />
            )}
          </div>
          <div className={classes.Actions}>
            {showSendKpiInsightsButton && (
              <Button
                onClick={() => sendHomepageSubscriptionTest(selectedHomepageId)}
                variant={'outlined'}
                className={classes.AddAnnotation}
                disabled={sendingInsights || !selectedMetricId}
              >
                Test Subscription
              </Button>
            )}
            <Button
              onClick={onAddAnnotation}
              variant={'outlined'}
              icon={PlusLightIcon}
              helperText={t(TransKeys.HOMEPAGE.ACTIONS.ADD_ANNOTATION_TOOLTIP)}
              className={classes.AddAnnotation}
              disabled={isLoading}
            >
              {t(TransKeys.HOMEPAGE.ACTIONS.ADD_ANNOTATION)}
            </Button>
            <AnomalyModeButton
              className={HOMEPAGE_CHANGE_MODE_BUTTON_FAKE_CLASS}
              onClick={onEditHomepageConfiguration}
              loopsIcon={userSettings?.anomalyMode === AnomalyMode.LOOPS_ALGO}
              text={anomalyModeText}
              disabled={isLoading}
            />
            {showSubscription && (
              <Button
                variant={'outlined'}
                icon={RSSIcon}
                className={classNames(classes.Subscribe, HOMEPAGE_SUBSCRIBE_BUTTON_FAKE_CLASS)}
                onClick={onEditHomepageSubscription}
                disabled={isLoading || extendedMetrics.length === 0}
              />
            )}
          </div>
        </div>
        <div className={classes.Content}>
          {!homepageData && isLoading && <GenericLoading />}
          <div className={classes.List}>
            <HomepageMetricList
              searchValue={searchValue}
              onSearchValueChange={onSearchValueChange}
              metrics={extendedMetrics}
              selectedMetricId={reviewedMetric?.id || selectedMetricId}
              onChangeSelectedMetricId={setSelectedMetricId}
              homepageMetricIds={homepageMetricIds}
              onAddMetric={onAddMetric}
              onDeleteMetric={onDeleteMetric}
              onCreateKPI={onCreateKPI}
              onReorderMetrics={onReorderMetrics}
              addMetricButtonRef={addMetricButtonRef}
            />
          </div>
          <div className={classes.ItemViewer}>
            {isLoading && <GenericLoading />}
            {reviewedMetric && (
              <>
                <HomepageMetricViewer
                  className={classes.HomepageMetricViewer}
                  metricId={reviewedMetric?.id}
                  homepageId={selectedHomepageId}
                  onAnnotationsClicked={onAnnotationsClicked}
                  onCreateEditGoal={(goalId: number) => onCreateEditGoal(reviewedMetric.id, goalId)}
                  onViewMetric={() => onViewMetric(reviewedMetric.id)}
                  onViewMetricOpportunities={() =>
                    onViewMetric(reviewedMetric.id, MetricPageTab.PERFORMANCE)
                  }
                  configuration={userSettings}
                  isLoading={isLoading}
                />
                <div className={classes.LastUpdate}>
                  <CircleInfoLightIcon className={classes.InfoIcon} />
                  <span>{lastUpdateText}</span>
                </div>
              </>
            )}
            {!exists(searchValue) && extendedMetrics.length === 0 && !isLoading && (
              <div className={classes.EmptyState}>
                <span className={classes.CTA} onClick={() => addMetricButtonRef.current.click()}>
                  {t(TransKeys.HOMEPAGE.ACTIONS.EMPTY_STATE_TEXT)}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </TeamFilterProvider>
  );
};
