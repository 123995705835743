import {TableEntity} from './table.model';
import {Signal} from './signal.model';
import {SourceType} from './source.model';
import {DefaultTimeFrameStart} from '../../constants/shared-enums';

export type ProductEntities = {
  [key in TableEntity]?: {
    name: string;
    order: number;
    icon?: string;
    mainPropertiesTable?: {id: number; name: string};
  };
};
export type OrderedProductEntities = Array<{key: TableEntity; name: string}>;
export enum AccountStatus {
  ACTIVE = 'active',
  DIDNT_CONVERT = 'didnt_convert',
  HOLD = 'hold',
  INTERNAL = 'internal',
  INVOLENTARY_CHURN = 'involuntary_churn',
  POC = 'poc',
}

export enum IntegrationStatus {
  ACTIVE = 'active',
  SETUP = 'setup',
}

export interface ProductData {
  id: number;
  name: string;
  accountStatus: AccountStatus;
  productEntities: ProductEntities;
  signalTags: string[];
  topSignals: Partial<Signal>;
  userCreatedAnalysis: boolean;
  experimentsCount: number;
  defaultSource: {
    id: number;
    type: SourceType;
    rawSqlEnabled: boolean;
  };
  integrationStatus?: IntegrationStatus;
  defaultTimeframeStart?: DefaultTimeFrameStart;
}
