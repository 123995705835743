import * as React from 'react';
import {useMemo} from 'react';
import classNames from 'classnames';
import classes from './metric-page-header.module.scss';
import {MetricPage} from '../../../../../../objects/models/metric-page.model';
import {MetricValueType} from '../../../../../../objects/models/metric.model';
import {number2k} from 'front-core';
import {keyBy, values} from 'lodash';
import {AvatarGroup, EditIcon, TeamIconChip} from 'ui-components';
import {ModelSeriesGranularity} from '../../../../../../objects/models/model-sample-series.model';
import TransKeys from '../../../../../../constants/translation-keys';
import {Parameter} from '../../../../../shared/components/general/parameter/parameter.component';
import {UserSmartSelectorAvatar} from '../../../../../shared/core/smart-selector/user-smart-selector-avatar/user-smart-selector-avatar.component';
import {useTranslation} from 'react-i18next';

interface OwnProps {
  metric: MetricPage;
  onOwnerChange: (userId: number) => void;
  onEdit: () => void;
  className?: string;
}

type AllProps = OwnProps;

export const MetricPageHeader: React.FC<AllProps> = (props: AllProps) => {
  const {metric, onEdit, onOwnerChange, className} = props;
  const {t} = useTranslation();

  const value = useMemo(() => {
    let v;
    if (ModelSeriesGranularity.WEEK in metric.valueAvg) {
      v = metric.valueAvg[ModelSeriesGranularity.WEEK];
    } else {
      v = values(metric.valueAvg)[0];
    }
    return metric.valueType === MetricValueType.PERCENTAGE ? `${number2k(v * 100)}%` : number2k(v);
  }, [metric]);
  const users = useMemo(() => {
    const users = metric.history.map(h => h.user).filter(u => u);
    const usersMap = keyBy(users, 'id');
    const set = new Set(users.map(s => s.id));
    return Array.from(set).map(i => ({name: usersMap[i].fullName}));
  }, [metric]);

  return (
    <div className={classNames(classes.MetricPageHeader, className)}>
      <div className={classes.Head}>
        <div className={classes.Left}>
          <div className={classes.Title}>{metric.name}</div>
          <div className={classes.Description}>
            {metric.shortDescription && <span>{metric.shortDescription}</span>}
            {!metric.shortDescription && (
              <span className={classes.AddDescription} onClick={() => onEdit()}>
                Add description <EditIcon />
              </span>
            )}
          </div>
        </div>
        <div className={classes.Right}>
          <div className={classes.Value}>{value}</div>
          <div className={classes.Updated}>Last 3 month average</div>
        </div>
      </div>
      <div className={classes.Descriptors}>
        <div className={classes.Item}>
          <Parameter
            label={'Owner'}
            value={
              <UserSmartSelectorAvatar
                userName={metric.ownerUserName}
                onChange={userId => onOwnerChange(userId)}
                clearText={t(TransKeys.METRICS.ACTIONS.REMOVE_OWNER)}
                tooltipText={t(TransKeys.METRICS.ACTIONS.SET_OWNER)}
                size={'small'}
              />
            }
          />
        </div>
        {users.length > 0 && (
          <div className={classes.Item}>
            <Parameter
              label={'Users'}
              value={
                <AvatarGroup
                  avatarsData={users}
                  variant={'secondary'}
                  size={'small'}
                  maxCount={3}
                />
              }
            />
          </div>
        )}
        {metric.teams.length > 0 && (
          <div className={classes.Item}>
            <Parameter
              label={'Teams'}
              value={
                <div className={classes.Teams}>
                  {metric.teams.map(t => (
                    <TeamIconChip {...t} key={t.id} showTooltip size={'medium'} />
                  ))}
                </div>
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};
