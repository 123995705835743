import * as React from 'react';
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {getSlackChannelsNetworkRequest} from '../../../../http/slack-channels.network-requests';
import classes from './homepage-subscription-panel.module.scss';
import {
  Button,
  Checkbox,
  FancyHeader,
  ModalLayout,
  RSSIcon,
  useRemoteSourceStated,
  ToggleSwitch,
  TeamIconChip,
  UserIcon,
} from 'ui-components';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import yup from '../../../../config/yup.config';
import {
  AnomalyMode,
  HomepageSettings,
  HomepageSubscribedSegmentsType,
  HomepageSubscription,
  HomepageSubscriptionPushType,
  HomepageSubscriptionVIA,
} from '../../../../objects/models/homepage.model';
import {yupResolver} from '@hookform/resolvers/yup';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {SlackChannel} from '../../../analyses/panels/share-resource-form-panel/components/share-direct-slack-form-fields/share-direct-slack-form-fields.component';
import {useDispatch, useSelector} from 'react-redux';
import {updateHomepageSubscription} from '../../../../store/homepage/homepage.actions';
import {MultiSelectInput} from '../../../shared/form/components/shared-form-input.component';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {getReducedLoadingStateSelector} from '../../../../store/store.selectors';
import {ActionKey} from '../../../../constants/action-key';
import {SlackBtn} from '../../../analyses/panels/share-resource-form-panel/components/slack-btn/add-slack-btn.component';
import {
  getHomepageSettingsNetworkRequest,
  getHomepageSubscriptionNetworkRequest,
} from '../../../../http/homepage.network-requests';
import {composition} from 'front-core';
import {withModalInactiveSourceHandler} from '../../../../core/hoc/with-modal-inactive-source-handler.hoc';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {HOMEPAGE_ID_PATH_PARAM, TEAM_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {keys, values} from 'lodash';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import {COMPANIES_LOGOS} from '../../../../constants/ui';
import {multiLoaderNetworkRequest} from '../../../../http/multi-loader.network-requests';
import {MetricSettingsList} from './components/metric-settings-list/metric-settings-list.component';
import {SignificancyDefinitionSelector} from './components/significancy-definition-selector/significancy-definition-selector.component';
import classNames from 'classnames';
import {SubscribedSegmentsSelector} from './components/subscribed-segments-selector/subscribed-segments-selector.component';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {PanelKey} from '../../../../constants/panels';
import {Tooltip} from '@material-ui/core';
import {withDisableDemoProduct} from '../../../../core/hoc/with-disable-demo-product.hoc';

interface OwnProps {
  onClose: () => void;
  panelId?: string;
  [HOMEPAGE_ID_PATH_PARAM]: number;
  homepageSettings: HomepageSettings;
  data: HomepageSubscription;
  channels?: SlackChannel[];
  disabled?: boolean;
}

type AllProps = OwnProps;

const HOMEPAGE_PRODUCT_SLACK_CHANNELS_SELECTED_KEY = 'HOMEPAGE/PRODUCT_SLACK_CHANNELS';
const HOMEPAGE_SUBSCRIPTION_SELECTED_KEY = 'HOMEPAGE/HOMEPAGE_SUBSCRIPTION';
const HOMEPAGE_SETTINGS_SELECTED_KEY = 'HOMEPAGE/HOMEPAGE_SETTINGS';

const parseSlackChannelOption = (m: SlackChannel) => ({
  label: m.type === 'channel' ? `# ${m.name}` : m.name,
  value: m.id,
});

const homepageSubscriptionPanelDTOValidator = yup.object().shape({
  isActive: yup.boolean().required(),
  via: yup
    .array()
    .of(yup.string().oneOf(values(HomepageSubscriptionVIA)))
    .when('isActive', {
      is: true,
      then: yup.array().min(1),
      otherwise: yup.array(),
    }),
  slackChannels: yup
    .array()
    .of(yup.string())
    .test({
      name: 'slack_channels_required',
      test: function (value, context) {
        const via = context.parent.via;
        if (!via.includes(HomepageSubscriptionVIA.SLACK)) {
          return true;
        }
        if (value.length === 0) {
          return this.createError({
            message: 'Required when Slack is selected',
          });
        }
        return true;
      },
    }),
  anomalyMode: yup.string().required().oneOf(values(AnomalyMode)),
  anomalyThreshold: yup.number().nullable(),
  subscribedSegmentsType: yup.string().required().oneOf(values(HomepageSubscribedSegmentsType)),
  metricSettings: yup.lazy(obj => {
    const validationSchema = {};
    for (const metricId of keys(obj)) {
      validationSchema[metricId] = yup
        .string()
        .required()
        .oneOf(values(HomepageSubscriptionPushType));
    }
    return yup.object().shape(validationSchema).required();
  }),
});

const HomepageSubscriptionPanelComponent: React.FC<AllProps> = (props: AllProps) => {
  const {
    [HOMEPAGE_ID_PATH_PARAM]: homepageId,
    data,
    channels: channelsFromProps,
    homepageSettings,
    onClose,
    disabled,
  } = props;
  const {teams} = useProductData();
  const {installedSlackToWorkspace} = useProductData();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const contentRef = useRef<HTMLDivElement>(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const {
    source: metrics,
    exec: getMetrics,
    isLoading: isLoadingMetrics,
  } = useRemoteSourceStated({
    networkRequest: multiLoaderNetworkRequest,
    transformer: response => response.metrics,
  });
  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: data,
    resolver: yupResolver(homepageSubscriptionPanelDTOValidator),
  });
  const {
    handleSubmit,
    formState: {errors},
    control,
    watch,
    setValue,
  } = formMethods;
  const {openSecondaryPanel} = useContext(PanelsContext);
  const selectedTeam = useMemo(
    () => teams.find(t => t.homepageId === homepageId),
    [homepageId, teams]
  );
  const slackChannels = watch('slackChannels');
  const via = watch('via');
  const isActive = watch('isActive');
  const anomalyMode = watch('anomalyMode');
  const anomalyThreshold = watch('anomalyThreshold');
  const metricSettings = watch('metricSettings');
  const subscribedSegmentsType = watch('subscribedSegmentsType');

  const isLoading = useSelector(state =>
    getReducedLoadingStateSelector(ActionKey.UPDATE_HOMEPAGE_SUBSCRIPTION)(state)
  );
  const metricsDataForList = useMemo(
    () =>
      values(metrics).sort(
        (a, b) => homepageSettings.metrics.indexOf(a.id) - homepageSettings.metrics.indexOf(b.id)
      ),
    [metrics, homepageSettings.metrics]
  );
  const onSubmit = useCallback(
    data => dispatch(updateHomepageSubscription({...data, homepageId}, onClose)),
    [dispatch, onClose, homepageId]
  );
  const onChangeAllMetrics = useCallback(
    (pushType: HomepageSubscriptionPushType) => {
      const newMetrics = {};
      for (const metricId of keys(metricSettings)) {
        newMetrics[metricId] = pushType;
      }
      setValue(`metricSettings`, newMetrics);
    },
    [setValue, metricSettings]
  );
  const onViaChange = useCallback(
    (viaChannel: HomepageSubscriptionVIA) => {
      let newVia;
      if (via.includes(viaChannel)) {
        newVia = via.filter(v => v !== viaChannel);
      } else {
        newVia = [...via, viaChannel];
      }
      setValue('via', newVia, {shouldValidate: true});
      if (viaChannel === HomepageSubscriptionVIA.SLACK) {
        setValue('slackChannels', [], {shouldValidate: true});
      }
    },
    [setValue, via]
  );

  const channelsOptionsProp = useMemo(() => {
    if (!channelsFromProps || channelsFromProps.length === 0) {
      return null;
    }
    return {
      options: channelsFromProps.map(parseSlackChannelOption),
    };
  }, [channelsFromProps]);
  const onTeamSegmentsClick = useCallback(() => {
    openSecondaryPanel(PanelKey.FAVORITES_SEGMENTS_PANEL, {
      [TEAM_ID_PATH_PARAM]: selectedTeam.id,
    });
  }, [openSecondaryPanel, selectedTeam]);
  const homepageName = useMemo(() => {
    let name = 'My Homepage';
    if (selectedTeam) {
      name = selectedTeam.name;
    }
    return (
      <div className={classes.HomepageName}>
        {selectedTeam && <TeamIconChip {...selectedTeam} />}
        {!selectedTeam && <UserIcon />}
        <span>{name}</span>
      </div>
    );
  }, [selectedTeam]);

  const renderSlackChannelsInput = () => {
    if (!installedSlackToWorkspace || !channelsOptionsProp) {
      return (
        <div className={classes.ConnectSlack}>
          <SlackBtn className={classes.AddToSlackBtn} size={'small'} />
        </div>
      );
    }

    return (
      <MultiSelectInput
        options={channelsOptionsProp}
        label={''}
        name={'slackChannels'}
        placeholder={
          slackChannels.length === 0
            ? t(TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.NOTIFY.SLACK_PLACEHOLDER)
            : ''
        }
        disabled={!isActive || !via.includes(HomepageSubscriptionVIA.SLACK)}
      />
    );
  };

  useEffect(() => {
    getMetrics({
      metrics: homepageSettings.metrics,
    });
  }, [getMetrics, homepageSettings]);
  const containerRefCurrent = contentRef.current;
  useLayoutEffect(() => {
    if (containerRefCurrent) {
      const onScroll = () => {
        setIsScrolled(containerRefCurrent.scrollTop > 0);
      };
      containerRefCurrent.addEventListener('scroll', onScroll);
      return () => {
        containerRefCurrent.removeEventListener('scroll', onScroll);
      };
    }
  }, [containerRefCurrent]);

  return (
    <div className={classes.HomepageSubscriptionPanelContainer}>
      <ModalLayout
        footer={
          <div className={classes.Footer}>
            <Button onClick={onClose} variant={'outlined'}>
              {t(TransKeys.GENERAL.ACTIONS.CANCEL)}
            </Button>
            <Button disabled={disabled} onClick={handleSubmit(onSubmit)}>
              {t(TransKeys.GENERAL.ACTIONS.SAVE)}
            </Button>
          </div>
        }
      >
        {isLoading && <GenericLoading />}
        <FancyHeader icon={RSSIcon} title={t(TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.HEADER_TITLE)} />
        <FormProvider {...formMethods}>
          <div className={classes.Content} ref={contentRef}>
            <div className={classes.Top}>
              <span className={classes.Label}>Homepage</span>
              {homepageName}
            </div>
            <div className={classNames(classes.Block, classes.HeadBlock)}>
              <div className={classes.VIA}>
                <div className={classes.Head}>
                  <Controller
                    render={({field}) => (
                      <Tooltip
                        title={t(TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.ACTIVATE_TOOLTIP)}
                        placement={'left'}
                        open={!isActive && !isScrolled}
                        arrow
                      >
                        <div>
                          <ToggleSwitch {...field} value={field.value} />
                        </div>
                      </Tooltip>
                    )}
                    name={'isActive'}
                    control={control}
                  />
                  <span className={classes.Title}>
                    <span>{t(TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.MAIN_TITLE)}</span>
                  </span>
                </div>
                <div className={classes.Option}>
                  <MailOutlineIcon />
                </div>
                <div className={classes.Option}>
                  <img
                    alt={'Slack icon'}
                    src={COMPANIES_LOGOS.slack}
                    className={classes.SlackIcon}
                  />
                </div>
                <div className={classes.Description}>
                  {t(TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.MAIN_DESCRIPTION)}
                </div>
                <div className={classes.Option}>
                  <Checkbox
                    onChange={() => onViaChange(HomepageSubscriptionVIA.MAIL)}
                    checked={via.includes(HomepageSubscriptionVIA.MAIL)}
                    disabled={!isActive}
                    error={Boolean(errors.via)}
                    multi
                  />
                </div>
                <div className={classes.Option}>
                  <Checkbox
                    onChange={() => onViaChange(HomepageSubscriptionVIA.SLACK)}
                    checked={via.includes(HomepageSubscriptionVIA.SLACK)}
                    disabled={!isActive || !installedSlackToWorkspace}
                    error={Boolean(errors.via)}
                    multi
                  />
                </div>
              </div>
              <div className={classes.SlackChannels}>{renderSlackChannelsInput()}</div>
            </div>
            <div className={classes.Block}>
              <div className={classes.SubTitle}>
                {t(TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.KPI_SELECTION_SECTION.TITLE)}
              </div>
              <div className={classes.Description}>
                {t(TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.KPI_SELECTION_SECTION.DESCRIPTION)}
              </div>
              <div className={classes.MetricSettings}>
                {isLoadingMetrics && <GenericLoading />}
                {!isLoadingMetrics && metrics && (
                  <MetricSettingsList
                    disabled={!isActive}
                    metricsData={metricsDataForList}
                    settings={metricSettings}
                    onChange={(metricId, pushType) =>
                      // @ts-ignore
                      setValue(`metricSettings`, {
                        ...metricSettings,
                        [metricId]: pushType,
                      })
                    }
                    onChangeAll={pushType => onChangeAllMetrics(pushType)}
                  />
                )}
              </div>
            </div>
            <div className={classes.Block}>
              <div className={classes.SubTitle}>
                {t(TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.CUSTOMIZATION_SECTION.TITLE)}
              </div>
              <div className={classes.Description}>
                {t(TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.CUSTOMIZATION_SECTION.DESCRIPTION)}
              </div>
              <SignificancyDefinitionSelector
                anomalyMode={anomalyMode}
                onChangeAnomalyMode={mode => setValue('anomalyMode', mode)}
                anomalyThreshold={anomalyThreshold}
                onChangeAnomalyThreshold={threshold => setValue('anomalyThreshold', threshold)}
                disabled={!isActive}
              />
              <SubscribedSegmentsSelector
                subscribedSegmentsType={subscribedSegmentsType}
                onChangeSubscribedSegmentsType={type => setValue('subscribedSegmentsType', type)}
                team={selectedTeam}
                onTeamSegmentsClick={onTeamSegmentsClick}
                disabled={!isActive}
              />
            </div>
          </div>
        </FormProvider>
      </ModalLayout>
    </div>
  );
};

export const HomepageSubscriptionPanel = composition<AllProps>(
  HomepageSubscriptionPanelComponent,
  withModalInactiveSourceHandler,
  withDisableDemoProduct,
  withLoadBefore({
    channels: {
      selectedKey: HOMEPAGE_PRODUCT_SLACK_CHANNELS_SELECTED_KEY,
      actionKey: HOMEPAGE_PRODUCT_SLACK_CHANNELS_SELECTED_KEY,
      request: getSlackChannelsNetworkRequest,
    },
    homepageSettings: {
      selectedKey: HOMEPAGE_SETTINGS_SELECTED_KEY,
      actionKey: HOMEPAGE_SETTINGS_SELECTED_KEY,
      request: getHomepageSettingsNetworkRequest,
      mapPayloadFromProps: props => props[HOMEPAGE_ID_PATH_PARAM],
    },
    data: {
      selectedKey: HOMEPAGE_SUBSCRIPTION_SELECTED_KEY,
      actionKey: HOMEPAGE_SUBSCRIPTION_SELECTED_KEY,
      request: getHomepageSubscriptionNetworkRequest,
      mapPayloadFromProps: props => props[HOMEPAGE_ID_PATH_PARAM],
    },
  })
);
