import {TableEntity} from '../../../objects/models/table.model';
import {HomepageMetric} from '../../../objects/models/homepage.model';

export enum ChangeDescription {
  NO_CHANGE = 'no_change',
  INCREASE = 'increase',
  DECREASE = 'decrease',
}

export enum ChangeSentiment {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  NEUTRAL = 'neutral',
}

export enum RCAInsightsSectionKeys {
  POTENTIAL_DRIVERS = 'POTENTIAL_DRIVERS',
  INTERESTING_SEGMENTS_PERFORMANCE_VS_PRIOR_WEEK = 'INTERESTING_SEGMENTS_PERFORMANCE_VS_PRIOR_WEEK',
}

export enum RCAInsightsSentenceKeys {
  SPIKE_OVERPERFORMING_SEGMENT_SHARE_INCREASE_AND_KPI_INCREASE = 'SPIKE_OVERPERFORMING_SEGMENT_SHARE_INCREASE_AND_KPI_INCREASE',
  SPIKE_UNDERPERFORMING_SEGMENT_SHARE_DECREASE_AND_KPI_INCREASE = 'SPIKE_UNDERPERFORMING_SHARE_DECREASE_AND_KPI_INCREASE',
  DROP_UNDERPERFORMING_SEGMENT_SHARE_INCREASE_AND_KPI_DECREASED = 'DROP_UNDERPERFORMING_SEGMENT_SHARE_INCREASE_AND_KPI_DECREASED',
  DROP_OVERPERFORMING_SEGMENT_SHARE_DECREASE_AND_KPI_DECREASED = 'DROP_OVERPERFORMING_SEGMENT_SHARE_DECREASE_AND_KPI_DECREASED',

  SPIKE_OVERPERFORMING_SEGMENT_SHARE_INCREASE = 'SPIKE_OVERPERFORMING_SEGMENT_SHARE_INCREASE',
  SPIKE_UNDERPERFORMING_SEGMENT_SHARE_DECREASE = 'SPIKE_UNDERPERFORMING_SHARE_DECREASE',
  DROP_UNDERPERFORMING_SEGMENT_SHARE_INCREASE = 'DROP_UNDERPERFORMING_SEGMENT_SHARE_INCREASE',
  DROP_OVERPERFORMING_SEGMENT_SHARE_DECREASE = 'DROP_OVERPERFORMING_SEGMENT_SHARE_DECREASE',

  SPIKE_OVERPERFORMING_SEGMENT_INCREASES_KPI = 'SPIKE_OVERPERFORMING_SEGMENT_INCREASES_KPI',
  SPIKE_UNDERPERFORMING_SEGMENT_INCREASED_KPI = 'SPIKE_UNDERPERFORMING_SEGMENT_INCREASED_KPI',
  DROP_UNDERPERFORMING_SEGMENT_DECREASED_KPI = 'DROP_UNDERPERFORMING_SEGMENT_DECREASED_KPI',
  DROP_OVERPERFORMING_SEGMENT_DECREASED_KPI = 'DROP_OVERPERFORMING_SEGMENT_DECREASED_KPI',

  NO_EXPLAINERS = 'NO_EXPLAINERS',

  NOT_SIGNIFICANT_CHANGE_KPI_AND_USERS = 'NOT_SIGNIFICANT_CHANGE_KPI_AND_USERS',
  NOT_SIGNIFICANT_CHANGE_KPI = 'NOT_SIGNIFICANT_CHANGE_KPI',
  NOT_SIGNIFICANT_USERS_CHANGE = 'NOT_SIGNIFICANT_USERS_CHANGE',
}

interface RCAInsightSentenceParts {
  changeDescription: ChangeDescription;
  isCountEntitiesMode?: boolean;
  entity?: TableEntity;
  segmentName?: string;
  segmentGroupName?: string;
  signalId?: number;
  changeSentiment?: ChangeSentiment;
  changeInValue?: number;
  changeInShareOfEntities?: number;
  changeInTotalEntities?: number;
  higherIsBetter?: boolean;
  segmentChangeDescription?: ChangeDescription;
  entityChangeDescription?: ChangeDescription;
}
// Potential Drivers formatted sentence parts
export interface SpikeDropOverUnderPerformingSegmentShareIncreaseDecreaseAndKpiIncreaseDecrease {
  segmentName: string;
  changeInValue: string;
  changeDescription: string;
  changeInShareOfEntities: string;
  entity: string;
  entityChangeDescription: string;
  entityChangeDescriptionSynonym: string;
  presentTensePerformanceVsAvg: string;
  pastTensePerformanceVsPriorWeek: string;
  segmentChangeDescription: string;
}

export interface SpikeDropOverUnderPerformingSegmentShareIncreaseDecrease {
  segmentName: string;
  entity: string;
  entityChangeDescription: string;
  changeInShareOfEntities: string;
  presentTensePerformanceVsAvg: string;
}

export interface SpikeDropOverUnderPerformingIncreasedDecreasedKPI {
  segmentName: string;
  pastTensePerformanceVsWow: string;
  changeInValue: string;
}

export interface NoExplainers {
  changeDescription: string;
}

type PotentialDriversFormattedSentenceParts =
  | SpikeDropOverUnderPerformingSegmentShareIncreaseDecreaseAndKpiIncreaseDecrease
  | SpikeDropOverUnderPerformingSegmentShareIncreaseDecrease
  | SpikeDropOverUnderPerformingIncreasedDecreasedKPI
  | NoExplainers;

// Interesting Segments Performance Vs Prior Week formatted sentence parts
export interface NotSignificantChangeKPI {
  segmentName: string;
  presentTensePerformance: string;
  changeInValue: string;
}

export interface NotSignificantChangeKPIAndUsers {
  segmentName: string;
  presentTensePerformance: string;
  changeInValue: string;
  changeInShareOfEntitiesDescription: string;
  changeInTotalEntities: string;
  entity: string;
}

export interface NotSignificantUsersChange {
  segmentName: string;
  changeInTotalEntities: string;
  entityChangeDescription: string;
  entity: string;
}

type InterestingSegmentsPerformanceVsPriorWeekFormattedSentenceParts =
  | NotSignificantChangeKPI
  | NotSignificantChangeKPIAndUsers
  | NotSignificantUsersChange;

export type FormattedSentenceParts =
  | PotentialDriversFormattedSentenceParts
  | InterestingSegmentsPerformanceVsPriorWeekFormattedSentenceParts;

export interface RCAInsightSentence {
  key: RCAInsightsSentenceKeys;
  sentenceParts: RCAInsightSentenceParts;
  formattedSentenceParts: FormattedSentenceParts;
}

export interface RCAInsightSection {
  key: RCAInsightsSectionKeys;
  title: string;
  sentences: RCAInsightSentence[];
}

export interface RCAInsights {
  homepageMetric: HomepageMetric; // Assuming you have this interface defined elsewhere
  potentialDrivers: RCAInsightSection;
  interestingSegmentsPerformanceVsPriorWeek?: RCAInsightSection;
}
