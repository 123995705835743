import {action, ActionHooks} from 'front-core';
import {ExperimentDTO} from '../../objects/dto/experiment.dto';
import {showConfirmationMessage} from '../interface/interface.actions';
import i18n from '../../config/i18n.config';
import TransKeys from '../../constants/translation-keys';
import {ExperimentSubscriptionDTO} from '../../objects/dto/experiment-subscription.dto';

export enum ExperimentsActionType {
  CREATE_EXPERIMENT = '@@EXPERIMENTS/CREATE_EXPERIMENT',
  PATCH_EXPERIMENT = '@@EXPERIMENTS/PATCH_EXPERIMENT',
  UPDATE_EXPERIMENT = '@@EXPERIMENTS/UPDATE_EXPERIMENT',
  DELETE_EXPERIMENT = '@@EXPERIMENTS/DELETE_EXPERIMENT',
  CREATE_EXPERIMENT_SUBSCRIPTION = '@@EXPERIMENTS/CREATE_EXPERIMENT_SUBSCRIPTION',
  EDIT_EXPERIMENT_SUBSCRIPTION = '@@EXPERIMENTS/EDIT_EXPERIMENT_SUBSCRIPTION',
  DELETE_EXPERIMENT_SUBSCRIPTION = '@@EXPERIMENTS/DELETE_EXPERIMENT_SUBSCRIPTION',
  RERUN_EXPERIMENT_LAST_COMPLETED_ANALYSIS_RESULT = '@@EXPERIMENTS/RERUN_EXPERIMENT_LAST_COMPLETED_ANALYSIS_RESULT',
}

export const createExperiment = (data: ExperimentDTO, hooks?: ActionHooks) =>
  action(ExperimentsActionType.CREATE_EXPERIMENT, data, hooks);

export const patchExperimentConfirmed = (data: ExperimentDTO) =>
  showConfirmationMessage(
    {
      title: i18n.t(TransKeys.CONFIRMATIONS.UPDATE_EXPERIMENT.TITLE),
      content: i18n.t(TransKeys.CONFIRMATIONS.UPDATE_EXPERIMENT.CONTENT),
    },
    () => [patchExperiment(data)]
  );

export const patchExperiment = (data: ExperimentDTO, hooks?: ActionHooks) =>
  action(ExperimentsActionType.PATCH_EXPERIMENT, data, hooks);

export const updateExperimentConfirmed = onApprove =>
  showConfirmationMessage(
    {
      title: i18n.t(TransKeys.CONFIRMATIONS.EDIT_EXPERIMENT.TITLE),
      content: i18n.t(TransKeys.CONFIRMATIONS.EDIT_EXPERIMENT.CONTENT),
    },
    () => {
      onApprove();
      return [];
    }
  );

export const updateExperiment = (data: ExperimentDTO, hooks?: ActionHooks) =>
  action(ExperimentsActionType.UPDATE_EXPERIMENT, data, hooks);

export const deleteExperiment = (id: number) => action(ExperimentsActionType.DELETE_EXPERIMENT, id);

export const deleteExperimentConfirmed = (id: number) =>
  showConfirmationMessage(
    {
      title: i18n.t(TransKeys.CONFIRMATIONS.DELETE_EXPERIMENT.TITLE),
      content: i18n.t(TransKeys.CONFIRMATIONS.DELETE_EXPERIMENT.CONTENT),
    },
    () => [deleteExperiment(id)]
  );

export const createExperimentSubscription = (
  data: ExperimentSubscriptionDTO,
  hooks?: ActionHooks
) => action(ExperimentsActionType.CREATE_EXPERIMENT_SUBSCRIPTION, data, hooks);

export const editExperimentSubscription = (data: ExperimentSubscriptionDTO, hooks?: ActionHooks) =>
  action(ExperimentsActionType.EDIT_EXPERIMENT_SUBSCRIPTION, data, hooks);

export const deleteExperimentSubscription = (
  data: Omit<ExperimentSubscriptionDTO, 'cronExp'>,
  hooks?: ActionHooks
) => action(ExperimentsActionType.DELETE_EXPERIMENT_SUBSCRIPTION, data, hooks);

export const rerunExperimentLastCompletedAnalysisResultConfirmed = (
  id: number,
  onApprove: () => void
) =>
  showConfirmationMessage(
    {
      title: i18n.t(TransKeys.CONFIRMATIONS.RERUN_ANALYSIS_RESULT.TITLE),
      content: i18n.t(TransKeys.CONFIRMATIONS.RERUN_ANALYSIS_RESULT.CONTENT),
    },
    () => {
      onApprove();
      return [rerunExperimentLastCompletedAnalysisResult(id)];
    }
  );

export const rerunExperimentLastCompletedAnalysisResult = (id: number) =>
  action(ExperimentsActionType.RERUN_EXPERIMENT_LAST_COMPLETED_ANALYSIS_RESULT, id);
