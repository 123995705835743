import yup from '../../config/yup.config';
import {InviteUsersModel} from '../models/invite-users.model';

export interface InviteUsersDTO extends InviteUsersModel {}

export const inviteUsersDTOValidator = yup.object().shape({
  emails: yup.array().of(yup.string().email()).min(1),
  message: yup.string().nullable(),
  defaultTeamId: yup.number().nullable(),
});
