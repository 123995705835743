import {NotSignificantChangeKPIAndUsers, RCAInsightSentence} from './objects';
import sharedClasses from '../insights.module.scss';
import {
  ChangeInTotalEntitiesRCAInsightSentencePart,
  ChangeInValueRCAInsightSentencePart,
  SegmentNameRCAInsightSentencePart,
} from '../components/shared-sentence-parts/shared-sentence-parts.components';

interface OwnProps extends RCAInsightSentence {
  formattedSentenceParts: NotSignificantChangeKPIAndUsers;
}

type AllProps = OwnProps;

const NotSignificantChangeKPIAndUsersFormatter: React.FC<AllProps> = (props: AllProps) => {
  const {formattedSentenceParts} = props;
  const {
    segmentName,
    presentTensePerformance,
    changeInValue,
    changeInShareOfEntitiesDescription,
    changeInTotalEntities,
    entity,
  } = formattedSentenceParts;

  return (
    <div className={sharedClasses.InsightSentenceContainer}>
      <SegmentNameRCAInsightSentencePart segmentName={segmentName} />
      <span
        className={sharedClasses.InsightSentencePart}
      >{`${presentTensePerformance} vs the prior week by`}</span>
      <ChangeInValueRCAInsightSentencePart changeInValue={changeInValue} />
      <span
        className={sharedClasses.InsightSentencePart}
      >{`and the share of ${entity} ${changeInShareOfEntitiesDescription} by`}</span>
      <ChangeInTotalEntitiesRCAInsightSentencePart changeInTotalEntities={changeInTotalEntities} />
    </div>
  );
};

export {NotSignificantChangeKPIAndUsersFormatter};
