import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import {FormProvider, useForm} from 'react-hook-form';
import classNames from 'classnames';
import {LoginDTO, loginDTOValidator} from '../../../../../objects/dto/login.dto';
import sharedClasses from '../../shared-forms.module.scss';
import classes from './login-form.module.scss';
import TransKeys from '../../../../../constants/translation-keys';
import {composition} from 'front-core';
import {yupResolver} from '@hookform/resolvers/yup';
import {AppRoutes} from '../../../../../constants/app-routes';
import AuthForm from '../../auth-scope/auth-scope-form/auth-form.component';
import {OauthProvider, DEFAULT_OAUTH_PROVIDERS} from 'ui-components';
import {AuthFormTextInput} from '../../auth-scope/inputs/auth-text-input/auth-text-input.component';
import {useLocation, NavLink} from 'react-router-dom';
import {OauthProviders} from '../../oauth-providers/oauth-providers.component';

interface OwnProps {
  onSubmit: (loginDTO: LoginDTO) => void;
  isLoading: boolean;
  error?: string;
}
const sentencePartToSpan = (sentence: string) =>
  sentence.split(' ').map(word => <span className={classes.Span}>{word}</span>);

type AllProps = OwnProps;

const LoginFormComponent: React.FC<AllProps> = ({onSubmit, isLoading, error}: AllProps) => {
  const {t} = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const redirectTo = useMemo(
    () =>
      location.search.includes('?redirectTo=')
        ? location.search.substring('?redirectTo='.length)
        : '',
    [location.search]
  );
  const isClickupDedicatedLogin = useMemo(() => location.pathname.includes('clickup'), [location]);

  const formMethods = useForm<LoginDTO>({
    defaultValues: {
      email: '',
      password: '',
      redirectTo,
    },
    resolver: yupResolver(loginDTOValidator, {abortEarly: false}),
  });
  const {handleSubmit, watch} = formMethods;
  const email = watch('email');

  const renderLeftToSubmitProp = useMemo(
    () => (
      <>
        <span className={classes.Link} onClick={() => history.push(AppRoutes.forgotPassword())}>
          {t(TransKeys.AUTH_FORMS.LOGIN.FORGOT)}
        </span>
        <div className={classes.LoginProvidersContainer}>
          <div className={classes.BottomWrapper}>
            <span>{t(TransKeys.AUTH_FORMS.LOGIN.NO_ACCESS)}</span>
            <span className={classes.Link} onClick={() => history.push(AppRoutes.signUp())}>
              {t(TransKeys.AUTH_FORMS.LOGIN.SIGN_UP)}
            </span>
          </div>
        </div>
      </>
    ),
    [t, history]
  );

  const renderClickupHelperText = () => (
    <div className={classes.ClickupHelperText}>
      {sentencePartToSpan('Please use the following')}
      <NavLink to={`${AppRoutes.login().pathname}/clickup`} className={classes.Link}>
        {' '}
        link{' '}
      </NavLink>
      {sentencePartToSpan('to connect using Okta')}
    </div>
  );
  // This is a temp solution for clickup login via their okta app.
  // we should create the dynamic logic for login with okta
  if (isClickupDedicatedLogin) {
    return (
      <FormProvider {...formMethods}>
        <div className={classes.LoginForm}>
          <div className={classes.LoginForm}>
            <OauthProviders
              providers={[OauthProvider.OKTA]}
              action={'Login'}
              redirectTo={redirectTo}
            />
          </div>
        </div>
      </FormProvider>
    );
  }

  return (
    <FormProvider {...formMethods}>
      <div className={classes.LoginForm}>
        <AuthForm
          disableOnSubmit={isLoading}
          onSubmit={handleSubmit(onSubmit)}
          onSubmitText={t(TransKeys.AUTH_FORMS.LOGIN.FORM.SUBMIT)}
          error={error}
          renderLeftToSubmit={renderLeftToSubmitProp}
        >
          <div className={classes.LoginForm}>
            <OauthProviders
              providers={DEFAULT_OAUTH_PROVIDERS}
              action={'Login'}
              redirectTo={redirectTo}
            />
            <div className={sharedClasses.FormBlock}>
              <AuthFormTextInput
                required
                label={t(TransKeys.AUTH_FORMS.LOGIN.FORM.USERNAME)}
                name={'email'}
                type={'text'}
              />
            </div>
            {email && email.toUpperCase().includes('clickup.com') && renderClickupHelperText()}
            <div className={classNames(sharedClasses.FormBlock, classes.Password)}>
              <AuthFormTextInput
                required
                label={t(TransKeys.AUTH_FORMS.LOGIN.FORM.PASSWORD)}
                name={'password'}
                type={'password'}
              />
            </div>
          </div>
        </AuthForm>
      </div>
    </FormProvider>
  );
};

const LoginForm = composition<OwnProps>(LoginFormComponent);
export default LoginForm;
