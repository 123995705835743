// This function should sit under slack.config or something like that
const getSlackHref = clientId => {
  const slackOauthUrl = `https://slack.com/oauth/v2/authorize`;
  const sharedSlackScopes = `users:read,users:read.email,channels:read,groups:read,im:read,mpim:read`;
  const slackScope = `scope=chat:write,chat:write.public,${sharedSlackScopes}`;
  const slackUserScope = `user_scope=${sharedSlackScopes}`;
  return `${slackOauthUrl}?client_id=${clientId}&${slackScope}&${slackUserScope}`;
};

let appEnv = 'development';
if (window.location.hostname.endsWith('us.getloops.ai')) {
  appEnv = 'prod_us';
} else if (window.location.hostname.endsWith('getloops.ai')) {
  appEnv = 'prod_eu';
}

const ENV_CONFIG = {
  development: {
    serverUrl: process.env.REACT_APP_API_URL,
    translationsUrl: 'http://localhost:3333/translations',
    amplitudeApiKey: '498f01320e9529b70cd628abcda1df4d',
    amplitudeApiEndpoint: 'api.amplitude.com',
    userSnapApiKey: undefined,
    pendoApiKey: undefined,
    helpScoutApiKey: undefined,
    sentryDsn: undefined,
    hotjarID: undefined,
    hotjarSnippetVersion: undefined,
    // slackHref: getSlackHref('1275595880982.1280967092021'), // Guy's Development Bot
    slackHref: getSlackHref('1275595880982.4887327332183'), // Loops Development Bot
    growthbookApiKey: 'sdk-34MXZsKvmue9JOi',
    growthbookDevMode: true,
    pusherKey: '4e17c404c33bc756451f',
    pusherCluster: 'eu',
  },
  prod_eu: {
    serverUrl: window.location.protocol + '//api.getloops.ai',
    translationsUrl: 'https://api.getloops.ai/public/translations/app',
    amplitudeApiKey: 'd8a129be5759933943877b07a4fbad97',
    amplitudeApiEndpoint: 'app.getloops.ai/amplitude',
    userSnapApiKey: 'b7848c52-62e1-4d1a-8b67-f9e52e4d985f',
    pendoApiKey: 'a5c2e967-4cb4-4120-617e-3a76e9b72407',
    helpScoutApiKey: 'd31d7bbe-dca7-4509-9f36-fd615f11e996',
    sentryDsn:
      'https://80a78fd445f54fa7b7835acaa968a5f9@o4504810144137216.ingest.sentry.io/4504810148790272',
    hotjarID: 3397018,
    hotjarSnippetVersion: 6,
    slackHref: getSlackHref('1275595880982.4982492673557'),
    growthbookApiKey: 'sdk-XgutjdAvTnT964o1',
    growthbookDevMode: false,
    pusherKey: '3e6f3c897a1843692864',
    pusherCluster: 'eu',
  },
  prod_us: {
    serverUrl: window.location.protocol + '//api.us.getloops.ai',
    translationsUrl: 'https://api.us.getloops.ai/public/translations/app',
    amplitudeApiKey: 'b7a4d5f169ad95e1fa902b0439388166',
    amplitudeApiEndpoint: 'app.us.getloops.ai/amplitude',
    userSnapApiKey: undefined,
    pendoApiKey: undefined,
    helpScoutApiKey: 'd31d7bbe-dca7-4509-9f36-fd615f11e996',
    sentryDsn: undefined,
    hotjarID: undefined,
    hotjarSnippetVersion: undefined,
    slackHref: getSlackHref('1275595880982.6522074260643'),
    growthbookApiKey: 'sdk-XgutjdAvTnT964o1',
    growthbookDevMode: false,
  },
};

export default ENV_CONFIG[appEnv];
