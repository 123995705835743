import * as React from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Button,
  ConditionOperator,
  FancyHeader,
  FeatureIcon,
  LabelWrapper,
  ModalLayout,
  QueryBuilder,
  QueryBuilderFactory,
  MultiSelect,
  useRemoteSourceStated,
} from 'ui-components';
import {FEATURE_ID_PATH_PARAM, TABLE_EVENT_ID_PATH_PARAM} from '../../../../constants/app-routes';
import classes from './feature-form-panel.module.scss';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../constants/translation-keys';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {Feature} from '../../../../objects/models/feature.model';
import {values} from 'lodash';
import {featureDTOValidator} from '../../../../objects/dto/feature.dto';
import {createFeature, updateFeature} from '../../../../store/features/features.actions';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys';
import {useDispatch, useSelector} from 'react-redux';
import {getReducedLoadingStateSelector} from '../../../../store/store.selectors';
import {sharedClasses} from '../../../shared';
import {yupResolver} from '@hookform/resolvers/yup';
import {getFeatureNetworkRequest} from '../../../../http/features.network-requests';
import {ActionKey} from '../../../../constants/action-key';
import {OnSuccessActionHook, composition, exists, withMetadata} from 'front-core';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {FormHiddenInputs} from '../../../shared/form/components/form-hidden-inputs.component';
import {
  TextareaFormInput,
  TextFormInput,
} from '../../../shared/form/components/shared-form-input.component';
import {contactSupport, preventSubmitOnEnter} from '../../../../utils/general.utils';
import {FunnelTabs} from '../../../shared/components/general/funnel-tabs/funnel-tabs.component';
import {queryBuilderModelConfig} from '../../../../constants/query-builder';
import {QueryBuilderConfig} from 'ui-components';
import {TabHeader} from '../../../shared/components/general/tab-header/tab-header.component';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {
  createTableEventsInitialQuery,
  TableEventsQueryBuilder,
} from '../../../shared/core/query-builders/table-events-query-builder/table-events-query-builder.component';
import {NoteText} from '../../../shared/components/general/note-text/note-text.component';
import {getComponentForQuery} from '../../../shared/core/query-builders/query-builders.utils';
import {useAmplitude} from '../../../../core/hooks/amplitude.hook';
import {AmplitudeEvent} from '../../../../constants/amplitude-event';
import {getTableEventNetworkRequest} from '../../../../http/table-events.network-requests';
import {TableEvent} from '../../../../objects/models/table-event.model';
import {METADATA_KEY, PARAMETERS_METADATA_KEY} from '../../../../constants/parameters-saved-keys';
import {SOURCE_PROP_KEY} from '../../../../constants/shared-component-prop-key';
import {TableEntity, TableEntityBinding} from '../../../../objects/models/table.model';
import {SOURCE_META_KEY} from '../../../../constants/app-sources';
import {getFeatureTagGroupsNetworkRequest} from '../../../../http/tags.network-requests';
import {EntityPicker} from '../../components/entity-picker/entity-picker.component';
import i18n from '../../../../config/i18n.config';
import {useCurrentUser} from '../../../../core/hooks/use-user.hook';
import {withModalInactiveSourceHandler} from '../../../../core/hoc/with-modal-inactive-source-handler.hoc';
import {useDemoProduct} from '../../../../core/hooks/use-demo-product.hook';
import {withDisableDemoProduct} from '../../../../core/hoc/with-disable-demo-product.hoc';

interface OwnProps {
  tableEvent?: TableEvent;
  feature?: Feature;
  data: Partial<Feature>;
  onSubmit?: (data: Partial<Feature>) => void;
  onSuccess?: OnSuccessActionHook;
  onClose?: () => void;
  panelId?: string;
  cloneMode?: boolean;
  disabled?: boolean;
  [FEATURE_ID_PATH_PARAM]?: number;
  [TABLE_EVENT_ID_PATH_PARAM]?: number;
  [SOURCE_PROP_KEY]?: string;
}

type AllProps = OwnProps;

const createEmptyFeature = (tableEvent?: TableEvent): Partial<Feature> => {
  let usageSignalDefinition = null;
  let name = '';
  let entity = null;
  if (exists(tableEvent)) {
    name = tableEvent.name;
    entity = tableEvent.tableEntity;
    const q = createTableEventsInitialQuery();
    let condition = QueryBuilderFactory.createCondition();
    condition.left = QueryBuilderFactory.createTableColumn();
    condition.left.table_id = tableEvent.tableId;
    condition.left.column = 'event_name';
    condition.op = ConditionOperator.EQ;
    condition.right = QueryBuilderFactory.createLiteral();
    condition.right.value_type = 'string';
    condition.right.value = tableEvent.name;
    const and = QueryBuilderFactory.createAndCondition();
    and.conditions = [condition];
    and[PARAMETERS_METADATA_KEY] = {
      [METADATA_KEY.TABLE_EVENT_ID_KEY]: tableEvent.id,
    };
    q.conditions = [and];
    usageSignalDefinition = q;
  }

  return {
    name: name,
    entity: entity || TableEntity.USER,
    shortDescription: '',
    usageSignalId: null,
    usageSignalDefinition: usageSignalDefinition,
    exposureSignalId: null,
    exposureSignalDefinition: null,
    intentSignalId: null,
    intentSignalDefinition: null,
    activationSignalId: null,
    activationSignalDefinition: null,
    churnSignalId: null,
    churnSignalDefinition: null,
  };
};

const SELECTED_FEATURE_KEY = SharedSelectionKeys.FEATURE_FORM__FEATURE;
const SELECTED_TABLE_EVENT_KEY = SharedSelectionKeys.FEATURE_FORM__TABLE_EVENT;

export enum FeatureTabOptions {
  exposure = 'exposure',
  intent = 'intent',
  usage = 'usage',
}

export const generateFeatureTabs = () => ({
  [FeatureTabOptions.exposure]: {
    key: FeatureTabOptions.exposure,
    title: 'Exposure',
    helperText: i18n.t(TransKeys.FEATURE_FORM.TABS.EXPOSURE.TEXT),
  },
  [FeatureTabOptions.intent]: {
    key: FeatureTabOptions.intent,
    title: 'Intent',
    helperText: i18n.t(TransKeys.FEATURE_FORM.TABS.INTENT.TEXT),
  },
  [FeatureTabOptions.usage]: {
    key: FeatureTabOptions.usage,
    title: 'Usage',
    helperText: i18n.t(TransKeys.FEATURE_FORM.TABS.USAGE.TEXT),
  },
});

const generateSignalKey = key => key + 'SignalDefinition';

const isCustomQueryBuilder = query => {
  if (!query) {
    return false;
  }
  return getComponentForQuery(query) === undefined;
};

export const getInitialActiveTabForFeature = (
  feature?: Feature,
  signalId?: number
): FeatureTabOptions => {
  if (signalId) {
    if (feature?.usageSignalId === signalId) {
      return FeatureTabOptions.usage;
    }
    if (feature?.intentSignalId === signalId) {
      return FeatureTabOptions.intent;
    }
    if (feature?.exposureSignalId === signalId) {
      return FeatureTabOptions.exposure;
    }
  }

  if (feature?.usageSignalDefinition) {
    return FeatureTabOptions.usage;
  }
  if (feature?.intentSignalDefinition) {
    return FeatureTabOptions.intent;
  }
  if (feature?.exposureSignalDefinition) {
    return FeatureTabOptions.exposure;
  }
  return FeatureTabOptions.usage;
};

const FeatureFormPanelComponent: React.FC<AllProps> = (props: AllProps) => {
  const {
    tableEvent,
    feature,
    onClose,
    onSuccess,
    cloneMode,
    onSubmit: onSubmitFromProps,
    disabled,
    [SOURCE_PROP_KEY]: appSource,
  } = props;
  const {t} = useTranslation();
  const {demoProductValidator} = useDemoProduct();
  const dispatch = useDispatch();
  const notify = useAmplitude();
  const user = useCurrentUser();
  const {source: featureTags, exec: getFeatureTags} = useRemoteSourceStated({
    networkRequest: getFeatureTagGroupsNetworkRequest,
    type: 'source',
    initialValue: [],
  });
  const formInitialValues = useMemo(() => {
    const values = {
      ...(!exists(feature) ? createEmptyFeature(tableEvent) : feature),
    };
    cloneMode && delete values.id;
    return values;
  }, [feature, cloneMode, tableEvent]);
  const formMethods = useForm({
    defaultValues: formInitialValues as any,
    resolver: yupResolver(demoProductValidator || featureDTOValidator.noUnknown()),
  });
  const {
    handleSubmit,
    formState: {errors, isSubmitting, isValidating},
    watch,
    control,
    setValue,
  } = formMethods;
  const entity = watch('entity');

  const [selectedFunnelTab, setSelectedFunnelTab] = useState<string>(
    getInitialActiveTabForFeature(feature)
  );
  const isLoading = useSelector(state =>
    getReducedLoadingStateSelector(ActionKey.CREATE_FEATURE, ActionKey.UPDATE_FEATURE)(state)
  );
  const eventFilters = useMemo(
    () => ({
      entityContext: entity,
      entityBinding: TableEntityBinding.TWO_WAY,
    }),
    [entity]
  );
  const onSwitchEntity = useCallback(
    (newEntity: TableEntity) => {
      notify(AmplitudeEvent.FEATURE_FORM_FIELD_CHANGED, {
        ...(feature ? {id: feature.id} : {}),
        field: 'entity',
        value: newEntity,
        previousValue: entity,
      });
      setValue('entity', newEntity);
      values(FeatureTabOptions).forEach(key => setValue(generateSignalKey(key), null));
    },
    [notify, feature, entity, setValue]
  );
  const queryBuilderConfig: QueryBuilderConfig = useMemo(
    () => ({
      modelConfig: queryBuilderModelConfig,
    }),
    []
  );
  const editMode = Boolean(watch('id'));
  const onSubmit = useCallback(
    data => {
      if (onSubmitFromProps) {
        onSubmitFromProps(data);
        return;
      }
      const onActionSuccess = (res, action) => {
        onSuccess && onSuccess(res, action);
        onClose();
      };
      let action = editMode
        ? updateFeature(data, onActionSuccess)
        : createFeature(data, onActionSuccess);
      action = withMetadata(action, {[SOURCE_META_KEY]: appSource});
      dispatch(action);
      notify(AmplitudeEvent.FEATURE_SAVE_CLICKED, {
        userId: user.id,
      });
    },
    [dispatch, editMode, onSubmitFromProps, onClose, user, notify, appSource, onSuccess]
  );

  const featureTabs = useMemo(() => generateFeatureTabs(), []);

  const renderFunnelTab = (tabKey: string) => {
    const dataKey = generateSignalKey(tabKey);
    return (
      <div className={classes.FeatureTab} key={`${tabKey}_${feature?.id}`}>
        <TabHeader
          size={'small'}
          title={`${t(TransKeys.GENERAL.LABELS.DEFINE)} ${featureTabs[tabKey]?.title}`}
          subTitle={featureTabs[tabKey]?.helperText}
        />
        <Controller
          render={({field}) => {
            const isQueryBuilder = isCustomQueryBuilder(field.value);
            if (isQueryBuilder) {
              return (
                <QueryBuilder
                  config={queryBuilderConfig}
                  query={field.value}
                  onChange={v => field.onChange(v || null)}
                  errors={errors[dataKey]}
                />
              );
            }
            return (
              <TableEventsQueryBuilder
                query={field.value as any}
                onChange={v => field.onChange(v || null)}
                errors={errors[dataKey]}
                filters={eventFilters}
              />
            );
          }}
          name={dataKey}
          control={control}
        />
        <NoteText
          text={t(TransKeys.SUPPORT.MISSING_EVENT_OR_PROPERTY)}
          buttonText={t(TransKeys.SUPPORT.SUPPORT_BUTTON_TEXT)}
          onButtonClicked={contactSupport}
        />
      </div>
    );
  };
  const funnelTabs = values(featureTabs).map(tab => ({
    ...tab,
    error: errors[generateSignalKey(tab.key)]?.message,
    render: () => renderFunnelTab(tab.key),
  }));
  const containerStyle = useMemo(() => ({height: '70vh', width: '50vw', minWidth: '80rem'}), []);
  const missingDefinitionError = useMemo(() => {
    // empty string because the validation defined at root object
    if (errors['']?.type === 'atLeastOneOf') {
      return errors[''].message;
    }
  }, [errors]);
  const featureTagsOptions = useMemo(
    () => ({options: featureTags.map(ft => ({label: ft.name, value: ft.name}))}),
    [featureTags]
  );
  const isDisabled = useMemo(
    () => isLoading || isSubmitting || isValidating || disabled,
    [isLoading, isSubmitting, isValidating, disabled]
  );

  useEffect(() => {
    getFeatureTags();
  }, [getFeatureTags]);

  return (
    <div style={containerStyle}>
      <ModalLayout
        footer={
          <Button disabled={isDisabled} onClick={handleSubmit(onSubmit)}>
            {t(TransKeys.GENERAL.ACTIONS.SAVE)}
          </Button>
        }
      >
        {isLoading && <GenericLoading />}
        <div className={classes.CreateFeature}>
          <FancyHeader
            icon={FeatureIcon}
            title={t(
              editMode ? TransKeys.FEATURE_FORM.TITLE_EDIT : TransKeys.FEATURE_FORM.TITLE_CREATE
            )}
            subTitle={t(TransKeys.FEATURE_FORM.MAIN_TITLE)}
            onClose={onClose}
            className={classes.CreateFeatureHeader}
          />

          <FormProvider {...formMethods}>
            <form
              className={sharedClasses.Form}
              onKeyDown={preventSubmitOnEnter}
              onSubmit={e => {
                e.stopPropagation();
                handleSubmit(onSubmit)(e);
              }}
            >
              <FormHiddenInputs names={['id']} />
              <div className={sharedClasses.FormContent}>
                <div className={sharedClasses.Block}>
                  <div className={sharedClasses.Input}>
                    <TextFormInput
                      label={t(TransKeys.FEATURE_FORM.INPUTS.NAME.LABEL)}
                      placeholder={t(TransKeys.FEATURE_FORM.INPUTS.NAME.PLACEHOLDER)}
                      name={'name'}
                      required
                    />
                  </div>
                </div>
                <div className={sharedClasses.Block}>
                  <div className={sharedClasses.Input}>
                    <TextareaFormInput
                      placeholder={t(TransKeys.FEATURE_FORM.INPUTS.DESCRIPTION.PLACEHOLDER)}
                      label={t(TransKeys.FEATURE_FORM.INPUTS.DESCRIPTION.LABEL)}
                      name={'shortDescription'}
                    />
                  </div>
                </div>
                <div className={sharedClasses.Block}>
                  <div className={sharedClasses.Input}>
                    <EntityPicker value={entity} editMode={!editMode} onChange={onSwitchEntity} />
                  </div>
                </div>
                <div className={sharedClasses.Block}>
                  <div className={sharedClasses.Input}>
                    <LabelWrapper label={t(TransKeys.FEATURE_FORM.INPUTS.TAGS.LABEL)}>
                      <Controller
                        render={({field}) => (
                          <MultiSelect
                            placeholder={t(TransKeys.FEATURE_FORM.INPUTS.TAGS.PLACEHOLDER)}
                            value={field.value}
                            onChange={field.onChange}
                            fullWidth
                            options={featureTagsOptions}
                            freeText={true}
                            allowEdit={false}
                          />
                        )}
                        name={'tags'}
                        control={control}
                      />
                    </LabelWrapper>
                  </div>
                </div>
                <div className={sharedClasses.Block}>
                  <div className={sharedClasses.Input}>
                    <LabelWrapper
                      error={missingDefinitionError !== undefined}
                      label={
                        missingDefinitionError
                          ? `${t(TransKeys.GENERAL.LABELS.DEFINE)} - ${missingDefinitionError}`
                          : t(TransKeys.GENERAL.LABELS.DEFINE)
                      }
                      fullWidth
                    >
                      <FunnelTabs
                        tabs={funnelTabs}
                        onTabChange={setSelectedFunnelTab}
                        selectedKey={selectedFunnelTab}
                      />
                    </LabelWrapper>
                  </div>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </ModalLayout>
    </div>
  );
};

const FeatureFormPanel = composition<AllProps>(
  FeatureFormPanelComponent,
  withModalInactiveSourceHandler,
  withDisableDemoProduct,
  withLoadBefore({
    feature: {
      selectedKey: SELECTED_FEATURE_KEY,
      actionKey: SELECTED_FEATURE_KEY,
      request: getFeatureNetworkRequest,
      mapPayloadFromProps: props => props[FEATURE_ID_PATH_PARAM],
      shouldCall: props => props[FEATURE_ID_PATH_PARAM] !== undefined,
    },
    tableEvent: {
      selectedKey: SELECTED_TABLE_EVENT_KEY,
      actionKey: SELECTED_TABLE_EVENT_KEY,
      request: getTableEventNetworkRequest,
      mapPayloadFromProps: props => props[TABLE_EVENT_ID_PATH_PARAM],
      shouldCall: props => props[TABLE_EVENT_ID_PATH_PARAM] !== undefined,
    },
  })
);

export default FeatureFormPanel;
