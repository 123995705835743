import classNames from 'classnames';
import {LiteralType, TableColumn} from '../../../../../../objects/models/table-column.model';
import {TableParameters, TableType} from '../../../../../../objects/models/table.model';
import i18n from 'i18next';
import TransKeys from '../../../../../../constants/translation-keys';
import {useMemo, useState} from 'react';
import {
  AdvancedTable,
  TableColumn as TableColumnDefinition,
} from '../../../../../shared/components/general/table/table.component';
import {Button, Chip, SearchInput} from 'ui-components';
import {LITERAL_TYPE_TO_TEXT} from '../../../../../shared/core/smart-selector/components/previews/table-column-preview.component';
import {useTranslation} from 'react-i18next';
import classes from './table-events-table.module.scss';
import {exists} from 'front-core';
import {EmptyState} from '../../../../../shared/components/general/override';
import Fuse from 'fuse.js';
import {SignalDependencies} from '../../../../components/signal-dependencies/signal-dependencies.component';

const FUSE_OPTIONS = {
  threshold: 0.3,
  ignoreLocation: true,
  keys: [{name: 'name', weight: 2}, 'type'],
};

interface OwnProps {
  tableType: TableType;
  tableColumns: TableColumn[];
  tableParameters: TableParameters;
  onCreateSegment: (tableColumn: TableColumn) => void;
  className?: string;
}

const mapColumnToSpecialStatus = (
  column: TableColumn,
  tableParameters: TableParameters
): string => {
  let specialStatus = '';

  switch (column.name) {
    case tableParameters.entity_id:
      specialStatus = i18n.t(TransKeys.TABLES.PANEL.VIEW.COLUMNS.SPECIAL_STATUS.ENTITY_ID);
      break;
    case tableParameters.event_name:
      specialStatus = i18n.t(TransKeys.TABLES.PANEL.VIEW.COLUMNS.SPECIAL_STATUS.EVENT_NAME);
      break;
    case tableParameters.timestamp:
      specialStatus = i18n.t(TransKeys.TABLES.PANEL.VIEW.COLUMNS.SPECIAL_STATUS.TIMESTAMP);
      break;
  }

  return specialStatus;
};

type AllProps = OwnProps;

export const TableEventsTable: React.FC<AllProps> = (props: AllProps) => {
  const {tableType, tableColumns = [], onCreateSegment, tableParameters, className} = props;
  const {t} = useTranslation();
  const [searchText, setSearchText] = useState('');

  const data = useMemo(
    () =>
      tableColumns.map(column => ({
        ...column,
        specialStatus: mapColumnToSpecialStatus(column, tableParameters),
      })),
    [tableColumns, tableParameters]
  );
  const fuse = useMemo(() => new Fuse(data, FUSE_OPTIONS), [data]);
  const displayedSampleClasses = useMemo(() => {
    if (!exists(searchText)) {
      return data;
    }
    return fuse.search(searchText).map(result => result.item);
  }, [searchText, data, fuse]);

  const tableColumnDefinition: TableColumnDefinition[] = useMemo(
    () => [
      {
        key: 'name',
        title: t(TransKeys.TABLES.PANEL.VIEW.COLUMNS.TABLE_HEADERS.COLUMN_NAME),
        sortable: true,
        width: '50%',
        render: (tableColumn: TableColumn) => (
          <div className={classes.ColumnName}>
            <span>{tableColumn.name}</span>
            {tableColumn.specialStatus && (
              <Chip
                helperText={t(TransKeys.TABLES.PANEL.VIEW.COLUMNS.SPECIAL_STATUS.HELPER_TEXT)}
                label={tableColumn.specialStatus}
                className={classes.Status}
                size={'small'}
              />
            )}
          </div>
        ),
      },
      {
        key: 'literalType',
        title: t(TransKeys.TABLES.PANEL.VIEW.COLUMNS.TABLE_HEADERS.TYPE),
        sortable: true,
        width: '50%',
        render: (tableColumn: TableColumn) => (
          <span>{LITERAL_TYPE_TO_TEXT[tableColumn.literalType]}</span>
        ),
      },
      {
        key: 'dependencies',
        title: t(TransKeys.TABLES.PANEL.VIEW.COLUMNS.TABLE_HEADERS.DEPENDENCIES),
        sortable: false,
        width: '10%',
        render: (tableColumn: TableColumn) => (
          <SignalDependencies dependencies={tableColumn.dependencies} />
        ),
      },
      {
        key: 'actions',
        title: '',
        sortable: false,
        width: '1%',
        hidden: tableType !== TableType.ENTITY_PROPERTIES,
        showOnHover: true,
        render: (tableColumn: TableColumn) => {
          const isDisabled = tableColumn.literalType === LiteralType.DATE;
          const helperText = isDisabled
            ? t(TransKeys.VIEW_TABLE.COLUMNS_TABLE.CREATE_SEGEMENT_DISABLED_HELPER_TEXT)
            : '';
          return (
            <span>
              <Button
                onClick={() => onCreateSegment(tableColumn)}
                disabled={isDisabled}
                helperText={helperText}
              >
                {t(TransKeys.VIEW_TABLE.COLUMNS_TABLE.CREATE_SEGMENT_BUTTON_LABEL)}
              </Button>
            </span>
          );
        },
      },
    ],
    [t, tableType, onCreateSegment]
  );

  if (displayedSampleClasses.length === 0 && !exists(searchText)) {
    return (
      <div className={classes.TabWrapper}>
        <EmptyState size={'small'} title={t(TransKeys.VIEW_TABLE.COLUMNS_TABLE.EMPTY_STATE)} />
      </div>
    );
  }

  return (
    <div className={classNames(classes.TableEventsTable, className)}>
      <SearchInput
        placeholder={t(TransKeys.GENERAL.LABELS.SEARCH)}
        value={searchText}
        onChange={setSearchText}
        className={classes.SearchInput}
      />
      {displayedSampleClasses.length === 0 && exists(searchText) && (
        <EmptyState
          size={'small'}
          title={t(TransKeys.VIEW_TABLE.COLUMNS_TABLE.EMPTY_STATE_SEARCH)}
        />
      )}
      {displayedSampleClasses.length > 0 && (
        <AdvancedTable
          dataKey={'id'}
          columns={tableColumnDefinition}
          data={displayedSampleClasses}
          pagination={true}
        />
      )}
    </div>
  );
};
