import google from './company-logos/google.svg';
import spotify from './company-logos/spotify.svg';
import slack from './company-logos/slack.svg';
import facebook from './company-logos/facebook.svg';
import logo from './app/logo.png';
import logoGradient from './app/logo-gradient.svg';
import telescope from './app/telescope.svg';
import telescopeV2 from './app/telescope-v2.svg';
import magnifyingGlass from './app/magnifying-glass.svg';
import magnifyingGlassOnUsers from './app/magnifying-glass-on-users.svg';
import growthMap from './app/growth-map.svg';
import confetti from './backgrounds/confetti.svg';
import appBackground from './backgrounds/app-background.png';
import trophy from './app/trophy.svg';
import winner from './app/winner.svg';
import healthMonitorEmptyState from './app/health-monitor-empty-state-image.png';
import shortLogo from './app/short-logo.svg';
import squigglyArrowUp from './app/squiggly-arrow-up.svg';
import logoWhite from './app/logo-white.svg';
import logoPurple from './app/logo-purple.svg';
import miniLogoWhite from './app/mini-logo-white.svg';

export const ImagesResource = {
  app: {
    logoWhite,
    logoPurple,
    miniLogoWhite,
    logo,
    shortLogo,
    logoGradient,
    telescope,
    telescopeV2,
    magnifyingGlass,
    magnifyingGlassOnUsers,
    growthMap,
    trophy,
    winner,
    healthMonitorEmptyState,
    squigglyArrowUp,
  },
  companiesLogos: {
    google,
    spotify,
    facebook,
    slack,
  },
  backgrounds: {
    confetti,
    appBackground,
  },
};
