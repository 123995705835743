import * as React from 'react';
import classNames from 'classnames';
import classes from './goal-status.module.scss';
import {useMemo} from 'react';
import {getGoalParameters} from '../../homepage-summary.utils';
import {ExtendedHomepageMetric} from '../../homepage-summary.component';
import {ModelSeriesGranularity} from '../../../../../../objects/models/model-sample-series.model';
import TransKeys from '../../../../../../constants/translation-keys';
import {Tooltip} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

interface OwnProps {
  metric: ExtendedHomepageMetric;
  granularity: ModelSeriesGranularity;
  small?: boolean;
  className?: string;
}

type AllProps = OwnProps;

export const GoalStatus: React.FC<AllProps> = (props: AllProps) => {
  const {metric, granularity, small, className} = props;
  const {t} = useTranslation();

  const goalParameters = useMemo(
    () => getGoalParameters(metric, granularity),
    [metric, granularity]
  );
  if (!goalParameters) {
    return null;
  }

  return (
    <Tooltip
      title={
        <div className={classes.GoalTooltip}>
          {goalParameters.helperText}
          <div className={classes.Values}>
            {t(TransKeys.HOMEPAGE.LABELS.KPI_AVERAGE_VALUE)}: <b>{goalParameters.averageValue}</b>
          </div>
          <div>
            {t(TransKeys.HOMEPAGE.LABELS.QUARTERLY_TARGET)}: <b>{goalParameters.goalValue}</b>
          </div>
          <div>
            {t(TransKeys.HOMEPAGE.LABELS.CURRENT_TARGET_EOQ, {granularity})}:{' '}
            <b>{goalParameters.lastSampleGoalValue}</b>
          </div>
        </div>
      }
      placement={'top'}
      interactive={false}
    >
      <div
        className={classNames(
          classes.GoalStatus,
          goalParameters.isBehind && classes.Warn,
          small && classes.Small,
          className
        )}
      >
        <goalParameters.icon className={classes.Icon} />
        <span className={classes.Text}>{goalParameters.text}</span>
      </div>
    </Tooltip>
  );
};
