import * as React from 'react';
import classNames from 'classnames';
import classes from '../../analysis-forms.module.scss';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {SegmentFilterSelector} from '../../components/ui-selectors/segment-filter-selector/segment-filter-selector.component';
import {ParametersFormContext} from '../../../../shared/core/parameters-form/parameters-form.context';
import {KPIsSelector} from '../../components/ui-selectors/kpis-selector/kpis-selector.component';
import {FeaturesSignalSelector} from '../../components/ui-selectors/features-signal-selector/features-selector.component';
import TransKeys from '../../../../../constants/translation-keys';
import {ExtendedParameters} from '../../../../shared/form/form-layout/extended-parameters/extended-parameters.component';
import {useTranslation} from 'react-i18next';
import {StatisticalSignificanceSelector} from '../../components/ui-selectors/statistical-significance-selector/statistical-significance-selector.component';
import {SingleDateSelector} from '../../components/ui-selectors/single-date-selector/single-date-selector.component';
import {DaysCountSelector} from '../../components/ui-selectors/days-count-selector/days-count-selector.component';
import {AnalysisFormProps} from '../../analysis-forms.types';
import {SignalDataType, SignalType} from '../../../../../objects/models/signal.model';
import {SimpleNumberSelector} from '../../components/ui-selectors/simple-number-selector/simple-number-selector.component';
import {TableEntity, TableEntityBinding} from '../../../../../objects/models/table.model';
import {EntitySelector} from '../../components/ui-selectors/entity-selector/entity-selector.component';
import {createUndefinedObject, hasError} from '../../../../../utils/general.utils';
import {useProductData} from '../../../../../core/hooks/use-product-data.hook';
import {get, values} from 'lodash';
import {TreatmentSelector} from '../../components/ui-selectors/treatment-selector/treatment-selector.component';
import {ParameterInputWrapper} from '../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import {TableEventsQueryBuilder} from '../../../../shared/core/query-builders/table-events-query-builder/table-events-query-builder.component';

export const GRADUAL_RELEASE_QUERY_KEY = 'gradual_release_query';
export const kpiSelectorSchemaMapping = {
  primary_kpi: 'goal',
  secondary_kpis: 'secondary_goal',
};
const featuresSelectorSchemaMapping = {
  features: 'features',
};
const treatmentsSelectorMap = {
  treatments_tag: 'feature_usage_tag',
  treatments_signals: 'treatments_signals',
};
const statisticalSignificanceSchemaMapping = {
  statistical_significance: 'experiment_threshold',
};
const gradualReleaseSelectorSchemaMapping = {
  date_parameter: 'pre_release_date',
};
export const releaseDateSelectorSchemaMapping = {
  date_parameter: 'release_date',
};
const DAYS_BEFORE_SCHEMA_KEYS = {
  days_count_key: 'days_before',
};
const entitySchemaMapping = {
  entity: 'entity',
};
const endDateSelectorSchemaMapping = {
  date_parameter: 'end_date',
};
const MAX_DAYS_FROM_RELEASE_SCHEMA_MAPPING = {
  number_key: 'max_days_from_release',
};

const EXCLUDE_TEMPLATES = ['dod', 'wow', 'mom', 'dau', 'wau', 'mau', 'l7', 'l28'];
export const createKPISelectorFiltersFor109 = (entityContext: TableEntity) => [
  {
    type: SignalType.MEASURE,
    data_type: SignalDataType.BOOLEAN,
    entityBinding: TableEntityBinding.TWO_WAY,
    entityContext: entityContext,
    exclude_templates: EXCLUDE_TEMPLATES,
  },
  {
    type: SignalType.DIMENSION,
    data_type: SignalDataType.TIMESTAMP,
    entityBinding: TableEntityBinding.ONE_WAY,
    entityContext: entityContext,
    exclude_templates: EXCLUDE_TEMPLATES,
    exclude_tag: ['join_date'],
  },
  {
    templates: ['bounded_action_nu'],
  },
];

export const Analysis109Form: React.FC<AnalysisFormProps> = (props: AnalysisFormProps) => {
  const {onSignalInfo, className} = props;
  const {errors, parameters, changeParametersValue, registerDefaultHandler, removeDefaultHandler} =
    useContext(ParametersFormContext);
  const [isOpenAdvancedParams, setIsOpenAdvancedParams] = useState(false);
  const {t} = useTranslation();
  const {productEntities, defaultTableEntity} = useProductData();
  const {[entitySchemaMapping.entity]: entityContext} = parameters;
  const kpiSignalFilters = useMemo(
    () => createKPISelectorFiltersFor109(entityContext),
    [entityContext]
  );
  useEffect(() => {
    registerDefaultHandler('analysis_109', parameters => {
      const defaults = {};
      defaults[statisticalSignificanceSchemaMapping.statistical_significance] = 0.9;
      defaults[DAYS_BEFORE_SCHEMA_KEYS.days_count_key] = 28;
      defaults[MAX_DAYS_FROM_RELEASE_SCHEMA_MAPPING.number_key] = 28;
      defaults[entitySchemaMapping.entity] = defaultTableEntity;
      return defaults;
    });
    return () => {
      removeDefaultHandler('analysis_109');
    };
  }, [registerDefaultHandler, removeDefaultHandler, defaultTableEntity]);

  const onChangeEntityContext = useCallback(
    (entity: TableEntity) => {
      const resetKeys = [
        kpiSelectorSchemaMapping.primary_kpi,
        kpiSelectorSchemaMapping.secondary_kpis,
      ];
      const resetParameters = createUndefinedObject(resetKeys);
      changeParametersValue({
        [entitySchemaMapping.entity]: entity,
        ...resetParameters,
      });
    },
    [changeParametersValue]
  );
  const isOpenAdvancedParamsOrError = useMemo(() => {
    return (
      isOpenAdvancedParams ||
      hasError(errors, [
        ...values(endDateSelectorSchemaMapping),
        ...values(MAX_DAYS_FROM_RELEASE_SCHEMA_MAPPING),
        ...values(treatmentsSelectorMap),
        ...values(gradualReleaseSelectorSchemaMapping),
        ...values(DAYS_BEFORE_SCHEMA_KEYS),
        ...values(featuresSelectorSchemaMapping),
        GRADUAL_RELEASE_QUERY_KEY,
      ])
    );
  }, [isOpenAdvancedParams, errors]);

  return (
    <div className={classNames(classes.AnalysisForm, className)}>
      <EntitySelector
        value={parameters}
        productEntities={productEntities}
        schemaKeysMapping={entitySchemaMapping}
        onChange={v => onChangeEntityContext(v[entitySchemaMapping.entity])}
        className={classes.Parameter}
      />
      <SingleDateSelector
        title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.RELEASE_DATE.TITLE)}
        subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.RELEASE_DATE.SUB_TITLE)}
        helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.RELEASE_DATE.HELPER_TEXT)}
        className={classes.Parameter}
        errors={errors}
        value={parameters}
        onChange={changeParametersValue}
        schemaKeysMapping={releaseDateSelectorSchemaMapping}
        allowFutureDates={false}
      />
      <FeaturesSignalSelector
        value={parameters}
        title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.FEATURES.TITLE)}
        subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.FEATURES.SUB_TITLE)}
        helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.FEATURES.HELPER_TEXT)}
        onChange={changeParametersValue}
        className={classes.Parameter}
        schemaKeysMapping={featuresSelectorSchemaMapping}
        errors={errors}
        onSignalInfo={onSignalInfo}
      />
      <KPIsSelector
        title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.KPI.TITLE)}
        subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.KPI.SUB_TITLE)}
        helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.KPI.HELPER_TEXT)}
        value={parameters}
        onChange={changeParametersValue}
        className={classes.Parameter}
        schemaKeysMapping={kpiSelectorSchemaMapping}
        filters={kpiSignalFilters}
        errors={errors}
        onSignalInfo={onSignalInfo}
      />
      <TreatmentSelector
        value={parameters}
        onChange={changeParametersValue}
        className={classes.Parameter}
        schemaKeysMapping={treatmentsSelectorMap}
        errors={errors}
        entityContext={entityContext}
      />
      <StatisticalSignificanceSelector
        title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.STATISTICAL_SIGNIFICANCE.TITLE)}
        subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.STATISTICAL_SIGNIFICANCE.SUB_TITLE)}
        helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.STATISTICAL_SIGNIFICANCE.HELPER_TEXT)}
        value={parameters}
        onChange={changeParametersValue}
        schemaKeysMapping={statisticalSignificanceSchemaMapping}
        errors={errors}
        className={classes.Parameter}
      />
      {entityContext && (
        <SegmentFilterSelector
          title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.SEGMENT_FILTER.TITLE)}
          subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.SEGMENT_FILTER.SUB_TITLE)}
          helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.SEGMENT_FILTER.HELPER_TEXT)}
          onChange={changeParametersValue}
          value={parameters}
          className={classes.Parameter}
          entityContext={entityContext}
          errors={errors}
        />
      )}
      <ExtendedParameters
        className={classes.SpaceBottom}
        label={t(TransKeys.GENERAL.LABELS.ADVANCED_PARAMETERS)}
        isOpen={isOpenAdvancedParamsOrError}
        onOpenChanged={() => setIsOpenAdvancedParams(!isOpenAdvancedParams)}
      >
        <SingleDateSelector
          title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.END_DATE.TITLE)}
          subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.END_DATE.SUB_TITLE)}
          helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.END_DATE.HELPER_TEXT)}
          className={classes.Parameter}
          errors={errors}
          value={parameters}
          onChange={changeParametersValue}
          schemaKeysMapping={endDateSelectorSchemaMapping}
          allowFutureDates={false}
          clearable
        />
        <SimpleNumberSelector
          title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.MAX_DAYS_FROM_RELEASE.TITLE)}
          subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.MAX_DAYS_FROM_RELEASE.SUB_TITLE)}
          helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.MAX_DAYS_FROM_RELEASE.HELPER_TEXT)}
          className={classes.Parameter}
          onChange={changeParametersValue}
          schemaKeysMapping={MAX_DAYS_FROM_RELEASE_SCHEMA_MAPPING}
          value={parameters}
          errors={errors}
          minValue={1}
          postText={'Days'}
        />
        <ParameterInputWrapper
          title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.GRADUAL_RELEASE.TITLE)}
          subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.GRADUAL_RELEASE.SUB_TITLE)}
          helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.GRADUAL_RELEASE.HELPER_TEXT)}
          className={classes.Parameter}
        >
          <TableEventsQueryBuilder
            query={parameters[GRADUAL_RELEASE_QUERY_KEY]}
            errors={get(errors, GRADUAL_RELEASE_QUERY_KEY)}
            onChange={q => changeParametersValue({[GRADUAL_RELEASE_QUERY_KEY]: q})}
            multiEvents={false}
          />
        </ParameterInputWrapper>
        <DaysCountSelector
          title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.DAYS_BEFORE_COUNT.TITLE)}
          subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.DAYS_BEFORE_COUNT.SUB_TITLE)}
          helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.DAYS_BEFORE_COUNT.HELPER_TEXT)}
          className={classes.Parameter}
          schemaKeysMapping={DAYS_BEFORE_SCHEMA_KEYS}
          value={parameters}
          errors={errors}
          onChange={changeParametersValue}
        />
      </ExtendedParameters>
    </div>
  );
};
