import {
  RCAInsightSentence,
  SpikeDropOverUnderPerformingSegmentShareIncreaseDecrease,
} from './objects';
import sharedClasses from '../insights.module.scss';
import {
  ChangeInShareOfEntitiesRCAInsightSentencePart,
  SegmentNameRCAInsightSentencePart,
} from '../components/shared-sentence-parts/shared-sentence-parts.components';

interface OwnProps extends RCAInsightSentence {
  formattedSentenceParts: SpikeDropOverUnderPerformingSegmentShareIncreaseDecrease;
}

type AllProps = OwnProps;

const SpikeDropOverUnderPerformingSegmentShareIncreaseDecreaseFormatter: React.FC<AllProps> = (
  props: AllProps
) => {
  const {formattedSentenceParts} = props;
  const {
    segmentName,
    entity,
    entityChangeDescription,
    changeInShareOfEntities,
    presentTensePerformanceVsAvg,
  } = formattedSentenceParts;

  return (
    <div className={sharedClasses.InsightSentenceContainer}>
      <SegmentNameRCAInsightSentencePart segmentName={segmentName} />
      <span className={sharedClasses.InsightSentencePart}>
        {`share of ${entity} ${entityChangeDescription}d by`}
      </span>
      <ChangeInShareOfEntitiesRCAInsightSentencePart
        changeInShareOfEntities={changeInShareOfEntities}
      />
      <span className={sharedClasses.InsightSentencePart}>
        {`vs the prior week. This segment is ${presentTensePerformanceVsAvg} vs the average.`}
      </span>
    </div>
  );
};

export {SpikeDropOverUnderPerformingSegmentShareIncreaseDecreaseFormatter};
