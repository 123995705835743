import * as React from 'react';
import classes from './experiment-final-info.module.scss';
import {Experiment, ExperimentType} from '../../../../objects/models/experiment.model';
import TransKeys from '../../../../constants/translation-keys';
import {
  BlockHeader,
  Button,
  KPIIcon,
  FlaskIcon,
  LearningIcon,
  Link,
  WandIcon,
  Textarea,
  ActionIcon,
} from 'ui-components';
import {useTranslation} from 'react-i18next';
import {TitleWithIcon} from '../../../shared/components/general/title/title.component';
import {AnalysisResult} from '../../../../objects/models/analysis-result.model';
import {values} from 'lodash';
import classNames from 'classnames';
import {useEffect, useMemo, useRef, useState} from 'react';
import {ImagesResource} from '../../../../assets/images';
import {ConfettiMessage} from '../../../shared/components/general/confetti-message/confetti-message.component';
import {WinnerLabel} from '../../../shared/components/general/winner-label/winner-label.component';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {MissingData} from '../../../shared/components/general/system-text/system-text.component';
import {AppSentence} from '../../../shared/core/app-sentence/app-sentence.component';

interface OwnProps {
  experiment: Experiment;
  onViewOpportunity?: () => void;
  onViewResults?: () => void;
  analysisResult?: AnalysisResult;
  onLearningChange: (text: string) => void;
  onNextDescriptionChange: (text: string) => void;
  isLoadingLearnings?: boolean;
  isLoadingNextDescription?: boolean;
}

type AllProps = OwnProps;

export const ExperimentFinalInfo: React.FC<AllProps> = (props: AllProps) => {
  const {
    experiment,
    analysisResult,
    onViewOpportunity,
    onViewResults,
    onLearningChange,
    onNextDescriptionChange,
    isLoadingLearnings,
    isLoadingNextDescription,
  } = props;
  const {t} = useTranslation();
  const learningsRef = useRef<any>();
  const winner = useMemo(
    () => (analysisResult ? values(analysisResult.outputs).find(i => i.isWinner) : null),
    [analysisResult]
  );
  const [learnings, setLearnings] = useState(experiment?.learnings || '');
  const [nextDescription, setNextDescription] = useState(experiment?.nextDescription || '');
  const [focusedInput, setFocusedInput] = useState(null);
  const primaryMetric = useMemo(() => experiment?.metrics?.find(m => m.isPrimary), [experiment]);

  useEffect(() => {
    setLearnings(experiment?.learnings || '');
    setNextDescription(experiment?.nextDescription || '');
  }, [experiment]);

  const onInputFocused = (inputKey: string) => {
    if (focusedInput === 'learnings') {
      setLearnings(experiment.learnings);
    }
    if (focusedInput === 'nextDescription') {
      setNextDescription(experiment.nextDescription);
    }
    setFocusedInput(inputKey);
  };

  const onSave = () => {
    if (focusedInput === 'learnings') {
      onLearningChange(learnings);
    }
    if (focusedInput === 'nextDescription') {
      onNextDescriptionChange(nextDescription);
    }
    setFocusedInput(null);
  };

  const renderTextAreaButtons = () => (
    <div className={classes.TextareaButtons}>
      <Button className={classes.Btn} variant={'outlined'} onClick={() => onInputFocused(null)}>
        Cancel
      </Button>
      <Button className={classes.Btn} onClick={onSave}>
        Save
      </Button>
    </div>
  );

  return (
    <div className={classes.ExperimentFinalInfo}>
      <div className={classes.Info}>
        {!experiment?.learnings && experiment.type === ExperimentType.AB && (
          <div className={classes.ConfettiMessage}>
            <ConfettiMessage
              className={classes.Message}
              title={t(TransKeys.VIEW_EXPERIMENT.EXPERIMENT_COMPLETED_CONFETTI_MESSAGE.TITLE)}
              subTitle={t(
                TransKeys.VIEW_EXPERIMENT.EXPERIMENT_COMPLETED_CONFETTI_MESSAGE.SUB_TITLE
              )}
              buttonText={t(
                TransKeys.VIEW_EXPERIMENT.EXPERIMENT_COMPLETED_CONFETTI_MESSAGE.BTN_TEXT
              )}
              onClick={() => learningsRef && learningsRef.current?.focus()}
            />
          </div>
        )}
        <div className={classes.InfoRow}>
          <div className={classes.InfoBox}>
            <BlockHeader
              icon={WandIcon}
              title={t(TransKeys.MODELS.OPPORTUNITY)}
              headerRight={
                experiment?.opportunity?.id && (
                  <Link onClick={onViewOpportunity}>{t(TransKeys.GENERAL.ACTIONS.DEEP_DIVE)}</Link>
                )
              }
            />
            <div className={classes.InfoContent}>
              <AppSentence text={experiment?.opportunity?.title || ''} />
              {!experiment?.opportunity?.id && <MissingData />}
            </div>
          </div>
          <div className={classes.Spacer}>
            <ArrowRightIcon className={classes.ArrowRightIcon} />
          </div>
          <div className={classes.InfoBox}>
            <BlockHeader icon={ActionIcon} title={t(TransKeys.MODELS.ACTION)} />
            <div className={classes.InfoContent}>
              <div className={classes.Description}>
                <span className={classes.Label}>{t(TransKeys.GENERAL.LABELS.DESCRIPTION)}:</span>{' '}
                {experiment?.loop?.description}
              </div>
              {experiment?.action?.title && (
                <div className={classes.Action}>
                  <span className={classes.Label}>{t(TransKeys.GENERAL.LABELS.ACTION_TAKEN)}:</span>{' '}
                  {experiment.action.title}
                </div>
              )}
            </div>
          </div>
          <div className={classes.Spacer}>
            <ArrowRightIcon className={classes.ArrowRightIcon} />
          </div>
          <div className={classes.InfoBox}>
            <BlockHeader
              icon={FlaskIcon}
              title={t(TransKeys.GENERAL.LABELS.RESULTS)}
              headerRight={
                <Link onClick={onViewResults}>{t(TransKeys.GENERAL.ACTIONS.VIEW_RESULTS)}</Link>
              }
            />
            <div className={classes.InfoContent}>
              {primaryMetric && (
                <div className={classes.PrimaryMetric}>
                  <TitleWithIcon size={'small'} text={primaryMetric.name} icon={KPIIcon} />
                </div>
              )}
              {!analysisResult?.outputs && <MissingData />}
              {winner && (
                <div className={classes.Winner}>
                  <div className={classes.WinnerIcon}>
                    <img src={ImagesResource.app.trophy} alt={''} />
                  </div>
                  <WinnerLabel
                    text={
                      winner?.isControl
                        ? t(TransKeys.GENERAL.LABELS.CONTROL_IS_WINNING_THE_EXPERIMENT)
                        : t(TransKeys.GENERAL.LABELS.EXPERIMENT_WINNER, winner)
                    }
                  />
                </div>
              )}
              <div className={classes.Stats}>
                {values(analysisResult?.outputs).map(i => (
                  <div key={i.name} className={classes.Item}>
                    <div
                      className={classNames(
                        classes.Dot,
                        i.isControl ? classes.Control : classes.Other
                      )}
                    />
                    <div className={classes.Label}>{i.name}</div>
                    <div className={classes.Value}>
                      {t(TransKeys.GENERAL.LABELS.UPLIFT_BY, {percent: i.uplift})}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.Info}>
        <div className={classes.InfoRow}>
          <div className={classes.InfoBox}>
            {isLoadingLearnings && <GenericLoading />}
            <BlockHeader
              icon={LearningIcon}
              title={t(TransKeys.EXPERIMENT_FORM.INPUTS.LEARNINGS.LABEL)}
            />
            <Textarea
              fullWidth
              value={learnings}
              onChange={e => setLearnings(e.target.value)}
              inputRef={learningsRef}
              placeholder={t(TransKeys.EXPERIMENT_FORM.INPUTS.LEARNINGS.PLACEHOLDER)}
              onFocus={() => onInputFocused('learnings')}
              rows={6}
              className={classes.Textarea}
            />
            {focusedInput === 'learnings' && renderTextAreaButtons()}
          </div>
        </div>
      </div>
      <div className={classes.Info}>
        <div className={classes.InfoRow}>
          <div className={classes.InfoBox}>
            {isLoadingNextDescription && <GenericLoading />}
            <BlockHeader
              icon={ActionIcon}
              title={t(TransKeys.EXPERIMENT_FORM.INPUTS.WHATS_NEXT.LABEL)}
            />
            <Textarea
              fullWidth
              value={nextDescription}
              onChange={e => setNextDescription(e.target.value)}
              placeholder={t(TransKeys.EXPERIMENT_FORM.INPUTS.WHATS_NEXT.PLACEHOLDER)}
              className={classes.Textarea}
              onFocus={() => onInputFocused('nextDescription')}
              rows={6}
            />
            {focusedInput === 'nextDescription' && renderTextAreaButtons()}
          </div>
        </div>
      </div>
    </div>
  );
};
