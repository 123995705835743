import * as React from 'react';
import classNames from 'classnames';
import classes from './confetti-message.module.scss';

interface OwnProps {
  title: string;
  subTitle?: string;
  buttonText?: string;
  onClick?: (e) => void;
  className?: string;
}

type AllProps = OwnProps;

export const ConfettiMessage: React.FC<AllProps> = (props: AllProps) => {
  const {title, subTitle, buttonText, onClick, className} = props;
  return (
    <div className={classNames(classes.ConfettiMessage, className)}>
      <div className={classes.Title}>{title}</div>
      {subTitle && <div className={classes.SubTitle}>{subTitle}</div>}
      {buttonText && onClick && (
        <div className={classes.ButtonText} onClick={onClick}>
          {buttonText}
        </div>
      )}
    </div>
  );
};
