import * as React from 'react';
import {useMemo} from 'react';
import classNames from 'classnames';
import classes from './homepage-metric-header.module.scss';
import {
  Button,
  TrendChip,
  WandMagicSparklesSolidIcon,
  ArrowUpRightFromSquareRegularIcon,
} from 'ui-components';
import TransKeys from 'translations';
import {ExtendedHomepageMetric} from '../../../homepage-summary.component';
import {takeRight} from 'lodash';
import {getSampleWoWChange} from '../../../homepage-summary.utils';
import {ModelSeriesGranularity} from '../../../../../../../objects/models/model-sample-series.model';
import {GoalStatus} from '../../goal-status/goal-status.component';
import {Tooltip} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {AnomalyMode} from '../../../../../../../objects/models/homepage.model';
import {ConfidenceIntervalConfig} from '../../../../../../../objects/models/user-settings.model';

interface OwnProps {
  metric: ExtendedHomepageMetric;
  configuration: ConfidenceIntervalConfig;
  onViewMetric: () => void;
  onViewMetricOpportunities: () => void;
  className?: string;
}

type AllProps = OwnProps;

export const HomepageMetricHeader: React.FC<AllProps> = (props: AllProps) => {
  const {metric, configuration, onViewMetric, onViewMetricOpportunities, className} = props;
  const {t} = useTranslation();

  const granularity = useMemo(() => {
    if (!metric) {
      return;
    }
    if (ModelSeriesGranularity.WEEK in metric.transformedSeries) {
      return ModelSeriesGranularity.WEEK;
    }
    return metric.series[0].granularity;
  }, [metric]);
  const reviewedSeries = useMemo(() => {
    if (!metric) {
      return undefined;
    }
    return metric.transformedSeries[granularity];
  }, [metric, granularity]);
  const trend = useMemo(() => {
    if (!reviewedSeries) {
      return;
    }
    const [prevSample, lastSample] = takeRight(reviewedSeries.nonPartialSamples, 2);
    return getSampleWoWChange(lastSample, prevSample);
  }, [reviewedSeries]);

  return (
    <div className={classNames(classes.HomepageMetricHeader, className)}>
      <div className={classes.Top}>
        {trend?.isSignificant && (
          <div
            className={classNames(
              classes.SignificantIndication,
              trend.isBetter ? classes.Positive : classes.Negative
            )}
          >
            {configuration.anomalyMode === AnomalyMode.LOOPS_ALGO &&
              (trend.isBetter
                ? t(TransKeys.HOMEPAGE.METRIC_CHART.LABELS.INVESTIGATE_RISE_TITLE)
                : t(TransKeys.HOMEPAGE.METRIC_CHART.LABELS.INVESTIGATE_DROP_TITLE))}
            {configuration.anomalyMode === AnomalyMode.XOX_SAME_WEEKDAY &&
              (trend.isBetter
                ? t(TransKeys.HOMEPAGE.METRIC_CHART.LABELS.WOW_INVESTIGATE_RISE_TITLE, {
                    percent: configuration.anomalyThreshold * 100,
                  })
                : t(TransKeys.HOMEPAGE.METRIC_CHART.LABELS.WOW_INVESTIGATE_DROP_TITLE, {
                    percent: configuration.anomalyThreshold * 100,
                  }))}
          </div>
        )}
        {metric.goal && (
          <div className={classes.GoalStatus}>
            <GoalStatus metric={metric} granularity={granularity} />
          </div>
        )}
      </div>
      <div className={classes.Main}>
        <div className={classes.MainTitle}>
          <span className={classes.KPIName} onClick={() => onViewMetric()}>
            {metric.name}
            <ArrowUpRightFromSquareRegularIcon className={classes.OpenIcon} />
          </span>
          {trend && (
            <>
              <span>{trend?.isBetter ? 'increased by' : 'decreased by'}</span>
              <Tooltip
                title={t(TransKeys.HOMEPAGE.METRIC_TREND_HELPER_TEXT, {
                  granularity: reviewedSeries.granularity,
                })}
                placement={'top'}
                interactive={false}
              >
                <TrendChip {...trend} isSignificant={false} className={classes.TrendChip} />
              </Tooltip>
              <span>
                this {reviewedSeries.granularity} vs. last {reviewedSeries.granularity}
              </span>
            </>
          )}
        </div>
        <div className={classes.Actions}>
          <Button
            onClick={() => onViewMetricOpportunities()}
            icon={WandMagicSparklesSolidIcon}
            caps={false}
          >
            Find Opportunities
          </Button>
        </div>
      </div>
    </div>
  );
};
