import * as React from 'react';
import classNames from 'classnames';
import classes from './significancy-definition-selector.module.scss';
import {AnomalyMode} from '../../../../../../objects/models/homepage.model';
import {
  Accordion,
  Chip,
  ChipVariant,
  LabelWrapper,
  LoopsIcon,
  OptionBox,
  Select,
} from 'ui-components';
import TransKeys from '../../../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';
import {useMemo} from 'react';

interface OwnProps {
  anomalyMode: AnomalyMode;
  onChangeAnomalyMode: (mode: AnomalyMode) => void;
  anomalyThreshold: number;
  onChangeAnomalyThreshold: (threshold: number) => void;
  disabled?: boolean;
  className?: string;
}

type AllProps = OwnProps;

const THRESHOLD_OPTIONS = [0.05, 0.1, 0.2];
const thresholdOptions = {
  options: THRESHOLD_OPTIONS.map(v => ({value: v, label: `${v * 100}%`})),
};

export const SignificancyDefinitionSelector: React.FC<AllProps> = (props: AllProps) => {
  const {
    anomalyMode,
    onChangeAnomalyMode,
    anomalyThreshold,
    onChangeAnomalyThreshold,
    disabled,
    className,
  } = props;
  const {t} = useTranslation();

  const subTitle = useMemo(() => {
    if (anomalyMode === AnomalyMode.LOOPS_ALGO) {
      return (
        <>
          <LoopsIcon className={classes.LoopsIcon} />
          {t(TransKeys.HOMEPAGE_CONFIGURATION_PANEL.MODES.LOOPS_ALGO.TITLE)}
        </>
      );
    }
    return `${t(TransKeys.HOMEPAGE_CONFIGURATION_PANEL.MODES.XOX_SAME_WEEKDAY.TITLE)} ± ${
      anomalyThreshold * 100
    }%`;
  }, [anomalyMode, anomalyThreshold, t]);

  return (
    <Accordion
      title={t(TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.SIGNIFICANCY_DEFINITION.TITLE)}
      subTitle={subTitle}
      disabled={disabled}
      className={classNames(classes.SignificancyDefinitionSelector, className)}
    >
      <div className={classes.Description}>
        {t(TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.SIGNIFICANCY_DEFINITION.DESCRIPTION)}
      </div>
      <OptionBox
        className={classes.Option}
        title={
          <>
            <LoopsIcon className={classes.LoopsIcon} />
            {t(TransKeys.HOMEPAGE_CONFIGURATION_PANEL.MODES.LOOPS_ALGO.TITLE)}
            <Chip
              label={t(TransKeys.GENERAL.LABELS.RECOMMENDED).toUpperCase()}
              variant={ChipVariant.NEW}
              size={'small'}
            />
          </>
        }
        description={t(TransKeys.HOMEPAGE_CONFIGURATION_PANEL.MODES.LOOPS_ALGO.DESCRIPTION)}
        selected={anomalyMode === AnomalyMode.LOOPS_ALGO}
        onSelect={() => onChangeAnomalyMode(AnomalyMode.LOOPS_ALGO)}
      />
      <OptionBox
        className={classes.Option}
        title={t(TransKeys.HOMEPAGE_CONFIGURATION_PANEL.MODES.XOX_SAME_WEEKDAY.TITLE)}
        description={t(TransKeys.HOMEPAGE_CONFIGURATION_PANEL.MODES.XOX_SAME_WEEKDAY.DESCRIPTION)}
        selected={anomalyMode === AnomalyMode.XOX_SAME_WEEKDAY}
        onSelect={() => onChangeAnomalyMode(AnomalyMode.XOX_SAME_WEEKDAY)}
      >
        <div onClick={e => e.stopPropagation()}>
          <LabelWrapper label={t(TransKeys.HOMEPAGE_CONFIGURATION_PANEL.INPUTS.THRESHOLD.TITLE)}>
            <div className={classes.ThresholdInfo}>
              {t(TransKeys.HOMEPAGE_CONFIGURATION_PANEL.INPUTS.THRESHOLD.DESCRIPTION)}
            </div>
            <div onClick={e => e.stopPropagation()}>
              <Select
                value={anomalyThreshold}
                options={thresholdOptions}
                // @ts-ignore
                onChange={(v: number) => onChangeAnomalyThreshold(v)}
                searchable={false}
                clearable={false}
                sortValues={false}
                disabled={anomalyMode !== AnomalyMode.XOX_SAME_WEEKDAY}
              />
            </div>
          </LabelWrapper>
        </div>
      </OptionBox>
    </Accordion>
  );
};
