import React, {useCallback, useEffect, useMemo} from 'react';
import {composition} from 'front-core';
import {connector, ConnectorInjectedProps} from 'front-core';
import {coreLinker, CoreViewModel} from './core.linker';
import classes from './core.module.scss';
import CoreRouting from './core-routing.component';
import AppNavigator from './components/app-navigator/app-navigator.component';
import {logout} from '../../store/auth/auth.actions';
import {AppRoutes} from '../../constants/app-routes';
import {
  AnalysesMenuIcon,
  ExperimentsMenuIcon,
  OpportunitiesMenuIcon,
  HealthMonitorMenuIcon,
  DMPMenuIcon,
  HouseRegularIcon,
} from 'ui-components';
import {AppGrowthMap} from '../growth-map/app-growth-map.component';
import TransKeys from '../../constants/translation-keys';
import {useTranslation} from 'react-i18next';
import {
  changeAdminPrivileges,
  changeDefaultTeam,
  initialLoading,
  setScopeProduct,
} from '../../store/core/core.actions';
import {APP_PANELS} from '../../constants/panels';
import {useSelector} from 'react-redux';
import {INTERFACE_STORE_KEY} from '../../store/interface/interface.store';
import ModuleStructure from '../shared/infrastracture/module-structure/module-structure.component';
import {AUTH_STORE_KEY} from '../../store/auth/auth.store';
import {RootModelInteractionProvider} from '../shared/infrastracture/root-model-interaction-provider/root-model-interaction-provider.component';
import {CORE_STORE_KEY} from '../../store/core/core.store';
import {RootLoadingScreen} from '../root/components/root-loading-screen/root-loading-screen.component';
import {PanelsContextProvider} from '../../core/contexts/panels.context';
import {AppAnnouncementBar} from './components/app-announcement-bar/app-announcement-bar.component';
import {AppConfirmationManager} from './components/app-confirmation/app-confirmation-manager.component';
import {ConfirmationDialogContextProvider} from '../../core/contexts/confirmation-dialog.context';
import {Questionnaire} from './pages/questionnaire/questionnaire.component';
import {useProductData} from '../../core/hooks/use-product-data.hook';
import {IntegrationStatus} from '../../objects/models/product-data.model';
import {UserRole} from '../../objects/models/user.model';
import {AppUserOnboardingDrawer} from './components/app-user-onboarding/app-user-onboarding-drawer.component';
import {useUserOnboarding} from '../../core/hooks/use-user-onboarding.hook';
import {AppUserOnboardingConfetti} from './components/app-user-onboarding/app-user-onboarding-confetti.component';
import {AppNavigatorContextProvider} from '../../core/contexts/app-navigator.context';
import {AppUserOnboardingSimpleAnalysisPanel} from './components/app-user-onboarding/app-user-onboarding-simple-analysis-panel.component';
import {useFeatureIsOn} from '@growthbook/growthbook-react';
import {FeatureFlag} from 'src/constants/feature-flags';
import {AppPusherSubscriber} from './components/app-pusher-subscriber/app-pusher-subscriber.component';
import {AppDemoProductActionsSubscriber} from './components/app-demo-product-actions-subscriber/app-demo-product-actions-subscriber.component';
import {useDemoProduct} from '../../core/hooks/use-demo-product.hook';
import {AppMobileScreenMessage} from './components/app-mobile-screen-message/app-mobile-screen-message.component';

const CoreProviders: React.FC = ({children}) => {
  return (
    <AppNavigatorContextProvider>
      <PanelsContextProvider>
        <RootModelInteractionProvider>
          <ConfirmationDialogContextProvider>{children}</ConfirmationDialogContextProvider>
        </RootModelInteractionProvider>
      </PanelsContextProvider>
    </AppNavigatorContextProvider>
  );
};

interface OwnProps {}

type AllProps = OwnProps & CoreViewModel & ConnectorInjectedProps;

const CoreComponent: React.FC<AllProps> = (props: AllProps) => {
  const {dispatch, user, scope, appStatusCounters} = props;
  const {t} = useTranslation();
  const {isResetting: isResettingOnboarding} = useUserOnboarding();
  const initialLoadingCompleted = useSelector(
    state => state[CORE_STORE_KEY].initialLoadingCompleted
  );
  const {
    userCompletedQuestionnaire,
    integrationStatus,
    actualTeams: teams,
    defaultTeamId,
  } = useProductData();
  const {isDemoProduct} = useDemoProduct();

  const exposeHealthMonitor = useFeatureIsOn(FeatureFlag.HEALTH_MONITOR as string);

  useEffect(() => {
    dispatch(initialLoading());
  }, [dispatch]);

  const links = useMemo(
    () => [
      {
        icon: HouseRegularIcon,
        label: t(TransKeys.HOMEPAGE.HEADER.TITLE),
        route: AppRoutes.homepage().pathname,
      },
      {
        icon: AnalysesMenuIcon,
        label: t(TransKeys.ANALYSES.HEADER.TITLE),
        route: AppRoutes.analyses().pathname,
        count: appStatusCounters?.newAnalyses,
      },
      {
        icon: OpportunitiesMenuIcon,
        label: t(TransKeys.OPPORTUNITIES.HEADER.TITLE),
        route: AppRoutes.opportunities().pathname,
      },
      {
        icon: ExperimentsMenuIcon,
        label: t(TransKeys.EXPERIMENTS.HEADER.TITLE),
        route: AppRoutes.experiments().pathname,
      },
      {
        icon: HealthMonitorMenuIcon,
        label: t(TransKeys.HEALTH_MONITOR.HEADER.TITLE),
        route: AppRoutes.healthMonitor().pathname,
        hidden: !exposeHealthMonitor,
      },
      {
        icon: DMPMenuIcon,
        label: t(TransKeys.DMP.HEADER.TITLE),
        route: AppRoutes.dmp().pathname,
      },
    ],
    [t, appStatusCounters, exposeHealthMonitor]
  );
  const additionalLinks = useMemo(() => [], []);
  const showQuestionnaire = useMemo(() => {
    if (isDemoProduct) {
      return false;
    } else if (isResettingOnboarding) {
      return true;
    } else if (
      !userCompletedQuestionnaire &&
      integrationStatus === IntegrationStatus.ACTIVE &&
      user.role !== UserRole.ADMIN
    ) {
      return true;
    } else {
      return false;
    }
  }, [isDemoProduct, isResettingOnboarding, userCompletedQuestionnaire, integrationStatus, user]);
  const showOnboardingDrawer = useMemo(
    () => !user.isDemoUser || !isDemoProduct,
    [user, isDemoProduct]
  );
  const isGrowthMapOpen = useSelector(state => state[INTERFACE_STORE_KEY].isGrowthMapOpen);
  const isGrowthMapHidden = useSelector(state => state[INTERFACE_STORE_KEY].isGrowthMapHidden);
  const adminPrivileges = useSelector(state => state[CORE_STORE_KEY].adminPrivileges);
  const hasGrowthMap = useSelector(
    state =>
      state[AUTH_STORE_KEY].user?.products?.find(p => p.id === scope && p.hasGrowthMap) !==
      undefined
  );
  const onChangeAdminPrivileges = useCallback(
    (v: boolean) => dispatch(changeAdminPrivileges(v)),
    [dispatch]
  );
  const onScopeChange = useCallback((scope: any) => dispatch(setScopeProduct(scope)), [dispatch]);
  const onChangeDefaultTeam = useCallback(
    (defaultTeamId: any) => dispatch(changeDefaultTeam(defaultTeamId)),
    [dispatch]
  );

  if (!initialLoadingCompleted) {
    return <RootLoadingScreen />;
  }

  if (showQuestionnaire) {
    return <Questionnaire />;
  }

  return (
    <CoreProviders>
      <AppMobileScreenMessage />
      <AppDemoProductActionsSubscriber />
      <AppPusherSubscriber />
      <AppUserOnboardingConfetti />
      <AppUserOnboardingSimpleAnalysisPanel />
      <div className={classes.Core}>
        <AppAnnouncementBar />
        <div className={classes.CoreContent}>
          <div className={classes.NavigatorWrapper}>
            <AppNavigator
              user={user}
              teams={teams}
              defaultTeamId={defaultTeamId}
              onLogout={() => dispatch(logout())}
              onScopeChange={onScopeChange}
              onChangeDefaultTeam={onChangeDefaultTeam}
              links={links}
              additionalLinks={additionalLinks}
              scope={scope}
              disabled={isGrowthMapOpen}
              adminPrivileges={adminPrivileges}
              onChangeAdminPrivileges={onChangeAdminPrivileges}
            />
          </div>
          <div className={classes.CoreBody}>
            {showOnboardingDrawer && <AppUserOnboardingDrawer />}
            <ModuleStructure panels={APP_PANELS}>
              <CoreRouting />
            </ModuleStructure>
            <AppConfirmationManager />
            {!isGrowthMapHidden && hasGrowthMap && <AppGrowthMap />}
          </div>
        </div>
      </div>
    </CoreProviders>
  );
};

const Core = composition<OwnProps>(CoreComponent, connector<CoreViewModel>(coreLinker));
export default Core;
