import * as React from 'react';
import classNames from 'classnames';
import classes from './metric-settings-list.module.scss';
import {
  HomepageSubscriptionMetricSettings,
  HomepageSubscriptionPushType,
} from '../../../../../../objects/models/homepage.model';
import {Checkbox, TooltipIfOverflow} from 'ui-components';
import {useMemo} from 'react';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {Tooltip} from '@material-ui/core';

interface OwnProps {
  metricsData: Array<{id: number; name: string}>;
  settings: HomepageSubscriptionMetricSettings;
  onChange: (metricId: number, pushType: HomepageSubscriptionPushType) => void;
  onChangeAll: (pushType: HomepageSubscriptionPushType) => void;
  disabled?: boolean;
  className?: string;
}

type AllProps = OwnProps;

export const MetricSettingsList: React.FC<AllProps> = (props: AllProps) => {
  const {metricsData, settings, onChange, onChangeAll, disabled, className} = props;
  const {t} = useTranslation();

  const allSelected = useMemo(
    () => ({
      [HomepageSubscriptionPushType.SIGNIFICANT_CHANGE]: metricsData.every(
        m => settings[m.id] === HomepageSubscriptionPushType.SIGNIFICANT_CHANGE
      ),
      [HomepageSubscriptionPushType.ALWAYS]: metricsData.every(
        m => settings[m.id] === HomepageSubscriptionPushType.ALWAYS
      ),
      [HomepageSubscriptionPushType.NEVER]: metricsData.every(
        m => settings[m.id] === HomepageSubscriptionPushType.NEVER
      ),
    }),
    [settings, metricsData]
  );

  return (
    <div
      className={classNames(classes.MetricSettingsList, disabled && classes.Disabled, className)}
    >
      <div className={classes.Headers}>
        <Tooltip
          title={t(
            TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.METRIC_SETTINGS_LIST.HEADERS.KPI.HELPER_TEXT
          )}
          placement={'top'}
          interactive={false}
        >
          <div className={classNames(classes.Header, classes.MainHeader)}>
            {t(TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.METRIC_SETTINGS_LIST.HEADERS.KPI.TITLE)}
          </div>
        </Tooltip>
        <Tooltip
          title={t(
            TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.METRIC_SETTINGS_LIST.HEADERS.ALWAYS.HELPER_TEXT
          )}
          placement={'top'}
          interactive={false}
        >
          <div className={classNames(classes.Header, classes.SelectorHeader)}>
            <Checkbox
              checked={allSelected[HomepageSubscriptionPushType.ALWAYS]}
              onChange={() => onChangeAll(HomepageSubscriptionPushType.ALWAYS)}
              disabled={disabled}
            />
            {t(TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.METRIC_SETTINGS_LIST.HEADERS.ALWAYS.TITLE)}
          </div>
        </Tooltip>
        <Tooltip
          title={t(
            TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.METRIC_SETTINGS_LIST.HEADERS.SIGNIFICANT_CHANGE
              .HELPER_TEXT
          )}
          placement={'top'}
          interactive={false}
        >
          <div className={classNames(classes.Header, classes.SelectorHeader)}>
            <Checkbox
              checked={allSelected[HomepageSubscriptionPushType.SIGNIFICANT_CHANGE]}
              onChange={() => onChangeAll(HomepageSubscriptionPushType.SIGNIFICANT_CHANGE)}
              disabled={disabled}
            />
            {t(
              TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.METRIC_SETTINGS_LIST.HEADERS.SIGNIFICANT_CHANGE
                .TITLE
            )}
          </div>
        </Tooltip>
        <Tooltip
          title={t(
            TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.METRIC_SETTINGS_LIST.HEADERS.NEVER.HELPER_TEXT
          )}
          placement={'top'}
          interactive={false}
        >
          <div className={classNames(classes.Header, classes.SelectorHeader)}>
            <Checkbox
              checked={allSelected[HomepageSubscriptionPushType.NEVER]}
              onChange={() => onChangeAll(HomepageSubscriptionPushType.NEVER)}
              disabled={disabled}
            />
            {t(TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.METRIC_SETTINGS_LIST.HEADERS.NEVER.TITLE)}
          </div>
        </Tooltip>
      </div>
      <div className={classes.Body}>
        {metricsData.map(m => (
          <div className={classes.Row} key={m.id}>
            <div className={classNames(classes.Cell, classes.MainCell)}>
              <TooltipIfOverflow title={m.name}>
                <span className={classes.Name}>{m.name}</span>
              </TooltipIfOverflow>
            </div>
            <div className={classNames(classes.Cell, classes.SelectorCell)}>
              <Checkbox
                checked={settings[m.id] === HomepageSubscriptionPushType.ALWAYS}
                onChange={() => onChange(m.id, HomepageSubscriptionPushType.ALWAYS)}
                disabled={disabled}
              />
            </div>
            <div className={classNames(classes.Cell, classes.SelectorCell)}>
              <Checkbox
                checked={settings[m.id] === HomepageSubscriptionPushType.SIGNIFICANT_CHANGE}
                onChange={() => onChange(m.id, HomepageSubscriptionPushType.SIGNIFICANT_CHANGE)}
                disabled={disabled}
              />
            </div>
            <div className={classNames(classes.Cell, classes.SelectorCell)}>
              <Checkbox
                checked={settings[m.id] === HomepageSubscriptionPushType.NEVER}
                onChange={() => onChange(m.id, HomepageSubscriptionPushType.NEVER)}
                disabled={disabled}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
