import {NRC} from './index';
import {RequestType} from 'front-core';
import {toFiltersDTO} from '../objects/dto/to-filters.dto';
import {toCamelCase, toSnakeCase} from 'front-core';
import {LoopFiltersDTO} from '../objects/dto/loop-filters.dto';
import {LoopDTO} from '../objects/dto/loop.dto';

export const getLoopNetworkRequest: NRC<void, void> = (id: number) => ({
  method: RequestType.GET,
  relativeUrl: `/app/loops/${id}`,
  responseTransformer: res => toCamelCase(res.data, ['opportunity.analysis_result_parameters']),
});

export const getLoopsNetworkRequest: NRC<void, LoopFiltersDTO> = (payload: LoopFiltersDTO) => ({
  method: RequestType.GET,
  relativeUrl: `/app/loops`,
  query: payload,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data),
});

export const createLoopNetworkRequest: NRC<LoopDTO, void> = (data: LoopDTO) => ({
  method: RequestType.POST,
  relativeUrl: `/app/loops`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const updateLoopNetworkRequest: NRC<LoopDTO, void> = (data: LoopDTO) => ({
  method: RequestType.PUT,
  relativeUrl: `/app/loops/${data.id}`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const deleteLoopNetworkRequest: NRC<void, void> = (id: number) => ({
  method: RequestType.DELETE,
  relativeUrl: `/app/loops/${id}`,
  responseTransformer: toCamelCase,
});
