import {Epic} from 'redux-observable';
import {createRequestEpic} from 'front-core';
import {ActionKey} from '../../constants/action-key';
import HttpClient from '../../services/http-client.service';
import {LoopsActionType} from './loops.actions';
import {
  createLoopNetworkRequest,
  deleteLoopNetworkRequest,
  updateLoopNetworkRequest,
} from '../../http/loops.network-requests';
import {modelCreated, modelDeleted, modelUpdated, notifyEvent} from '../core/core.actions';
import {ModelKey} from '../../constants/model-key';
import {loopToastCreator} from '../toasts.actions';
import {AmplitudeEvent} from '../../constants/amplitude-event';
import {demoProductLimitedActionsFilter} from '../store.utils';

export const createLoopEpic: Epic = createRequestEpic(
  {
    types: [LoopsActionType.CREATE_LOOP],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.CREATE_LOOP,
    request: createLoopNetworkRequest,
    onSuccess: loop => [
      notifyEvent(AmplitudeEvent.LOOP_CREATED, {id: loop.id}),
      loopToastCreator('CREATE_SUCCESS'),
      modelCreated(loop, ModelKey.LOOP),
    ],
    onError: err => [loopToastCreator('CREATE_ERROR')],
  },
  HttpClient
);

export const updateLoopEpic: Epic = createRequestEpic(
  {
    types: [LoopsActionType.UPDATE_LOOP],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.UPDATE_LOOP,
    request: updateLoopNetworkRequest,
    onSuccess: loop => [
      notifyEvent(AmplitudeEvent.LOOP_UPDATED, {id: loop.id}),
      loopToastCreator('UPDATE_SUCCESS'),
      modelUpdated(loop, ModelKey.LOOP),
    ],
    onError: err => [loopToastCreator('UPDATE_ERROR')],
  },
  HttpClient
);

export const deleteLoopEpic: Epic = createRequestEpic(
  {
    types: [LoopsActionType.DELETE_LOOP],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.DELETE_LOOP,
    request: deleteLoopNetworkRequest,
    onSuccess: (res, payload) => [
      notifyEvent(AmplitudeEvent.LOOP_DELETED, {id: payload}),
      loopToastCreator('DELETE_SUCCESS'),
      modelDeleted(payload, ModelKey.LOOP),
    ],
    onError: err => [loopToastCreator('DELETE_ERROR')],
  },
  HttpClient
);
