import {HomepageExperiment, HomepageMetric} from '../../../../../../objects/models/homepage.model';
import {AnalysisTypeId} from '../../../../../../constants/analysis-type-id';
import TransKeys from 'translations';
import {AppRoutes} from '../../../../../../constants/app-routes';
import {exists} from 'front-core';
import moment from 'moment';
import {TIME_FORMATS} from '../../../../../../constants/time-formats';
import {MetricType} from '../../../../../../objects/models/metric.model';
import pluralize from 'pluralize';
import {QueryBuilderFactory} from 'ui-components';
import {
  METADATA_KEY,
  PARAMETERS_METADATA_KEY,
} from '../../../../../../constants/parameters-saved-keys';
import {retentionQueryMapper} from '../../../../../shared/core/query-builders/retention-builder/retention-query-builder.component';
import {ModelSeriesGranularity} from '../../../../../../objects/models/model-sample-series.model';
import {last} from 'lodash';
import {AggregationMode} from '../../../../../../objects/models/signal.model';

export interface ViewSuggestionAction {
  url: string;
}

export interface RunAnalysisSuggestionAction {
  analysisTypeId: number;
  parameters: any;
}

export type SuggestionAction = ViewSuggestionAction | RunAnalysisSuggestionAction;

export interface MetricSuggestion {
  transKey: string;
  transParams: any;
  buttonText: string;
  action: SuggestionAction;
}

export class MetricSuggestionsFactoryUtils {
  static getMetricSuggestions(
    metric: HomepageMetric,
    granularity: ModelSeriesGranularity,
    experiments: HomepageExperiment[],
    productEntitiesMap: any
  ): MetricSuggestion[] {
    return [
      experiments
        ? MetricSuggestionsFactoryUtils.generateReleaseImpactSuggestion(
            metric,
            granularity,
            experiments
          )
        : undefined,
      MetricSuggestionsFactoryUtils.generateRunRCASuggestion(metric, granularity),
      MetricSuggestionsFactoryUtils.generateRunHabitMomentSuggestion(metric),
      MetricSuggestionsFactoryUtils.generateRunGoalDriversSuggestion(metric, productEntitiesMap),
      MetricSuggestionsFactoryUtils.generateRunRetentionAnalysis(metric, productEntitiesMap),
      MetricSuggestionsFactoryUtils.generateRunKPIAnalysisSuggestion(metric, granularity),
      MetricSuggestionsFactoryUtils.generateRunJourney(metric),
      MetricSuggestionsFactoryUtils.generateRunEngagementDrivers(metric),
    ].filter(i => i);
  }

  static _getMetricSeries(metric: HomepageMetric, granularity: ModelSeriesGranularity) {
    const series = metric.series.find(s => s.granularity === granularity);
    return series || metric.series[0];
  }

  static generateReleaseImpactSuggestion(
    metric: HomepageMetric,
    granularity: ModelSeriesGranularity,
    experiments: HomepageExperiment[]
  ): MetricSuggestion {
    const series = MetricSuggestionsFactoryUtils._getMetricSeries(metric, granularity);
    const nonPartialSamples = series.samples.filter(s => s.isPartial === false);
    const lastSample = last(nonPartialSamples);
    if (!lastSample) {
      return;
    }
    const releaseImpactExp = experiments.find(
      experiment =>
        experiment.analysisTypeId === AnalysisTypeId.RELEASE_IMPACT &&
        moment.utc(experiment.startedOn).isSameOrBefore(moment.utc(lastSample.datetime))
    );
    if (releaseImpactExp) {
      return {
        transKey: TransKeys.HOMEPAGE.METRIC_SUGGESTIONS.HAS_RELEASE.TITLE,
        transParams: {
          kpi_name: metric.name,
          started_on: moment(releaseImpactExp.startedOn).format(TIME_FORMATS.READABLE_DATE),
        },
        buttonText: TransKeys.HOMEPAGE.METRIC_SUGGESTIONS.HAS_RELEASE.BUTTON_TEXT,
        action: {
          url: AppRoutes.viewExperiment(releaseImpactExp.id),
        },
      };
    }
  }

  static generateRunRCASuggestion(
    metric: HomepageMetric,
    granularity: ModelSeriesGranularity
  ): MetricSuggestion {
    const series = MetricSuggestionsFactoryUtils._getMetricSeries(metric, granularity);
    const samples = series.samples.filter(s => s.isPartial === false);
    if (samples.length < 5) {
      return;
    }
    const relevantSample = [...samples].reverse().find(s => {
      return exists(s.upper) && exists(s.lower) ? s.value > s.upper || s.value < s.lower : false;
    });
    if (!relevantSample) {
      return;
    }
    let analysisMode = undefined;
    if (metric.type === MetricType.USAGE || metric.type === MetricType.REVENUE) {
      analysisMode = AggregationMode.SUM;
    }
    return {
      transKey: TransKeys.HOMEPAGE.METRIC_SUGGESTIONS.RUN_RCA.TITLE,
      transParams: {
        kpi_name: metric.name,
        decreased_or_increased:
          relevantSample.value > relevantSample.upper ? 'increased' : 'decreased',
        sample_datetime: moment(relevantSample.datetime).format(TIME_FORMATS.READABLE_DATE),
      },
      buttonText: TransKeys.HOMEPAGE.METRIC_SUGGESTIONS.RUN_RCA.BUTTON_TEXT,
      action: {
        analysisTypeId: AnalysisTypeId.RCA_ANALYSIS,
        parameters: {
          entity: metric.entity,
          goal: metric.signalId,
          start_date_anomaly: moment(relevantSample.datetime).format(
            TIME_FORMATS.PARAMETER_DATE_FORMAT
          ),
          analysis_mode: analysisMode,
          time_aggregation: series.granularity,
        },
      },
    };
  }

  static generateRunHabitMomentSuggestion(metric: HomepageMetric): MetricSuggestion {
    if (metric.type !== MetricType.RETENTION) {
      return;
    }
    return {
      transKey: TransKeys.HOMEPAGE.METRIC_SUGGESTIONS.RUN_HABIT_MOMENT.TITLE,
      transParams: {
        kpi_name: metric.name,
        kpi_type: metric.type,
      },
      buttonText: TransKeys.HOMEPAGE.METRIC_SUGGESTIONS.RUN_HABIT_MOMENT.BUTTON_TEXT,
      action: {
        analysisTypeId: AnalysisTypeId.HABIT_MOMENTS,
        parameters: {
          entity: metric.entity,
          goal_query: metric.signalDefinition,
          population_filter: metric.signalPopulationFilter,
        },
      },
    };
  }

  static generateRunGoalDriversSuggestion(
    metric: HomepageMetric,
    productEntitiesMap
  ): MetricSuggestion {
    if ([MetricType.RETENTION, MetricType.CONVERSION].indexOf(metric.type) === -1) {
      return;
    }
    const goalQuery = QueryBuilderFactory.createSignalColumn(metric.signalId);
    goalQuery[PARAMETERS_METADATA_KEY] = {
      [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: 'UsageQueryBuilder',
    };
    return {
      transKey: TransKeys.HOMEPAGE.METRIC_SUGGESTIONS.RUN_GOAL_DRIVERS.TITLE,
      transParams: {
        entity: pluralize(productEntitiesMap[metric.entity].name).toLowerCase(),
        kpi_name: metric.name,
        kpi_type: metric.type,
      },
      buttonText: TransKeys.HOMEPAGE.METRIC_SUGGESTIONS.RUN_GOAL_DRIVERS.BUTTON_TEXT,
      action: {
        analysisTypeId: AnalysisTypeId.GOAL_DRIVERS,
        parameters: {
          entity: metric.entity,
          goal_query: goalQuery,
          model_version: 'existing',
        },
      },
    };
  }

  static generateRunKPIAnalysisSuggestion(
    metric: HomepageMetric,
    granularity: ModelSeriesGranularity
  ): MetricSuggestion {
    let analysisMode = undefined;
    const series = MetricSuggestionsFactoryUtils._getMetricSeries(metric, granularity);

    if (metric.type === MetricType.USAGE || metric.type === MetricType.REVENUE) {
      analysisMode = AggregationMode.SUM;
    }

    return {
      transKey: TransKeys.HOMEPAGE.METRIC_SUGGESTIONS.RUN_KPI_ANALYSIS.TITLE,
      transParams: {
        kpi_name: metric.name,
      },
      buttonText: TransKeys.HOMEPAGE.METRIC_SUGGESTIONS.RUN_KPI_ANALYSIS.BUTTON_TEXT,
      action: {
        analysisTypeId: AnalysisTypeId.KPI_ANALYSIS,
        parameters: {
          entity: metric.entity,
          goal: metric.signalId,
          time_aggregation: series.granularity,
          analysisMode,
        },
      },
    };
  }

  static generateRunJourney(metric: HomepageMetric): MetricSuggestion {
    if ([MetricType.CONVERSION, MetricType.RETENTION].indexOf(metric.type) === -1) {
      return;
    }
    const goalQuery = QueryBuilderFactory.createSignalColumn(metric.signalId);
    goalQuery[PARAMETERS_METADATA_KEY] = {
      [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: 'TemplateItemQueryBuilder',
    };
    return {
      transKey: TransKeys.HOMEPAGE.METRIC_SUGGESTIONS.RUN_JOURNEY.TITLE,
      transParams: {
        kpi_name: metric.name,
      },
      buttonText: TransKeys.HOMEPAGE.METRIC_SUGGESTIONS.RUN_JOURNEY.BUTTON_TEXT,
      action: {
        analysisTypeId: AnalysisTypeId.USER_JOURNEY,
        parameters: {
          entity: metric.entity,
          goal: goalQuery,
          ui_goal_type: 'existing',
        },
      },
    };
  }

  static generateRunRetentionAnalysis(
    metric: HomepageMetric,
    productEntitiesMap
  ): MetricSuggestion {
    if ([MetricType.RETENTION].indexOf(metric.type) === -1) {
      return;
    }
    const mapped = retentionQueryMapper(metric.signalDefinition);

    return {
      transKey: TransKeys.HOMEPAGE.METRIC_SUGGESTIONS.RUN_RETENTION_ANALYSIS.TITLE,
      transParams: {
        entity: pluralize(productEntitiesMap[metric.entity].name).toLowerCase(),
        kpi_name: metric.name,
      },
      buttonText: TransKeys.HOMEPAGE.METRIC_SUGGESTIONS.RUN_RETENTION_ANALYSIS.BUTTON_TEXT,
      action: {
        analysisTypeId: AnalysisTypeId.RETENTION_COHORTS,
        parameters: {
          entity: metric.entity,
          actions: mapped.actions,
          ref_date: mapped.ref_date,
          population_filter: metric.signalPopulationFilter,
        },
      },
    };
  }

  static generateRunEngagementDrivers(metric: HomepageMetric): MetricSuggestion {
    if ([MetricType.DAU, MetricType.WAU, MetricType.MAU].indexOf(metric.type) === -1) {
      return;
    }
    const goalQuery = QueryBuilderFactory.createSignalColumn(metric.signalId);
    goalQuery[PARAMETERS_METADATA_KEY] = {
      [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: 'UsageQueryBuilder',
    };
    return {
      transKey: TransKeys.HOMEPAGE.METRIC_SUGGESTIONS.RUN_ENGAGEMENT_DRIVERS.TITLE,
      transParams: {
        kpi_name: metric.name,
      },
      buttonText: TransKeys.HOMEPAGE.METRIC_SUGGESTIONS.RUN_ENGAGEMENT_DRIVERS.BUTTON_TEXT,
      action: {
        analysisTypeId: AnalysisTypeId.ENGAGEMENT_DRIVERS,
        parameters: {
          entity: metric.entity,
          goal: metric.signalId,
        },
      },
    };
  }
}
