import classNames from 'classnames';
import sharedClasses from '../../insights.module.scss';

export const ChangeInTotalEntitiesRCAInsightSentencePart = (props: {
  changeInTotalEntities: string;
}) => {
  const {changeInTotalEntities} = props;

  return (
    <span className={classNames(sharedClasses.InsightSentencePart, sharedClasses.Bold)}>
      {changeInTotalEntities}
    </span>
  );
};

export const ChangeInShareOfEntitiesRCAInsightSentencePart = (props: {
  changeInShareOfEntities: string;
}) => {
  const {changeInShareOfEntities} = props;

  return (
    <span className={classNames(sharedClasses.InsightSentencePart, sharedClasses.Bold)}>
      {changeInShareOfEntities}
    </span>
  );
};
export const ChangeInValueRCAInsightSentencePart = (props: {changeInValue: string}) => {
  const {changeInValue} = props;

  return (
    <span className={classNames(sharedClasses.InsightSentencePart, sharedClasses.Bold)}>
      {changeInValue}
    </span>
  );
};
export const SegmentNameRCAInsightSentencePart = (props: {segmentName: string}) => {
  const {segmentName} = props;

  return (
    <span
      className={classNames(
        sharedClasses.InsightSentencePart,
        sharedClasses.Bold,
        sharedClasses.Italic
      )}
    >
      {segmentName}
    </span>
  );
};
