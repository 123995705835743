import * as React from 'react';
import {Experiment} from '../../../../../objects/models/experiment.model';
import {AnalysisTypeId} from '../../../../../constants/analysis-type-id';
import {useTranslation} from 'react-i18next';
import {toLocalTime} from '../../../../../utils/general.utils';
import {TIME_FORMATS} from '../../../../../constants/time-formats';
import moment from 'moment/moment';
import {FlexVertical} from '../../../../shared/components/layout/flex-layout/general-flex-layouts.component.';
import {Tooltip} from '@material-ui/core';
import TransKeys from '../../../../../constants/translation-keys';
import classes from '../experiments-main.module.scss';
import {RepeatIcon} from 'ui-components';
import {useMemo} from 'react';

interface OwnProps {
  experiment: Experiment;
}

type AllProps = OwnProps;

const ABTestStartDateColumn: React.FC<AllProps> = (props: AllProps) => {
  const {experiment: exp} = props;
  const {t} = useTranslation();
  const formattedStartedOn = useMemo(
    () => toLocalTime(exp.startedOn, TIME_FORMATS.READABLE_DATE),
    [exp.startedOn]
  );
  const formattedTimeAgo = useMemo(
    () =>
      exp.lastCompletedAnalysisResult?.createdOn
        ? moment.utc(exp.lastCompletedAnalysisResult.createdOn).local().fromNow()
        : undefined,
    [exp]
  );
  const tooltipTitle = useMemo(
    () =>
      t(
        TransKeys.EXPERIMENTS.TABLE.HEADERS.START_DATE_TOOLTIP.AB[
          formattedTimeAgo ? 'WITH_TIME_AGO' : 'WITHOUT_TIME_AGO'
        ],
        {
          startDate: formattedStartedOn,
          timeAgo: formattedTimeAgo,
        }
      ),
    [t, formattedStartedOn, formattedTimeAgo]
  );

  return (
    <FlexVertical spacing fullWidth verticalAlignCenter>
      <Tooltip title={tooltipTitle} placement={'top'}>
        <div className={classes.StartDate}>
          <div>
            {formattedStartedOn} <RepeatIcon className={classes.RepeatIcon} />
          </div>
          {formattedTimeAgo && (
            <>
              <div className={classes.LightText}>
                {t(TransKeys.GENERAL.LABELS.LAST)}: {formattedTimeAgo}
              </div>
            </>
          )}
        </div>
      </Tooltip>
    </FlexVertical>
  );
};

const ReleaseImpactStartDateColumn: React.FC<AllProps> = (props: AllProps) => {
  const {experiment: exp} = props;
  const {t} = useTranslation();
  const formattedReleaseDate = toLocalTime(exp.releaseDate, TIME_FORMATS.READABLE_DATE);
  const tooltipTitle = useMemo(
    () =>
      t(TransKeys.EXPERIMENTS.TABLE.HEADERS.START_DATE_TOOLTIP.AB.WITHOUT_TIME_AGO, {
        releaseDate: formattedReleaseDate,
      }),
    [t, formattedReleaseDate]
  );
  return (
    <FlexVertical spacing fullWidth verticalAlignCenter>
      <Tooltip title={tooltipTitle} placement={'top'}>
        <span>{formattedReleaseDate}</span>
      </Tooltip>
    </FlexVertical>
  );
};

const ANALYSIS_TYPE_ID_TO_EXPERIMENT_START_DATE_COLUMN_COMPONENT_MAP = {
  [AnalysisTypeId.A_B_TEST]: ABTestStartDateColumn,
  [AnalysisTypeId.RELEASE_IMPACT]: ReleaseImpactStartDateColumn,
};

const ExperimentStartDateColumn: React.FC<AllProps> = (props: AllProps) => {
  const {experiment} = props;
  const Component =
    ANALYSIS_TYPE_ID_TO_EXPERIMENT_START_DATE_COLUMN_COMPONENT_MAP[
      experiment.analysis.analysisTypeId
    ];

  return <Component experiment={experiment} />;
};

export default ExperimentStartDateColumn;
